import React from 'react';
import { Box } from '@mui/material';
import ConsultationService,{PREGNANCY_URL_IDENTIFIER} from '../../services/consultation.service.js'


import GenericConsultationList from "../generic/genericConsultationList.component.js"


function PregnancConsultationList(props) {


  return (
    <Box  width={'100%'} height={"100%"}>
      <GenericConsultationList
        {...props}
        getConsultationsByPatientId={ConsultationService.getAllPregnancyConsultationGroupsFromPatientId }
        deleteInterviewsById={ConsultationService.deletePregnancyConsultationById}
        startConsultationProcessWithPatientId={ConsultationService.startPregnancyConsultationForPatient }
        billConsultationGroupById={ConsultationService.billConsultationGroupById}
        urlIdentifier={PREGNANCY_URL_IDENTIFIER}
        />
    </Box>
  );
}

export default PregnancConsultationList;