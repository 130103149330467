import React, { useState } from 'react';
import {
    Dialog, DialogTitle, DialogContent, Button, Grid, Box, Typography, TextField, FormHelperText, Stack, CircularProgress
} from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import MedicalTestResultChip from '../../../components/field/MedicalTestResulChip.tsx';
import  { UtilService } from '../../../services/util.service.js';
import MedicalTestService from '../../../services/MedicalTestService.ts';
import { useDispatch } from 'react-redux';
import { getActionDisplayMessage } from '../../../actions/ReduxAction.ts';
import {MedicalTestStatus} from "../../../types/types";

interface SendResultDialogProps {
    isOpen: boolean;
    onClose: () => void;
    currentTest: any;  // Adjust the type as necessary
    defaultEmailAddress: string;
    result?: MedicalTestStatus;
    onSendingResult?: () => void;  // Optional prop for additional logic after sending the result
}

const SendResultDialog: React.FC<SendResultDialogProps> = ({
                                                               isOpen,
                                                               onClose,
                                                               currentTest,
                                                               defaultEmailAddress,
                                                               result,
                                                               onSendingResult
                                                           }) => {
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const [emailAddress, setEmailAddress] = useState(defaultEmailAddress);

    const resultToSend= result ? result: currentTest.status;

    const handleSendResult = async () => {
        setIsLoading(true);
        try {
            // trying to save medical Test
            await MedicalTestService.saveCOVID19MedicalTest(currentTest);
            dispatch(getActionDisplayMessage('Test Enregistré avec succès', 'success'));

            try {
                // // trying to send medical result
                await MedicalTestService.validateCOVID19MedicalTest(currentTest.id, resultToSend);
                dispatch(getActionDisplayMessage('Résultat envoyé avec succès', 'success'));

                if (onSendingResult) {
                    onSendingResult();
                }
                onClose();
            } catch (validateError) {
                console.error(validateError);
                dispatch(getActionDisplayMessage("Une erreur s'est produite lors de l'envoi du résultat", 'error'));
            }
        } catch (saveError) {
            console.error(saveError);
            dispatch(getActionDisplayMessage("Une erreur s'est produite lors de l'enregistrement du test", 'error'));
        } finally {
            setIsLoading(false);
        }
    };

    const handleDialogClose = () => {
        onClose();
    };

    return (
        <Dialog
            open={isOpen}
            onClose={handleDialogClose}
            fullWidth
            disableRestoreFocus={true}
            maxWidth="lg"
        >
            <DialogTitle>
                <Grid container>
                    <Grid item md={10}>
                        <Box display="flex" justifyContent="center">
                            <Typography variant="h4">Envoie du résultat du test</Typography>
                        </Box>
                    </Grid>

                    <Grid item md={2}>
                        <Button
                            variant="outlined"
                            color="primary"
                            startIcon={<CloseRoundedIcon />}
                            onClick={handleDialogClose}
                        >
                            Quitter
                        </Button>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <Grid container>
                    <Grid item md={10}>
                        <Box display="flex" justifyContent="center">
                            <Typography variant="h6">
                                Vous êtes sur le point d'envoyer un résultat de test <MedicalTestResultChip result={resultToSend} /> à l'adresse suivante :
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item md={12} xs={12}>
                        <TextField
                            label="Adresse Email"
                            required
                            value={emailAddress}
                            error={!UtilService.isValidEmail(emailAddress)}
                            color="success"
                            id="emailAddress"
                            name="emailAddress"
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            onChange={(event) => setEmailAddress(event.target.value)}
                            inputProps={{ maxLength: 250 }}
                        />
                        {!UtilService.isValidEmail(emailAddress) && (
                            <FormHelperText error>Adresse email incorrecte</FormHelperText>
                        )}
                    </Grid>
                    <Grid item md={12} xs={12}>
                        <Stack direction="row" mt={2} justifyContent={"center"}>
                            <Button
                                sx={{ p: 2 }}
                                variant="contained"
                                color="primary"
                                disabled={!UtilService.isValidEmail(emailAddress) || isLoading}
                                startIcon={<EmailIcon />}
                                onClick={handleSendResult}
                            >
                                {isLoading ? <CircularProgress size={24} /> : 'Envoyer et Finaliser le Test'}
                            </Button>
                        </Stack>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

export default SendResultDialog;
