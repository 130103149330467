import React, { useState, useEffect } from 'react';
import { Navigate  } from 'react-router-dom';
import { connect } from 'react-redux';
import { Box, Button, CircularProgress, Grid, Typography,Alert, Stack } from "@mui/material";
import { Dialog, DialogTitle, DialogActions,DialogContent  } from "@mui/material";
import { actionDisplayMessage } from "../../actions/auth.action";
import UserService from '../../services/user.service.js';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';



function TrialDialog(props) {
    const [user, setUser] = useState(null);
    const [isDialogOpen, setIsDialogOpen] = useState(props.isDialogOpen);
    const [isLoading, setIsLoading] = useState(true);

    const closeDialog = () => {
        setIsDialogOpen(false);
    };

    useEffect(() => {
        UserService.getCurrentUser()
          .then((response) => {
            setUser(response.data);
            setIsLoading(false);
            if(response.data.subscriptionStatus!=='TRIAL'){
                setIsDialogOpen(false);
            }
          })
          .catch((error) => {
            props.dispatch(
                  actionDisplayMessage(
                  "Une erreur s'est produite lors de la recupération de vos données Utilisateur, veuillez recharger la page ou contacter le support",
                  "error"));
            console.log(error);
          });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
     ,[props]);

    if (!props.user.accessToken) {
        return <Navigate replace to="/" />;
      } 
    if( isLoading) {
        return <CircularProgress />;
      }
    return (
    <Dialog
    open={isDialogOpen}
    onClose={closeDialog}
    
    disableRestoreFocus={true}
    maxWidth="lg"
  >
    <DialogTitle>
    <Grid container>
        <Grid item md={12}>
          <Box display="flex" justifyContent="center" ><Typography variant="h4" > Passer à la vitesse supérieure ! </Typography></Box>
        </Grid>

       
      </Grid>
      </DialogTitle>
      <DialogContent>
        <Stack>
      <Alert severity="info" >
            <Typography variant="h6" align="left">
            Vous utilisez une version d'essai du produit Entretien.       
            </Typography>

        <Typography variant="h6" align="left">
        Le nombre d'entretiens et de patients que vous pouvez créer est limité.
        </Typography>
        <Typography variant="h6" fontWeight="bold" align="left">
        Pour débloquer toutes les possibilités que notre produit vous offre, abonnez-vous à la version PRO.
         </Typography>
        <Typography paddingTop={3} variant="h6" align="center">
        100 euros par mois (HT), sans engagement ! *   
        </Typography>
        
        <Typography  paddingTop={3} variant="body2" align="right">
        * Offre de lancement qui vous donne accès à toutes les fonctionnalités (présentes et futures) et à un usage illimité de notre application, avec un tarif garanti jusqu'en août 2024 !
        </Typography>
        </Alert>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Grid container>
        <Grid item xs={6}>
                <Button
                    variant="contained"
                    color="primary"
                    fullWidth={false}
                    startIcon={<RocketLaunchIcon />}
                    href={user.checkoutUrl}
                    >OUI je veux passer à la version pro</Button> 
        </Grid>
        <Grid item xs={6}>
            <Stack   >
                <Button
                    variant="text"
                    color="primary"
                    sx={{justifyContent:"flex-end"}}
                    onClick={()=> {closeDialog();}}
                    >NON, je prefère continuer à essayer Entretien</Button> 
                    </Stack>
        </Grid>
        </Grid>
     </DialogActions>
  </Dialog>
    )

}

function mapStateToProps(state) {
    const user = state.authReducer.user;
    return {
      user
    };
  }
export default connect(mapStateToProps)(TrialDialog);
