import {AnyAction} from "redux";
import { Types } from "./types";


export const getActionDisplayMessage = (messageText, severity) : AnyAction => {
    return {
        type: Types.DISPLAY_MESSAGE,
        payload: { messageText: messageText,
            severity: severity }
    };
}