import { Types } from "../actions/types";
import { USER_LOCAL_STORAGE_KEY } from '../services/auth.service.js';


let user;
try{
   user = JSON.parse(localStorage.getItem(USER_LOCAL_STORAGE_KEY));
}
catch(err){
  user = null;
}


const initialState = user
  ? { isLoggedIn: true, user }
  : { isLoggedIn: false, user: {} };


  const reducer = (state = initialState, action) => {
    const { type, payload } = action;
 
    switch (type) {
      case Types.LOGIN_SUCCESS:
        return {
          ...state,
          user: payload.user,
        };
      case Types.LOGIN_FAIL:
        return {
          ...state,
          messageText :"identifiant ou mot de passe incorect",
          severity: "error"
        };
      case Types.LOGOUT:
        return {
          ...state,
          isLoggedIn: false,
          user: null,
        };
      case Types.DISPLAY_MESSAGE:
        return {
          ...state,
          messageText : payload.messageText,
          severity: payload.severity
        };
      case Types.CLEAR_MESSAGE:
        return {
          ...state
        };
      default:
        return state;
  }
}

export default reducer;

