import React from 'react';
import Chip from '@mui/material/Chip';
import {MedicalTestStatus} from "../../types/types.ts";


interface MedicalTestResultChipProps {
    result: MedicalTestStatus;
}

const MedicalTestResultChip: React.FC<MedicalTestResultChipProps> = ({ result }) => {
    // Function to get the label and color based on the result
    const getChipProperties = (result: MedicalTestStatus) => {
        switch(result) {
            case MedicalTestStatus.POSITIVE:
                return { label: 'POSITIF', color: '#428bca' };
            case MedicalTestStatus.NEGATIVE:
                return { label: 'NÉGATIF', color: '#428bca' };
            case MedicalTestStatus.IN_PROGRESS:
                return { label: 'EN COURS', color: '#294056' };
            case MedicalTestStatus.CANCELED:
                return { label: 'ANNULER', color: '#B94A43' };
            case MedicalTestStatus.INCONCLUSIVE:
                return { label: 'INDÉTERMINÉ', color: '#FF8C00' };
            default:
                return { label: 'INCONNU', color: 'grey' };
        }
    };

    const chipProps = getChipProperties(result);

    return (
        <Chip
            label={chipProps.label}
            style={{
                backgroundColor: chipProps.color,
                color: '#ffffff',
                margin: '8px',
                fontWeight: 'bold'
            }}
        />
    );
};

export default MedicalTestResultChip;
