import React, { useState } from 'react';
import {Box, Button, Menu, MenuItem} from '@mui/material';
import {
    CystitisDocument,
    CystitisTest,
    MedicalTest,
    MedicalTestStatus,
    MedicalTestType, SoreThroatDocument,
    SoreThroatTest
} from '../../types/types.ts';
import { Download } from '@mui/icons-material'; // Import the download icon
import MedicalTestService from '../../services/MedicalTestService.ts';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import SoreThroatTestDetail from "./soreThroat/SoreThroatTestDetail";

interface MedicalTestDocumentsDropdownProps {
    medicalTest: MedicalTest;
}

const MedicalTestDocumentsDropdown: React.FC<MedicalTestDocumentsDropdownProps> = ({ medicalTest }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleDownloadCystitisFile = (fileRequest : CystitisDocument) => {
        handleClose();
            MedicalTestService.downloadCystitisTestDocument(medicalTest.id, fileRequest)
                .then(({ fileBlob, fileName }) => {
                    const url = window.URL.createObjectURL(fileBlob);
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', fileName);
                    document.body.appendChild(link);
                    link.click();
                    window.URL.revokeObjectURL(url);
                })
                .catch((error) => {
                    console.error('Error downloading document:', error);
                });
    };

    const handleDownloadSoreThroatFile = (fileRequest : SoreThroatDocument) => {
        handleClose();
        MedicalTestService.downloadSoreThroatTestDocument(medicalTest.id, fileRequest)
            .then(({ fileBlob, fileName }) => {
                const url = window.URL.createObjectURL(fileBlob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
                window.URL.revokeObjectURL(url);
            })
            .catch((error) => {
                console.error('Error downloading document:', error);
            });
    };

    // Render the button only if the medical test is of type CYSTITIS
    if (medicalTest.type !== MedicalTestType.CYSTITIS && medicalTest.type !== MedicalTestType.SORE_THROAT) {
        return null;
    }

    if (medicalTest.type === MedicalTestType.CYSTITIS) {
        let  cystitisTest   : CystitisTest  =  medicalTest
        let  hasAttachments : boolean  =
            (cystitisTest.status === MedicalTestStatus.NEGATIVE && cystitisTest.negatifTestWithAnomaly === true) ||
            (cystitisTest.status === MedicalTestStatus.POSITIVE && cystitisTest.positiveTestWithAllergies === true) ||
            (cystitisTest.status === MedicalTestStatus.POSITIVE && cystitisTest.positiveTestWithAllergies === false && cystitisTest.antibioticsPrescribed !== null);
        if(!hasAttachments)  return null;  // Do not render the component if no attachments
    }
    if (medicalTest.type === MedicalTestType.SORE_THROAT) {
        let  soreThroatTest   : SoreThroatTest   =  medicalTest
        let  hasAttachments : boolean  =
            (soreThroatTest.status === MedicalTestStatus.NEGATIVE) ||
            (soreThroatTest.status === MedicalTestStatus.POSITIVE && soreThroatTest.allergicToAvailableAntibiotics === true) ||
            (soreThroatTest.status === MedicalTestStatus.POSITIVE && soreThroatTest.allergicToAvailableAntibiotics === false && soreThroatTest.antibioticsPrescribed !== null);
        if(!hasAttachments)  return null;  // Do not render the component if no attachments
    }

    return (
        <Box>
            <Button
                aria-controls="documents-menu"
                aria-haspopup="true"
                variant="contained"
                onClick={handleClick}
                startIcon={<AttachFileIcon />} >

                Documents
            </Button>

            <Menu
                id="documents-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}>

                {medicalTest.type === MedicalTestType.CYSTITIS &&  (<>
                <MenuItem onClick={() => {handleDownloadCystitisFile(CystitisDocument.REPORT_FOR_DOCTOR)}}>
                    <Download sx={{ marginRight: 1 }} />
                    Résultat TROD Cystite
                </MenuItem>
                <MenuItem onClick={() => {handleDownloadCystitisFile(CystitisDocument.LETTER_FOR_DOCTOR)}}>
                    <Download sx={{ marginRight: 1 }} />
                   Lettre de liaison pour le Médecin
                </MenuItem>
                </>)}
                {medicalTest.type === MedicalTestType.SORE_THROAT &&  (<>
                    <MenuItem onClick={() => {handleDownloadSoreThroatFile(SoreThroatDocument.REPORT_FOR_DOCTOR)}}>
                        <Download sx={{ marginRight: 1 }} />
                        Résultat TROD Angine
                    </MenuItem>
                    <MenuItem onClick={() => {handleDownloadSoreThroatFile(SoreThroatDocument.LETTER_FOR_DOCTOR)}}>
                        <Download sx={{ marginRight: 1 }} />
                        Lettre de liaison pour le Médecin
                    </MenuItem>
                </>)}
             </Menu>
        </Box>
);
};

export default MedicalTestDocumentsDropdown;


