import * as React from 'react';
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import SvgIcon from '@mui/material/SvgIcon';
import { tokensColors } from "../../theme";
import { useLocation } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { actionLogout } from '../../actions/auth.action';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';


const MyAppBar = (props) => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const colors = tokensColors();  
  const pathname = useLocation().pathname;

  const pages = [['Patients'  ,'/patient'], 
               ['Dépistage','/medical_test'],
               ['Tableau de Bord','/dashboard']];

  const settings = [/*['Mon Profil',"/profile"],*/
['Mon Compte','/account']];

  const theme = useTheme();

const healthBeesTransaparentLogoURL  = process.env.PUBLIC_URL + "/icons/heathbees_logo_transparent.png";

  function handleLogout(event){
    props.dispatch(actionLogout());
    window.location.reload();
}

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <AppBar position="fixed" sx={{
      backgroundColor: '#f5f6fa',
      height: theme.layout.appBarHeigh,
      zIndex: theme.zIndex.drawer + 1
    }}>
      <Container maxWidth="xxl" sx={{
      color: 'black'
    }}>
        <Toolbar disableGutters>
            <Box
              component="img"
              sx={{
                height: 46,
                width: 46,
                display: { xs: 'none',sm: 'flex', md: 'flex', }
              }}
              src={healthBeesTransaparentLogoURL}
      />

          <Box sx={{ flexGrow: 1, display: { xs: 'flex',sm: 'none', md: 'none' } }}>
            <IconButton
              size="small"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'block' },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page} onClick={handleCloseNavMenu} component={Link} to={page[1]}>
                  <Typography textAlign="center">{page[0]}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>  
          
          <Box sx={{ flexGrow: 1, display: {xs : 'none',sm: 'flex', md: 'flex' } }}>
           
            {pages.map((page) => (
            
              <Button
                key={page}
                onClick={handleCloseNavMenu}
                component={Link} to={page[1]}
                sx={{ 
                      display: 'block', 
                      fontWeight:pathname.startsWith(page[1])? 850: 'normal',
                      color :pathname.startsWith(page[1])? colors.blueAccent[400]: colors.blueAccent[100]}}
              >
                {page[0]}
              </Button>
            ))}
          </Box>
          <Box sx={{ flexGrow: 0 }}>
          <Tooltip title="Gestion du compte">
            <Avatar onClick={handleOpenUserMenu} sx={{ backgroundColor: 'primary.main',opacity: 0.8, cursor: 'pointer', height: 46, width: 46}}>
              <SvgIcon>
                <SettingsOutlinedIcon />
              </SvgIcon>
            </Avatar>
          </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem key={setting} onClick={handleCloseUserMenu}  component={Link} to={setting[1]}>
                  <Typography textAlign="center">{setting[0]}</Typography>
                </MenuItem>
              ))}
                <MenuItem key='logout' onClick={handleLogout}>
                  <Typography textAlign="center">Se déconnecter</Typography>
                </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
function mapStateToProps(state) {
  return {
    
  };
}
export default connect(mapStateToProps) (MyAppBar) ;