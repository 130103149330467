import * as React from 'react';
import {Paper, Grid, Typography} from '@mui/material';
import {Patient} from '../../types/types';


interface NoChemoConsultationsProps {
    patient: Patient,
    dispatch: React.Dispatch<any>;
}

const NoMedicalTest: React.FC<NoChemoConsultationsProps> = ({ patient, dispatch }) => {

    return (
        <Paper variant="outlined">
            <Grid container direction="row" justifyContent="center" alignItems="center">
                <Grid item xs={12}>
                    <Typography m={3} variant="h6">Aucun Test pour {patient.firstName} {patient.lastName}</Typography>
                </Grid>
            </Grid>
        </Paper>
    );
};

export default NoMedicalTest;
