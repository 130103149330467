import {useState} from "react";
import {Paper, Grid, Typography, Stack, Box, Button, Alert, AlertTitle, CircularProgress} from '@mui/material';
import {COVID19Test, MedicalTestStatus, Patient} from '../../../types/types.ts';
import CovidTestSelector from "../../../components/medicalTest/CovidTestSelector.tsx";
import EditableField from '../../../components/field/editableField.component.js';
import MedicalTestResultSelect from "../../../components/field/MedicalTestResultSelect.tsx";
import MedicalTestResultChip from "../../../components/field/MedicalTestResulChip.tsx";
import EmailIcon from "@mui/icons-material/Email";
import SaveIcon from "@mui/icons-material/Save";
import utilService  from "../../../services/util.service.js";
import {useNavigate} from "react-router-dom";
import {MEDICAL_TEST_URL_IDENTIFIER} from "../../../services/MedicalTestService.ts";
import MedicalTestService from "../../../services/MedicalTestService.ts";
import {connect,useDispatch} from "react-redux";
import {getActionDisplayMessage} from "../../../actions/ReduxAction.ts"
import {Dispatch} from "redux";
import SendResultDialog from './SendResultDialog.tsx';

interface COVID19TestDetailProps {
    medicalTest: COVID19Test;
    patient : Patient;
}

const COVID19TestDetail: React.FC<COVID19TestDetailProps> = ({ medicalTest,patient }) => {
    const [currentTest, setCurrentTest] = useState(medicalTest);
    const [isLoading, setIsLoading] = useState(false);
    const [isDialogOpen, setDialogOpen] = useState(false);
    const dispatch: Dispatch = useDispatch()

    const resultHasBeenSent : boolean = currentTest.emailDate && currentTest.emailResult;


    const navigate = useNavigate();

    const handleFieldChange = (field, value) => {
        setCurrentTest(prevState => ({
            ...prevState,
            [field]: value
        }));
    };

    const handleSendingResult = () => {
        dispatch(getActionDisplayMessage('Test Enregistré avec succès', 'success'));
        navigate(`/patient/${patient.id}/${MEDICAL_TEST_URL_IDENTIFIER}/`);
    };

    const handleSaveAsDraft = () => {
        setIsLoading(true);
        MedicalTestService.saveCOVID19MedicalTest(currentTest).then(() => {
            setIsLoading(false);
            dispatch(getActionDisplayMessage("Test Enregistré avec succès","success"));
            navigate(`/patient/${patient.id}/${MEDICAL_TEST_URL_IDENTIFIER}/`);
        })
            .catch((error) => {
                console.error(error);
                setIsLoading(false);
                dispatch(
                    getActionDisplayMessage(
                        "Une erreur s'est produite lors de l'enregistrement des modifications",
                        "error"));
            });
    }
    const handleSave = () => {
        setIsLoading(true);
        MedicalTestService.saveCOVID19MedicalTest(currentTest).then(() => {
            setIsLoading(false);
            dispatch(getActionDisplayMessage("Test Enregistré avec succès","success"));
            navigate(`/patient/${patient.id}/${MEDICAL_TEST_URL_IDENTIFIER}/`);
        })
            .catch((error) => {
                console.error(error);
                setIsLoading(false);
                dispatch(
                    getActionDisplayMessage(
                        "Une erreur s'est produite lors de l'enregistrement des modifications",
                        "error"));
            });
    }

    if(isLoading) return <CircularProgress/>;

    return (
        <Grid container justifyContent="center"  p={2} >
          <Grid item xs={12} sm={12} md={12} lg={11} xl={10} >
            <Paper variant="outlined">
                <Stack direction="row" mb={3} justifyContent={"center"}>
                    <Typography variant="h3" >
                        Test Covid 19 du  { utilService.handleDateFormat(currentTest.testDate) }
                    </Typography>
                </Stack>

            </Paper>
            <Paper variant="outlined">
              <Box p={2} display="block">
                  <Typography variant="h4" align="center">
                      Information sur le patient
                  </Typography>
                  <Grid container spacing={2} mt={2}>
                      <Grid item md={4}>
                          <Typography variant="body1" color="grey">{ patient.firstName} {patient && patient.lastName} </Typography>
                      </Grid>
                      <Grid item md={4}>
                          <Typography variant="body1" color="grey">Né le { utilService.handleDateFormat(patient.birthday)} </Typography>
                      </Grid>
                      <Grid item md={4}>
                          <Typography variant="body1" color="grey">Numéro de SS:  {patient.socialSecurityNumber} </Typography>
                      </Grid>
                  </Grid>
              </Box>
            </Paper>
            <Paper variant="outlined" >
             <Box p={2} display="block">
                 <Typography variant="h4" align="center">
                     Information sur le Test
                 </Typography>
                <Grid container  p={1}>
                    <Grid item md={12} xs={12} pb={2}><Typography variant="h5" align="center"> Matériel utilisé</Typography></Grid>

                    <Grid item md={12} xs={12}>
                    <CovidTestSelector onSelectKit={(newkit) => handleFieldChange('testingKit', newkit)}  initialCovidTest={currentTest.testingKit}/>
                    </Grid>
                    <Grid item md={6} xs={12} pr={1}>
                        <EditableField
                            label="Numéro de lot (Facultatif)"
                            value={currentTest.batchNumber}
                            name="address"
                            onChange={(event)=>{handleFieldChange('batchNumber',event.target.value);}}
                            maxLength={50}
                        />
                    </Grid>
                    <Grid item md={6} xs={12}  pl={1}>
                        <EditableField
                            label="Date de péremption (Facultatif)"
                            value={currentTest.expiryDate}
                            name="expiryDate"
                            type={'date'}
                            onChange={(event)=> handleFieldChange('expiryDate', event.target.value)}
                        />
                    </Grid>
                    <Grid item md={12} xs={12}  paddingTop={2}>
                        <Typography variant="h5" align="center">Date du Test</Typography>
                    </Grid>
                    <Grid item md={6} >
                        <EditableField
                            label="Date du test"
                            value={currentTest.testDate || null}
                            name="testDate"
                            type={'date'}
                            onChange={(event)=> handleFieldChange('testDate', event.target.value)}
                        />
                    </Grid>

                    </Grid>
             </Box>
            </Paper>
              <Paper variant="outlined" >
                  <Box p={2} display="block">
                      <Typography variant="h4" align="center" sx={{paddingBottom:4}}>
                          Résultat du Test
                      </Typography>
                      <Grid container  p={1}>
                          <Grid item md={12} xs={12}>
                              <MedicalTestResultSelect disabled={resultHasBeenSent} testResult={currentTest.status} changeValue={(newTestResult)=> handleFieldChange('status', newTestResult)}/>
                          </Grid>

                      </Grid>

                  </Box>
              </Paper>

              {(resultHasBeenSent ) && (
                  <Paper  variant="outlined">
                      <Box  p={2} display="block">
                          <Typography px={2} variant="h5" align="center">
                              Email envoyé au patient
                              {(currentTest.emailAddress) ? ` (à l'adresse ${currentTest.emailAddress})` : ''}
                              {(currentTest.emailDate && utilService.handleDateFormat(currentTest.emailDate) !=='Non renseigné' ) ?
                                  ` le  ${utilService.handleDateFormat(currentTest.emailDate)}` : ''}
                              {(currentTest.emailDate && utilService.get24HourTime(currentTest.emailDate) ) ?
                                  ` à  ${utilService.get24HourTime(currentTest.emailDate)} ` : ' '}
                              {currentTest.emailResult ? <>
                                  avec un résultat  <MedicalTestResultChip result={currentTest.emailResult} /> </>: ''}
                          </Typography>
                          <Alert severity="info">
                              <AlertTitle> <Typography  variant="body1">Test Terminé : </Typography></AlertTitle>
                              <Typography variant="body1">
                                  Le mail de résultat a déja été envoyé, il vous faudra recréer un nouveau test pour envoyer un nouveau mail
                              </Typography>
                          </Alert>
                      </Box>


                  </Paper>
                  )}
              <Box p={'2px'}/>
              <Box p={'2px'}/>
              {(!currentTest.emailResult  && (currentTest.status === MedicalTestStatus.POSITIVE || currentTest.status === MedicalTestStatus.NEGATIVE)) ?(
                  <>
                      <Button
                          variant="contained"
                          color="primary"
                          startIcon={<EmailIcon />}
                          onClick={() => setDialogOpen(true)}
                          sx={{ mr: 1 }}
                      >
                          Envoyer le Résultat au patient
                      </Button>
                      <Button
                          variant="outlined"
                          color="primary"
                          startIcon={<SaveIcon />}
                          onClick={() => handleSaveAsDraft()}
                          sx={{ mr: 1 }}
                      >
                          Enregistrer pour plus tard
                      </Button>
                  </>
              ):(
                  <Button
                      variant="contained"
                      color="primary"
                      startIcon={<SaveIcon />}
                      sx={{ mr: 1 }}
                      onClick={() => handleSave()}
                  >
                      Enregistrer
                  </Button>
              )}

          </Grid>
            <SendResultDialog
                isOpen={isDialogOpen}
                onClose={() => setDialogOpen(false)}
                currentTest={currentTest}
                defaultEmailAddress={patient.email}
                onSendingResult={handleSendingResult}
            />
        </Grid>


    );
};

function mapStateToProps(state) {
    const { isLoggedIn, user } = state.authReducer;
    return {
        isLoggedIn,
        user
    };
}
export default connect(mapStateToProps)(COVID19TestDetail);

