import React, { useState, useEffect } from 'react';
import { Navigate  } from 'react-router-dom';
import { connect } from 'react-redux';
import { Box, Button, Chip, CircularProgress, Grid, Paper, Typography,Stack,TextField,FormHelperText } from "@mui/material";
import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Cancel';
import { actionDisplayMessage } from "../../actions/auth.action";
import UserService from '../../services/user.service.js';
import UtilService from '../../services/util.service.js';
import PharmacyService from '../../services/pharmacy.service.js';
import EditableField from '../../components/field/editableField.component.js';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import RestartAltIcon from '@mui/icons-material/RestartAlt';



function MyAccount(props) {
  const [user, setUser] = useState(null);
  const [pharmacy, setPharmacy] = useState(null);
  const [subscriptionChip, setSubscriptionChip] = useState({severity:"warning", label:"Non définie"});
  const [accountURL, setAccountURL] = useState({url:"", label:"", urlStatus:null});
  const [defaultSignature, setDefaultSignature] = useState(null);

  const [isFormValid, setIsFormValid] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [formDataUser, setFormDataUser] = useState(null);
  const [formDataPharmacy, setFormDataPharmacy] = useState(null);

  const [isLoadingUser, setIsLoadingUser] = useState(true);
  const [isLoadingPharmacy, setIsLoadingPharmacy] = useState(true);
  
  
  useEffect(() => { 
    if(!pharmacy|| !pharmacy.id) return
    PharmacyService.getDefaultSignature(pharmacy.id).then((response) => {
        setDefaultSignature(response.data.signatureHtml);
    }).catch((error) => {
        props.dispatch(
            actionDisplayMessage(
            "Une erreur s'est produite lors de la recupération de vos données de Pharmacie, veuillez recharger la page ou contacter le support",
            "error"));
    })

  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  ,[pharmacy]);


  useEffect(() => { 
    PharmacyService.getCurrentPharmacie().then((response) => {
        setPharmacy(response.data.content[0]);
        setFormDataPharmacy(response.data.content[0]); 
        setIsLoadingPharmacy(false); 

    }).catch((error) => {
        props.dispatch(
            actionDisplayMessage(
            "Une erreur s'est produite lors de la recupération de vos données de Pharmacie, veuillez recharger la page ou contacter le support",
            "error"));
    })

  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  ,[props]);


  useEffect(() => {
      UserService.getCurrentUser()
        .then((response) => {
          setUser(response.data);
          setFormDataUser(response.data );           
          setIsLoadingUser(false); 
        })
        .catch((error) => {
          props.dispatch(
                actionDisplayMessage(
                "Une erreur s'est produite lors de la recupération de vos données Utilisateur, veuillez recharger la page ou contacter le support",
                "error"));
          console.log(error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
   ,[props]);

   useEffect(() => {
    if(!user )return;
    if(user.subscriptionStatus){
      if(user.subscriptionStatus === 'TRIAL'){
        setSubscriptionChip({severity:"info", label:"Version d'essai"})
      }
      else if(user.subscriptionStatus === 'VALID'){
        setSubscriptionChip({severity:"success", label:"Abonnement actif"})
      }
      else if(user.subscriptionStatus === 'CANCELED'){
        setSubscriptionChip({severity:"warning", label:"Abonnement arrêté"})
      }
      else if(user.subscriptionStatus === 'FAILED'){
        setSubscriptionChip({severity:"error", label:"Erreur paiement"})
      }
    }
    if(user.checkoutUrl){
      setAccountURL({url:user.checkoutUrl, variant:'contained', label:"Passer à la version PRO",urlStatus: "CHECKOUT"})
    }
    if(user.customerPortalUrl && user.subscriptionStatus !== 'TRIAL'){
      setAccountURL({url:user.customerPortalUrl, variant:'outlined', label:"Gerer votre abonnement ",urlStatus: "PORTAL"})
    }
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
 ,[user]);

  

   const handleEditClick = () => {
    setIsEditMode(true);
  };

   const handleDiscardClick = () => {
    setFormDataUser(user);
    setFormDataPharmacy(pharmacy)
    setIsEditMode(false);
  };

  const handleChangePharmacyInfo = (event) => {
    let { name, value } = event.target;
    const updatedFormDataPharmacy = { ...formDataPharmacy, [name]: value }; 
    setFormDataPharmacy(updatedFormDataPharmacy);           
  };  
  
  const handleSignatureResetClick = () => {
    const updatedFormDataPharmacy = { ...formDataPharmacy, signatureHtml: defaultSignature }; 
    setFormDataPharmacy(updatedFormDataPharmacy);           
  };

  const handleChangeUserInfo = (event) => {
    let { name, value } = event.target;
    const updatedFormDataUser = { ...formDataUser, [name]: value }; 
    setFormDataUser(updatedFormDataUser);           
  };

  useEffect(() => {
    if(!formDataPharmacy || !formDataUser) return;
     //Check Pharmacy
     const errorsMgsPharmacy = 
       UtilService.getErrorMsgValidatingPharmacyName(formDataPharmacy.name)
     + UtilService.getErrorMsgValidatingPhoneNumber(formDataPharmacy.phoneNumber)
     + UtilService.getErrorMsgValidatingAddress(formDataPharmacy.address)
     + UtilService.getErrorMsgValidatingEmail(formDataPharmacy.email,true);
 
     //Check User
     const errorsMgsUser = UtilService.getErrorMsgValidatingName(formDataUser.firstName)
     + UtilService.getErrorMsgValidatingName(formDataUser.lastName);
 
     const formValid = errorsMgsPharmacy.length === 0 && errorsMgsUser.length === 0 ;
     setIsFormValid(formValid);
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
 ,[formDataUser,formDataPharmacy]);


   const handleSaveClick = () => {
    if(!isFormValid) return;
    const successMessage = "Les modifications ont été enregistrées avec succès";
    setIsLoadingUser(true);
    setIsLoadingPharmacy(true);

    PharmacyService.savePharmacy(pharmacy.id,formDataPharmacy).then((response) => {
      setPharmacy(response.data);
      setFormDataPharmacy(response.data);
      setIsLoadingPharmacy(false);
      setIsEditMode(false);
      props.dispatch(actionDisplayMessage(successMessage,"success"));

      })
      .catch((error) => {
          console.error(error);
          setIsLoadingPharmacy(false);
          props.dispatch(actionDisplayMessage("Une erreur s'est produite lors de l'enregistrement des modifications sur la pharmacie","error"));
      });

    UserService.saveUser(user.id,formDataUser)
        .then((response) => {
            setUser(response.data);
            setFormDataUser(response.data);
            setIsLoadingUser(false);
            setIsEditMode(false);
            props.dispatch(actionDisplayMessage(successMessage,"success"));

        })
        .catch((error) => {
            console.error(error);
            setIsLoadingUser(false);
            props.dispatch(actionDisplayMessage("Une erreur s'est produite lors de l'enregistrement des modifications Utilisateur","error"));
        });
  };

  if (!props.user.accessToken) {
    return <Navigate replace to="/" />;
  }

  if (isLoadingUser || isLoadingPharmacy) {
        return <CircularProgress />;
  }

  return (
    <Box mx="auto" p={4} width="900px" >
      
      <Paper variant="outlined">
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
            {isEditMode ? (
              <>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<SaveIcon />}
                  onClick={handleSaveClick}
                  sx={{ mr: 1 }}
                  disabled={!isFormValid}
                >
                  Enregistrer
                </Button>
               
                  <Button variant="outlined" startIcon={<CancelIcon />} onClick={handleDiscardClick}>
                    Annuler
                  </Button>
                
              </>
            ) : (
                <Button variant="outlined" startIcon={<EditIcon />} onClick={handleEditClick}>
                  Modifier
                </Button>
            )}
          </Box>
        {/* Ajoutez ici les boutons pour éditer, enregistrer et annuler */}
      </Paper>
      <Paper variant="outlined">
        <Box p={2}>
          <Typography variant="h5" align="center">
              Mon abonnement          
          </Typography>
          <Grid container spacing={2} mt={2}>
              <Grid item xs={6}>
              <Stack direction="row" alignItems="center" spacing={2}>
                <Typography variant="body1" align="center">
                Votre status :         
                </Typography>
                <Chip color={subscriptionChip.severity} label={subscriptionChip.label} sx={{'& .MuiChip-label': { fontSize: '0.9rem' } }} />
               </Stack>
              </Grid>
              <Grid item xs={6} >
              <Stack direction="row" justifyContent="center" spacing={2}>
              

              <Button variant={accountURL.variant}
                      href={accountURL.url}
                      startIcon={accountURL.urlStatus === "CHECKOUT"? <RocketLaunchIcon/>:<AccountBalanceIcon />}> 
                     {accountURL.label}
              </Button>
              </Stack>
              </Grid>

          </Grid>
        </Box>
      </Paper>
      <Paper variant="outlined">
        <Box p={2}>
          <Typography variant="h5" align="center">
            Informations personnelles
          </Typography>
          <Grid container spacing={2} mt={2}>
              <Grid item xs={6}>
                <EditableField
                  label="Prénom"
                  value={formDataUser.firstName}
                  name="firstName"
                  isEditable={isEditMode}
                  getErrorMsgValidatingFieldFct={UtilService.getErrorMsgValidatingName}
                  onChange={handleChangeUserInfo}
                  isRequired
                />
              </Grid>
              <Grid item xs={6}>
                <EditableField
                  label="Nom"
                  value={formDataUser.lastName}
                  name="lastName"
                  isEditable={isEditMode}
                  getErrorMsgValidatingFieldFct={UtilService.getErrorMsgValidatingName}
                  onChange={handleChangeUserInfo}
                  isRequired
                />
              </Grid>
              <Grid item xs={6}>
                <EditableField
                  label="Adresse email de connexion au compte"
                  value={formDataUser.email}
                  name="lastName"
                  isEditable={false}
                  InputProps={{disabled:true}}
                />
              </Grid>
              <Grid item xs={6}>
                <EditableField
                  label="Numéro RPPS du titulaire"
                  value={formDataUser.rpps}
                  name="rpps"
                  isEditable={false}
                  InputProps={{disabled:true}}
                />
              </Grid>
          </Grid>
        </Box>
      </Paper>
      <Box mt={4} />
      <Paper variant="outlined">
        <Box p={2}>
          <Typography variant="h5" align="center">
            Informations de la pharmacie
          </Typography>
          <Grid container spacing={2} mt={2}>
              <Grid item xs={12}>
                <EditableField
                  label="Nom"
                  value={formDataPharmacy.name}
                  name="name"
                  isEditable={isEditMode}
                  getErrorMsgValidatingFieldFct={UtilService.getErrorMsgValidatingPharmacyName}
                  onChange={handleChangePharmacyInfo}
                  isRequired
                />
              </Grid>
              <Grid item xs={6}>
                <EditableField
                  label="Numéro de téléphone"
                  value={formDataPharmacy.phoneNumber}
                  name="phoneNumber"
                  isEditable={isEditMode}
                  getErrorMsgValidatingFieldFct={UtilService.getErrorMsgValidatingPhoneNumber}
                  onChange={handleChangePharmacyInfo}
                  
                />
              </Grid>
              <Grid item xs={6}>
                <EditableField
                  label="Adresse email de contact pour vos patients"
                  value={formDataPharmacy.email}
                  isEditable={isEditMode}
                  name="email"
                  getErrorMsgValidatingFieldFct={(value) => UtilService.getErrorMsgValidatingEmail(value, true)}
                  onChange={handleChangePharmacyInfo}
                />
              </Grid>
              <Grid item xs={12}>
                <EditableField
                  label="Adresse de votre pharmacie"
                  value={formDataPharmacy.address}
                  isEditable={isEditMode}
                  name="address"
                  getErrorMsgValidatingFieldFct={UtilService.getErrorMsgValidatingAddress}
                  onChange={handleChangePharmacyInfo}
                />
              </Grid>
              <Grid item xs={12}>
              <Typography pb={3} variant="h5" align="center"> 
                Signature de vos emails 
              </Typography>
                <TextField
                  name='signatureHtml'
                  id='signatureHtml'
                  multiline
                  fullWidth
                  value={formDataPharmacy.signatureHtml}
                  onChange={handleChangePharmacyInfo}
                  minRows={2}
                  maxRows={20}
                  variant="outlined"
                  inputProps={{maxLength : 1000}}
                  InputProps={{readOnly:!isEditMode}}
                  sx={{backgroundColor: isEditMode ?'#e8f0fe':''}}
                />
                  <FormHelperText id="comment">1000 caractères maximum</FormHelperText>
                  {isEditMode && formDataPharmacy.signatureHtml !== defaultSignature &&(
                    <Button
                      variant="outlined"
                      color="primary"
                      startIcon={<RestartAltIcon />}
                      onClick={handleSignatureResetClick}
                      sx={{ mr: 1 }}
                    >
                      REINITIALISER LA SIGNATURE
                    </Button>
                  )}
                </Grid>
          </Grid>        
        </Box>
      </Paper>

      <Button
        variant="contained"
        color="primary"
        startIcon={<SaveIcon />}
        onClick={handleSaveClick}
        sx={{ mt: 1 }}
        disabled={!isFormValid}
        >
        Enregistrer
      </Button>
        <Box mt={4} />

    </Box>
  );
}

function mapStateToProps(state) {
  const user = state.authReducer.user;
  return {
    user
  };
}

export default connect(mapStateToProps)(MyAccount);
