
//https://xml.insee.fr/schema/nir.html#:~:text=leurs%20besoins%20propres%22.-,3.3.Type%20NIRType,-La%20d%C3%A9finition%20d%27un
export const validFrenchSsnRegEx = /^([12])([0-9]{2})([0-9]{2})(2[AB]|[0-9]{2})([0-9]{3})([0-9]{3})([0-9]{2})$/;
export const spacingCharRegEx = /\s+/g;


export class UtilService {
    
  removeNonDigitCharacterForSSN(inputString) {
    const cleanedString = inputString.replace(/(?<=2)[^AB\d]|(?<!2)[^\d]/g, '');// allow 2A or 2B
    return cleanedString;
  }

  isValidSSN(ssn){
    if(!ssn) return false;
    const cleanedSSN = ssn.replace(spacingCharRegEx, '');
    return validFrenchSsnRegEx.test(cleanedSSN);
  }
  /**
   * 
   * @param {*} ssn social security number like 132065634567890
   * @returns a formated string that is easier to read like 1 32 06 56 345 678 90
   */
  formatFrenchSSN (ssn){
    ssn = this.removeNonDigitCharacterForSSN(ssn);
    if( !this.isValidSSN(ssn)){
      return ssn.replace(spacingCharRegEx,'')
              .replace(validFrenchSsnRegEx,"$1 $2 $3 $4 $5 $6 $7");
    }
    // Add spaces after 1st, 3rd, 5th, 7th, 10th, and 13th characters
    if (ssn.length > 1) {
      ssn = ssn.slice(0, 1) + ' ' + ssn.slice(1);
    }
    if (ssn.length > 4) {
      ssn = ssn.slice(0, 4) + ' ' + ssn.slice(4);
    }
    if (ssn.length > 7) {
      ssn = ssn.slice(0, 7) + ' ' + ssn.slice(7);
    }
    if (ssn.length > 10) {
      ssn = ssn.slice(0, 10) + ' ' + ssn.slice(10);
    }
    if (ssn.length > 14) {
      ssn = ssn.slice(0, 14) + ' ' + ssn.slice(14);
    }
    if (ssn.length > 18) {
      ssn = ssn.slice(0, 18) + ' ' + ssn.slice(18);
    }
    if (ssn.length > 21) {
      ssn = ssn.slice(0, 21);
    }

    return ssn;
  }
  static isValidEmail (email) {
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return regex.test(email);
  };

  isNavbarDisplay(location){
    const pathWIthNoNavBar = ["/","/signup","/verify"]
    return !pathWIthNoNavBar.includes(location.pathname);
  } 
  get24HourTime(dateString) {
    try {
      const dateObject = new Date(dateString); // convert the ISO 8601 string to a Date object
      const hour = dateObject.getHours().toString().padStart(2, '0'); // get the hour as a string and pad it with a leading zero if necessary
      const minute = dateObject.getMinutes().toString().padStart(2, '0'); // get the minute as a string and pad it with a leading zero if necessary
      return `${hour}:${minute}`; // concatenate the hour and minute with a colon separator
    } catch (error) {
      return ''; 
    }
  }
  /**
 * Checks if a given date is in the past.
 * @param {string} dateString - The date string to be checked (e.g., '2023-07-13').
 * @returns {boolean} `true` if the date is in the past, `false` otherwise.
 * @throws {Error} If an error occurs while checking the date.
 */ 
  isDateInPast(dateString) {
    try {
      const trimmedDateString = dateString.replace(/\.\d+/, ''); // Remove the fractional part of the seconds
      const dateObject = new Date(trimmedDateString); 
  
      // Get the current date and time
      const currentDate = new Date();
  
      if (dateObject < currentDate) {
        return true; // The date is in the past
      } else {
        return false; // The date is in the future or is the current date
      }
    } catch (error) {
      return false; 
    }
  }
  
/**
 * Formats a date string using the specified date format.
 * @param {string} dateString - The date string to be formatted (e.g., '2023-07-13').
 * @returns {string} The formatted date string (e.g., '15 juil. 2023').
 * @throws {Error} If an error occurs while formatting the date.
 */
  handleDateFormat(dateString) {
      try {
        const dateFormatter = new Intl.DateTimeFormat('fr-FR', {
          day: '2-digit',
          month: 'short',
          year: 'numeric',
        });
        const trimmedDateString = dateString.replace(/\.\d+/, ''); // Remove the fractional part of the seconds
        const dateObject = new Date(trimmedDateString); // Create a Date object
        return dateFormatter.format(dateObject);
      } catch (error) {
        //console.error('Error formatting date:', error);
        return 'Non renseigné';
      }
    }
      /*
      takes a date string in the format 'yyyy-MM-ddTHH:mm:ss.sss' 
      (such as '2023-03-20T16:47:24.03') and returns the date string in the format 'yyyy-MM-dd':
      */
      formatDate_ddMMYYYY(dateString) {
        try {
          const date = new Date(dateString);
          const year = date.getFullYear();
          const month = String(date.getMonth() + 1).padStart(2, '0');
          const day = String(date.getDate()).padStart(2, '0');
          return `${day}/${month}/${year}`;
        
        } catch (error) {
          //console.error('Error formatting date:', error);
          return 'Non renseigné';
        }
      }
      /**
       * Returns today's date formatted using a specified date format.
       * @returns {string} The formatted date string (e.g., '15 juil. 2023').
       * @throws {Error} If an error occurs while formatting the date.
       */
      getFormattedTodayDate() {
        try {
            const dateFormatter = new Intl.DateTimeFormat('fr-FR', {
                day: '2-digit',
                month: 'short',
                year: 'numeric',
            });
            const today = new Date(); // Get today's date
            return dateFormatter.format(today);
        } catch (error) {
            //console.error('Error formatting date:', error);
            return 'Non renseigné';
        }
      }
      todayDate_ddMMYYYY() {
        return new Date().toISOString().split('T')[0];
      }
      /**
       * 
       * @param {*} name represent the firstname or the lastname to validate
       * @returns a error message un french
       */
      getErrorMsgValidatingName (name, fieldName) {
        const regex = /^[a-zA-Z\u00C0-\u017F\s-]{2,49}$/;
        if(!name)return "Veuillez entrer un "+(fieldName ? fieldName+"": "nom")

        if(regex.test(name)) return '';
        if(name.length > 48) return "Champ trop grand, 49 caractères maximum"
    
        return "Aucun caractère spécial autorisé (espaces autorisés pour les prénoms ou noms composés)"
      };

       /**
       * 
       * @param {*} name represent the name of the pharmacy
       * @returns a error message in french
       */
      getErrorMsgValidatingPharmacyName (name) {
        if(!name) return 'Veuillez entrer un nom pour votre pharmacie'
        const regex = /^[a-zA-Z0-9\u00C0-\u017F\s-_']{2,49}$/;
        if (regex.test(name)) return '';
        
        if (name.length > 48) return "Champ trop grand, 49 caractères maximum";
      
        return "Aucun caractère spécial autorisé (espaces, - , _ autorisés )";
      }

      /**
       * 
       * @param {*} name represent the password of the acccount
       * @returns a error message in french
       */
      getErrorMsgValidatingPassword(value) {
        const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#$%&()*_+=~.,;:?])[A-Za-z\d!@#$%&()*_+=~.,;:?]{8,}$/;
        if (!passwordRegex.test(value)) {
          return "Le mot de passe doit faire au minimum 8 caractères et contenir au moins 1 lettre, 1 nombre, 1 des caractères spéciaux suivants : ! @ # $ % & ( ) * _ + = ~ . , ; : ?";
        }
        return '';
      }
      /**
       * 
       * @param {*} number the phone number as a string
       * @returns an error message in french if the phone number is not correct
       */
      getErrorMsgValidatingPhoneNumber (number){
        if (!number) return '';
        const regex = /^(\+)?[0-9\s]{1,17}$/;
        if(regex.test(number)) return '';
    
    
        return "le numéro doit commencer par 0 ou par +(indicatif du pays), +33 pour la france";
      };
      /**
       * 
       * @param {*} email  as a string 
       * @returns an error message in french if the email  is not correct
       */
      getErrorMsgValidatingEmail (email,canBeEmpty = false) {
        if(!email && canBeEmpty )return '';
        const baseErrorMsg="Veuillez entrer un email correct";
        if(!email) return baseErrorMsg;
    
        if(email.length> 320) return 'Email trop long, 320 caractères max';
    
        if(UtilService.isValidEmail(email)) return '';
    
        return baseErrorMsg;
      }
      /**
       * 
       * @param {*} address as a string
       * @returns an error message in french if the adress  is not correct
       */
      getErrorMsgValidatingAddress (address)  {
        if(!address || address.length <= 300) return '';
        return "Pas plus de 300 caractères autorisés"
      };
      
  
}

const utilService = new UtilService();

export default utilService;