import React,{ useState } from 'react';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router';
import { Drawer, List, ListItem, ListItemIcon, ListItemText, Dialog, DialogTitle, DialogContent, IconButton } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import BlurOnIcon from '@mui/icons-material/BlurOn';
import PregnantWomanIcon from '@mui/icons-material/PregnantWoman';
import CloseIcon from '@mui/icons-material/Close';
import LockIcon from '@mui/icons-material/Lock';
import BiotechIcon from '@mui/icons-material/Biotech';

import { useTheme } from '@mui/material/styles';
import { useLocation } from 'react-router-dom';
import { tokensColors } from "../../theme.js";

function PatientSidebar({ show }) {
  const { patientId } = useParams();
  const theme = useTheme();
  const location = useLocation();
  const colors = tokensColors();
  const [dialogOpen, setDialogOpen] = useState(false);

  const sidebarItems = [
    {
      text: 'Fiche Patient',
      icon: <PersonIcon />,
      link: `/patient/${patientId}`,
    },
    {
      text: 'Entretien(s) chimiothérapie',
      icon: <BlurOnIcon />,
      link: `/patient/${patientId}/chemo`,
     //disabled: disableChemoInterview
    },
    {
      text: 'Entretien femme enceinte',
      icon: <PregnantWomanIcon />,
      link: `/patient/${patientId}/pregnancy`,
    },
    {
      text: 'Dépistage',
      icon: <BiotechIcon />,
      link: `/patient/${patientId}/medical_test`,
    },
  ];

  const handleListItemClick = (item) => {
    if (item.disabled) {
      setDialogOpen(true);
    }
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };


  if (!show) {
    return null;
  }

  return (
    <Drawer
      variant="permanent"
      anchor={"left"}
      PaperProps={{
        sx: { 
          top: 64 + 8,
          width: theme.layout.drawerWidth,
          boxSizing: 'border-box'
        }     
      }}
      sx={{
        maxWidth: theme.layout.drawerWidth
      }}
    >

      <List>
        {sidebarItems.map((item) => (
          <ListItem
            key={item.text}
            component={Link}
            disabled={item.disabled}
            to={item.disabled ? null:item.link}
            sx={{
              color: location.pathname === item.link ? colors.blueAccent[400] : 'inherit'
            }}
            onClick={() => handleListItemClick(item)}

          >
            <ListItemIcon>
              {React.cloneElement(item.icon, {
                sx: {
                  color: location.pathname === item.link ? colors.blueAccent[400] : 'inherit',
                },
              })}
            </ListItemIcon>
            <ListItemText primary={item.text} />
          </ListItem>
        ))}
      </List>
      <Dialog open={dialogOpen} onClose={handleDialogClose} sx={{ overflowX: 'hidden' }}>
        <DialogTitle>
        <LockIcon style={{ marginRight: '0.5em' }} /> Cette fonctionalité a été désactivé 
       <IconButton
            edge="end"
            color="inherit"
            onClick={handleDialogClose}
            sx={{
              position: 'absolute',
              top: theme.spacing(1),
              right: theme.spacing(1),
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent >
        <DialogContent>
  <p>Afin de garantir que vos patients reçoivent les meilleurs services possibles, nous vous encourageons à vous former correctement pour dispenser des entretiens pharmaceutiques sur les anticancéreux oraux au long cours.</p>
  <p>Vous pouvez trouver toutes les informations sur la formation en partenariat avec Quiz Coach en suivant ce lien :</p>
  <p><a target="_blank" href="https://quiz-coach.com/formation/entretiens-pharmaceutiques-anticancereux-oraux/"rel="noopener noreferrer">https://quiz-coach.com/formation/entretiens-pharmaceutiques-anticancereux-oraux/</a></p>
  <p>Si vous avez déjà suivi une formation similaire, veuillez contacter health bees pour déverrouiller gratuitement cette fonctionnalité.</p>
  <p>contact: romain.barlo@healthbees.fr</p>
      </DialogContent>
         </DialogContent>
      </Dialog>
    </Drawer>
  );
}

export default PatientSidebar;
