import React,{ useState, useEffect } from 'react';
import { Navigate } from "react-router-dom";
import { CssVarsProvider } from '@mui/joy/styles';
import GlobalStyles from '@mui/joy/GlobalStyles';
import CssBaseline from '@mui/joy/CssBaseline';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Link from '@mui/joy/Link';
import Input from '@mui/joy/Input';
import Typography from '@mui/joy/Typography';
import { connect } from 'react-redux';
import {
    actionLoginRecaptchaV3,
    actionLoginRecaptchaV2,
    actionDisplayMessage,
} from "../../actions/auth.action"
import { useLocation  } from 'react-router-dom';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { ButtonBase } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import ReCAPTCHA from 'react-google-recaptcha';
import environmentService  from "../../services/environment.service.js";




function Login( props) {
  const location = useLocation();
  const {executeRecaptcha } = useGoogleReCaptcha();
  const [displayRecaptchaV2,setDisplayRecaptchaV2] = useState(false);
  const [recaptchaV2Token,setRecaptchaV2Token] = useState(null);
  const [email, setEmail] = useState("");

  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoginButtonEnabled, setIsLoginButtonEnabled] = useState(true);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const emailParam = params.get('email');
    if (emailParam) {
      setEmail(emailParam)
    }
  }, [location]);

  const handleRecaptchaV2 = (value ) => {
    setRecaptchaV2Token(value);
  }
  useEffect(() => {
    if(recaptchaV2Token) {
        setIsLoginButtonEnabled(true);
    }
    }, [recaptchaV2Token]);

  const handleLoginWithRecaptchaV2  = async (email, password) => {
        if(!recaptchaV2Token){
            props.dispatch(
                actionDisplayMessage(
                    'Erreur sur le recaptcha, veuillez recharger la page ou contacter le support',
                    "error"));
            setIsLoading(false);
            return;
        }
        try {
            await actionLoginRecaptchaV2(email, password, recaptchaV2Token,props.dispatch);
            setIsLoading(false);

        }
        catch (error) {
            console.error(error);
            setIsLoading(false);
            if (typeof error === 'string' && error.includes("Invalid reCAPTCHA")) {
                setDisplayRecaptchaV2(true);
                setIsLoginButtonEnabled(false);
                props.dispatch(
                    actionDisplayMessage(
                        'Erreur sur le recaptcha, veuillez recharger la page ',
                        "error"
                    )
                );
                window.grecaptcha.reset();
                setRecaptchaV2Token(null);
                setIsLoginButtonEnabled(false);
            }
        }

    }

    const handleLoginWithRecaptchaV3 = async (email, password) => {
        let recaptchaV3Token = "DEFAULT_OFFLINE_TOKEN";
        try {
            recaptchaV3Token = await executeRecaptcha('signIn');
            console.log('handleLoginWithRecaptchaV3');
            await actionLoginRecaptchaV3(email, password, recaptchaV3Token,props.dispatch);
            setIsLoading(false);
        } catch (error) {
            console.error(error);
            setIsLoading(false);
            if (typeof error === 'string' && error.includes("Invalid reCAPTCHA V3")) {
                setDisplayRecaptchaV2(true);
                setIsLoginButtonEnabled(false);
                props.dispatch(
                    actionDisplayMessage(
                        'Erreur sur le recaptcha, veuillez cliquer sur je ne suis pas un robot ',
                        "error"
                    )
                );
            }
        }
    };

  const handleLoginClick  = async (event) => {

      event.preventDefault();
      setIsLoading(true);
      const formElements = event.currentTarget.elements;

      const email = formElements.email.value;
      const password=  formElements.password.value;
      if(displayRecaptchaV2){
          handleLoginWithRecaptchaV2(email,password)
      }
      else {
          handleLoginWithRecaptchaV3(email,password)
      }
  }
  const handleLoginChange = (event) => {
    const emailValue = event.target.value;
    setEmail(emailValue);           
  };

  const entretienTransaparentLogoURL  = process.env.PUBLIC_URL + "/icons/entretien_logo.png";
  const healthBeesTransaparentLogoURL  = process.env.PUBLIC_URL + "/icons/heathbees_logo_transparent.png";


  if (props.user.accessToken) {
      return <Navigate replace to="/dashboard" />;
  } else {
  return (
  <CssVarsProvider
      disableTransitionOnChange>
  <CssBaseline />
  <GlobalStyles
        styles={{
          ':root': {
            '--Collapsed-breakpoint': '769px', // form will stretch when viewport is below `769px`
            '--Cover-width': '40vw', // must be `vw` only
            '--Form-maxWidth': '700px',
            '--Transition-duration': '0.4s', // set to `none` to disable transition
          },
        }}
      />
    <Box
      sx={(theme) => ({
        width:
          'clamp(100vw - var(--Cover-width), (var(--Collapsed-breakpoint) - 100vw) * 999, 100vw)',
        transition: 'width var(--Transition-duration)',
        transitionDelay: 'calc(var(--Transition-duration) + 0.1s)',
        position: 'relative',
        zIndex: 1,
        display: 'flex',
        justifyContent: 'flex-end',
        backdropFilter: 'blur(4px)',
        backgroundColor: 'rgba(255 255 255 / 0.6)',
      })}
      >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100dvh',
          width:
            'clamp(var(--Form-maxWidth), (var(--Collapsed-breakpoint) - 100vw) * 999, 100%)',
          maxWidth: '100%',
          px: 2,
        }}
        >
        <Box
          component="header"
          sx={{
            py: 3,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Typography
            fontWeight="lg"
            fontSize={18}
            startDecorator={
              <Box
              component="img"
              sx={{
                maxWidth: 38,
              }}
              src={healthBeesTransaparentLogoURL}
            />
              }
            >
              health bees
            </Typography>
        </Box>
        <Box
            component="main"
            sx={{
              my: 'auto',
              py: 2,
              pb: 5,
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              width: 400,
              maxWidth: '100%',
              mx: 'auto',
              borderRadius: 'sm',
              '& form': {
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
              },
            }}
          >
          <div>
          <Box
              component="img"
              sx={{
                maxWidth: 250,
                marginLeft: '60px',
                marginBottom: '40px',
                display: { xs: 'none',sm: 'flex', md: 'flex', }
              }}
              src={entretienTransaparentLogoURL}
      />


              <Typography component="h3" fontSize="xl1" fontWeight="lg">
                L'application qui gère pour vous vos Entretiens pharmaceutiques
              </Typography>
              <Typography level="body2" sx={{ my: 1, mb: 3 }}>
                Pour vous connecter, entrez vos identifiants !
              </Typography>
          </div>
          <form onSubmit={handleLoginClick}>
          <FormControl required>
            <FormLabel>Email</FormLabel>
            <Input placeholder="Entrer votre email" type="email" name="email" value={email}  onChange={handleLoginChange}/>
          </FormControl>
          <FormControl required>
            <FormLabel>Mot de Passe</FormLabel>
            <Input  placeholder="•••••••"
                    type={showPassword ? 'text' : 'password'}
                    name="password"
                    endDecorator={
                      <ButtonBase 
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end">
                      {showPassword ? <VisibilityOff /> : <Visibility />}</ButtonBase>
                     
                    }/>
          </FormControl>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
          {displayRecaptchaV2 && (
              <ReCAPTCHA
                  sitekey={environmentService.getReCaptchav2SiteKey()}
                  onChange={handleRecaptchaV2}
              />
          )}

          <Link sx={{display :"none" }}fontSize="sm" href="#replace-with-a-link" fontWeight="lg">
            Mot de passe oublié ?
          </Link>
          </Box>
          <Button type="submit" fullWidth disabled={isLoading && !isLoginButtonEnabled} >
              {isLoading ? <CircularProgress size={24} /> : 'Connectez vous !'}
          </Button>
          <Link  href="/signup">
          <Typography level="body3" textAlign="center" fontWeight={"medium"}>
             Pas encore de compte, cliquer ici pour nous rejoindre ! 
            </Typography>
            </Link>
          </form>
        </Box>
        <Box component="footer" sx={{ py: 3 }}>
            <Typography level="body3" textAlign="center">
              © <strong>health bees  </strong>, société française &#127467;&#127479;
            </Typography>
            <Typography level="body3" textAlign="center">
               Fait avec &#128154; par des pharmaciens pour les pharmaciens !
            </Typography>
        </Box>
      </Box>
    </Box>

    <Box
        sx={(theme) => ({
          height: '100%',
          position: 'fixed',
          right: 0,
          top: 0,
          bottom: 0,
          left: 'clamp(0px, (100vw - var(--Collapsed-breakpoint)) * 999, 100vw - var(--Cover-width))',
          backgroundColor: 'background.level1',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundImage:
            'url(https://images.unsplash.com/photo-1527181152855-fc03fc7949c8)',

        })}
      />

    </CssVarsProvider>

  );}

} 

function mapStateToProps(state) {
  const { isLoggedIn, user } = state.authReducer;
  return {
    isLoggedIn,
    user
  };
}


export default connect(mapStateToProps)(Login);

