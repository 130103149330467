import {React,  useState, useEffect } from 'react';
import {
   Paper,
   CircularProgress,
   Box,
   IconButton,
   Typography, 
   Grid,
  TextField,
  Chip } from '@mui/material';
import { DataGrid,frFR } from '@mui/x-data-grid';
import { connect } from 'react-redux';
import { Navigate,Link,useNavigate } from "react-router-dom";
import PatientService from '../../services/patient.service.js'
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import PregnantWomanIcon from '@mui/icons-material/PregnantWoman';
import BlurOnIcon from '@mui/icons-material/BlurOn';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';

import utilService from '../../services/util.service.js';

function PatientSearch(props) {
  const [patients, setPatients] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [patientSearchStr, setPatientSearchStr] = useState('');
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize,setPageSize] = useState(25);
  const [totalElements, setTotalElements] = useState(0);
  const navigate = useNavigate();
  
  const fetchAllPatients = () => {

    PatientService.getAllPatient(pageNumber,pageSize)
    .then(
    (response) => { 
                if(response){ // Can be cancelled so reponse is undefined
                  setIsLoading(false);
                  setPatientsForDisplay(response.data.content);
                  setTotalElements(response.data.totalElements);
                }
              }
    )
    .catch(error => {
      console.log(error)
    });
  }

  useEffect(() => {
    fetchAllPatients();
    // eslint-disable-next-line 
  }, []);

  const setPatientsForDisplay = (patients) => {
    patients.map(patient => {
        if( patient?.socialSecurityNumber ){
          patient.socialSecurityNumber= utilService.formatFrenchSSN(patient.socialSecurityNumber)
        }
        return patient;
    })
    setPatients(patients);

  }

  const fetchPatients = (patientSearchValue ) => {
    PatientService.searchPatient(patientSearchValue, pageNumber, pageSize)
      .then(response => {
        if(response){ // Can be cancelled so reponse is undefined
          setIsLoading(false);
          setPatientsForDisplay(response.data.content);
          setTotalElements(response.data.totalElements);
        }
      })
      .catch(error => {
        console.log(error);
        setIsLoading(false); // You might want to set loading to false in case of an error as well
        
      });
  };

  useEffect(() => {
    if(isLoading)return;
    setIsLoading(true);
    if(patientSearchStr){
      fetchPatients(patientSearchStr);
    }
    else{
      fetchAllPatients();
    }
    // eslint-disable-next-line
  }, [pageNumber,pageSize]);


  const handlePatientSearchStrChange= (event)=> {
    const searchString = event.target.value.trim()
    setPatientSearchStr(event.target.value);
    if(searchString){
      fetchPatients(searchString);
    }
    else{
      fetchAllPatients();
    }
  }

  const getColorFromStatus = (status) => {
    switch (status) {
        case 'BILLED':
            return 'success';
        case 'DRAFT':
            return 'warning';
        case 'VALIDATED':
            return 'info';
        default:
            return 'default';
    }
  };
  const getConsultationGroupLabelObjectFromType = (type,patientId) => {
    switch (type) {
        case 'PREGNANCY':
          return {label : 'Enceinte', link:`/patient/${patientId}/pregnancy`,icon : <PregnantWomanIcon /> };
        case 'ORAL_CANCER_TREATMENT':
          return {label : 'Chimio'  , link:`/patient/${patientId}/chemo`,icon : <BlurOnIcon />};
        default:
          return {label : 'Entretien', link:`/patient/${patientId}/`,icon : <QuestionMarkIcon />};
    }
  };
  const columns = [
    { field: 'socialSecurityNumber', headerName: 'Numéro de securité sociale', headerAlign: 'center', align:'center', width: 250 },
    { field: 'firstName' , headerName: 'Prénom', headerAlign: 'center', align:'center',width: 150 },
    { field: 'lastName', headerName: 'Nom', headerAlign: 'center', align:'center',width: 150 },
    { field: 'email', headerName: 'Email', headerAlign: 'center', align:'center',width: 250 },
    { field: 'consultationGroupSummaries', headerName: 'Entretien(s)', headerAlign: 'center', align:'center',width: 250 ,
    renderCell: (params) => {
      //console.log(params.row.firstName)
      if(!params.row.consultationGroupSummaries) return
      const patientId = params.row.id;
      return (
        <>
          {params.row.consultationGroupSummaries.map((consultationSummarie, index) => {
              const labelObject = getConsultationGroupLabelObjectFromType(consultationSummarie.type,patientId);
              return (
                <Link key={index} to={labelObject.link} style={{ textDecoration: 'none', margin: '4px' }}>
                  <Chip 
                    icon={labelObject.icon} 
                    label={labelObject.label} 
                    variant="outlined" 
                    color={getColorFromStatus(consultationSummarie.status)} 
                  />
                </Link>
              );
            })}
        </>
      );      
    }}
  ];


    if (!props.user.accessToken) {
          return <Navigate replace to="/" />;
    }
    else {
      return (
        <Box mx="auto" p={4} maxWidth="1200px" 
        width={'100%'}>
            
    <Paper variant="outlined">

    <Grid container>
      <Grid item xs={0} md={1}/>
      <Grid item md={2}>
        <IconButton
            color="primary"
            component={Link}
            to="/patient/new"
            aria-label="Add new patient">
              <Typography sx={{padding:'10px',}}>Nouveau Patient</Typography>
            < LibraryAddIcon />
          </IconButton>
        </Grid>
        <Grid item xs={0} md={1}/>
        <Grid item md={6} >
        <TextField
                label="Recherche Patient "
                value={patientSearchStr}
                id="patientSearchStr"
                name="patientSearchStr"
                placeholder=' Numéro de SS ou nom ou prénom' 
                fullWidth
                margin="normal"
                variant="outlined"
                onChange={handlePatientSearchStrChange}
                inputProps={{ maxLength: 250 }}
            />
          </Grid>  
    </Grid>
    </Paper>
     { isLoading ? <CircularProgress /> :
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <DataGrid
        localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
        autoHeight
        rows={patients}
        columns={columns}
        pageSize={pageSize}
        page={pageNumber}
        rowCount={totalElements}
        onPageChange={(newPageNumber) => {setPageNumber(newPageNumber)}}
        onPageSizeChange={(newPageSize) => {setPageSize(newPageSize);setPageNumber(0);}}
        onCellClick={(params) => {if(params.field !=='consultationGroupSummaries') navigate(`/patient/${params.row.id}`);}}

        sx={{
          '& .MuiDataGrid-columnHeaders': {
            fontWeight: 'bolder',
            fontSize: '1.5em',
          },
          '& .MuiDataGrid-cell:hover': {
            color: 'primary.main',
          },
          '& .MuiDataGrid-cell': {
            fontSize: '1.2em',
            cursor: 'pointer'
          },
          '& .MuiDataGrid-cell .MuiChip-root': { // Targeting the Chip inside the cell
            cursor: 'pointer',
          },
          '& .MuiDataGrid-cell[data-field="consultationGroupSummaries"]:hover': {
            cursor: 'default',  // Resetting the cursor for the cell containing the Chip
          },
        }}
        disableColumnMenu
        rowsPerPageOptions={[25, 50,100]}
        paginationMode="server"
      />
    </Paper>
    }
    </Box> )
}
};

function mapStateToProps(state) {
const user  = state.authReducer.user;
return {
  user
};
}


export default connect(mapStateToProps)(PatientSearch);