import * as React from 'react';
import { Dialog, DialogTitle, DialogContent, Button, Grid, Typography, Box } from '@mui/material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import TACDetail from '../biling/TACDetail.component';
import { ConsultationGroup, Patient } from '../../types/types';

interface TACHelpDialogProps {
    open: boolean;
    onClose: () => void;
    consultationGroup: ConsultationGroup;
    patient: Patient;
    handleBillAction: (consultationGroupId: string) => void;
}

const TACHelpDialog: React.FC<TACHelpDialogProps> = ({ open, onClose, consultationGroup, patient, handleBillAction }) => {
    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullWidth
            disableRestoreFocus
            maxWidth="lg"
        >
            <DialogTitle>
                <Grid container>
                    <Grid item xs={10}>
                        <Box display="flex" justifyContent="center">
                            <Typography variant="h4">Code d'adhésion TAC</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={2}>
                        <Button
                            variant="outlined"
                            color="primary"
                            startIcon={<CloseRoundedIcon />}
                            onClick={onClose}
                        >
                            Quitter
                        </Button>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <TACDetail
                    handleBillAction={() => handleBillAction(consultationGroup.id)}
                    handleClose={onClose}
                    consultationGroup={consultationGroup}
                    patient={patient}
                />
            </DialogContent>
        </Dialog>
    );
};

export default TACHelpDialog;
