//import axios from "axios";
import axios, { CancelToken, isCancel } from "./axiosInstance.js";


class PatientService {
  constructor() {
    this.source = CancelToken.source();
  }
    
  getAllPatient(page = 0, pageSize = 1) {
    // Cancel the previous request
    this.source.cancel();

    // Create a new cancel token source
    this.source = CancelToken.source();

    return axios.get(`/patient?page=${page}&size=${pageSize}` , {
      cancelToken: this.source.token,
    })
    .catch(error => {
      if (!isCancel(error)) {
        throw error;
      }
    });
  }
  getPatient(id){
    return axios.get(`/patient/${id}`);
  }

  savePatient(patient){
    return axios.post(`/patient/${patient.id}`, patient);
  }

  createPatient(patient){
    return axios.post("/patient",patient );
  }

  searchPatient(patientSearchStr, page = 0, pageSize = 1) {
    // Cancel the previous request
    this.source.cancel();

    // Create a new cancel token source
    this.source = CancelToken.source();

    return axios.get(`/patient/search/${patientSearchStr}?page=${page}&size=${pageSize}`, {
      cancelToken: this.source.token,
    })
    .catch(error => {
      if (!isCancel(error)) {
        // Handle the error as you normally would
        throw error;
      }
    });

  }

}
export default PatientService = new PatientService() ; 
