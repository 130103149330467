import {useState, useEffect, React} from 'react';
import { Box, CircularProgress, Grid, Paper, Button, MenuItem, Select, InputLabel, FormControl } from '@mui/material';
import { DataGrid, GridColDef, frFR } from '@mui/x-data-grid';
import { connect, ConnectedProps } from 'react-redux';
import { Navigate, useNavigate } from "react-router-dom";
import MedicalTestService,{MEDICAL_TEST_URL_IDENTIFIER} from '../../../services/MedicalTestService.ts';
import {MedicalTest, MedicalTestStatus, MedicalTestType} from "../../../types/types.ts";
import MedicalTestResultChip from "../../../components/field/MedicalTestResulChip.tsx";
import EmailIcon from "@mui/icons-material/Email";
import SendResultDialog from '../covid19/SendResultDialog.tsx';
import utilService from "../../../services/util.service"; // Make sure the path is correct

interface RootState {
    authReducer: {
        user: {
            accessToken: string;
        };
    };
}

const mapStateToProps = (state: RootState) => ({
    user: state.authReducer.user,
});

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;


const MedicalTestSearch: React.FC<PropsFromRedux>  = ({ user}) => {
    const [medicalTest, setMedicalTest] = useState<MedicalTest[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [pageNumber, setPageNumber] = useState(0);
    const [pageSize, setPageSize] = useState(25);
    const [totalElements, setTotalElements] = useState(0);
    const [selectedTest, setSelectedTest] = useState<MedicalTest | null>(null);
    const [isDialogOpen, setDialogOpen] = useState(false);
    const [emailAddress, setEmailAddress] = useState('');
    const [medicalStatusForDialog, setMedicalStatusForDialog] = useState<MedicalTestStatus |null>(null);
    const [selectedStatus, setSelectedStatus] = useState<MedicalTestStatus | "ALL">(MedicalTestStatus.IN_PROGRESS);

    const navigate = useNavigate();


    const flattenMedicalTests = ( medicalTests :MedicalTest[]) :MedicalTest[] => {
        return  medicalTests.map(medicalTest => ({
            ...medicalTest,
            lastName: medicalTest.patientRef.lastName,
            firstName: medicalTest.patientRef.firstName,
            socialSecurityNumber: medicalTest.patientRef.socialSecurityNumber
        }));
    }


    const fetchAllMedicalTests = (status: MedicalTestStatus | "ALL" = null) => {
        MedicalTestService.getAllMedicalTestFromStatus(status ==="ALL"? null :status, pageNumber, pageSize)
            .then((response) => {
                if (response) { // Can be cancelled so response is undefined
                    setIsLoading(false);
                    setMedicalTest(flattenMedicalTests(response.content));
                    setTotalElements(response.totalElements);
                }
            })
            .catch(error => {
                console.log(error);
            });
    };

    useEffect(() => {
        fetchAllMedicalTests(selectedStatus);
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (isLoading) return;
        setIsLoading(true);
        fetchAllMedicalTests(selectedStatus);
        // eslint-disable-next-line
    }, [pageNumber, pageSize, selectedStatus]);

    const handleDialogOpen = (test: MedicalTest, email: string, status : MedicalTestStatus) => {
        setSelectedTest(test);
        setEmailAddress(email);
        setMedicalStatusForDialog(status);
        setDialogOpen(true);
    };

    const columns: GridColDef[] = [
        { field: 'type', headerName: 'Test', headerAlign: 'center', align:'center',width: 100,
            renderCell: (medicalTests) => {
                 switch(medicalTests.row.type){
                     case MedicalTestType.CYSTITIS:
                         return 'Cystite';
                     case MedicalTestType.COVID19:
                         return 'Covid 19';
                     case MedicalTestType.SORE_THROAT:
                         return 'Trod Angine';
                     default:
                         return medicalTests.row.type;
                 }
            }},
        { field: 'socialSecurityNumber', headerName: 'Numéro de SS', headerAlign: 'center', align:'center', width: 180 },
        { field: 'firstName' , headerName: 'Prénom', headerAlign: 'center', align:'center',width: 150 },
        { field: 'lastName', headerName: 'Nom', headerAlign: 'center', align:'center',width: 150 },
        { field: 'creationDate', headerName: 'Date Création', headerAlign: 'center', align:'center',width: 140 ,
            renderCell: (medicalTests) => {
                return ( utilService.formatDate_ddMMYYYY(medicalTests.row.creationDate));
            }},
        { field: 'medicalTestResult', headerName: 'Résultat', headerAlign: 'center', align:'center',width: 120 ,
            renderCell: (medicalTests) => {
                return ( <MedicalTestResultChip result={medicalTests.row.status}/>);
            }},
        { field: 'action', headerName: 'Action', headerAlign: 'center', align:'center',width: 250,
            renderCell: (medicalTests) => {
                if(medicalTests.row.status !== MedicalTestStatus.IN_PROGRESS )return;
                if(medicalTests.row.type !== MedicalTestType.COVID19) return ;
                return ( <> <Button
                        variant="contained"
                        color="primary"
                        startIcon={<EmailIcon />}
                        onClick={() => handleDialogOpen(medicalTests.row, medicalTests.row.emailAddress, MedicalTestStatus.POSITIVE)}
                        sx={{ mr: 1 }}
                        >Positif
                            </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<EmailIcon />}
                            onClick={() => handleDialogOpen(medicalTests.row, medicalTests.row.emailAddress, MedicalTestStatus.NEGATIVE)}
                            sx={{ mr: 1 }}
                        >Négatif
                        </Button>
                    </>
                );
            }},

    ];

    if (!user.accessToken) {
        return <Navigate replace to="/" />;
    } else {
        return (
            <Box mx="auto" p={4} maxWidth="1200px"
                 width={'100%'}>

                <Paper variant="outlined">

                    <Grid container>
                        <Grid item md={4} sm={6} lg={2} xl={1} p={1} >
                            <FormControl >
                                <InputLabel id="status-select-label"> Status du Test</InputLabel>
                                <Select
                                    labelId="status-select-label"
                                    value={selectedStatus}
                                    label="Select Status"
                                    onChange={(event) => {
                                        setSelectedStatus(event.target.value as MedicalTestStatus | "ALL");
                                    }}
                                >
                                    <MenuItem value="ALL">Tous</MenuItem>
                                    <MenuItem value={MedicalTestStatus.IN_PROGRESS}>En Cours</MenuItem>
                                    <MenuItem value={MedicalTestStatus.POSITIVE}>Positif</MenuItem>
                                    <MenuItem value={MedicalTestStatus.NEGATIVE}>Négatif</MenuItem>
                                    <MenuItem value={MedicalTestStatus.CANCELED}>Annulé</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Paper>
                { isLoading ? <CircularProgress /> :
                    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                        <DataGrid
                            localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                            autoHeight
                            rows={medicalTest}
                            columns={columns}
                            pageSize={pageSize}
                            page={pageNumber}
                            rowCount={totalElements}
                            onPageChange={(newPageNumber) => {setPageNumber(newPageNumber)}}
                            onPageSizeChange={(newPageSize) => {setPageSize(newPageSize);setPageNumber(0);}}
                            onCellClick={(params) => {
                                if(params.field !=='action')
                                navigate(`/patient/${params.row.patientRef.id}/${MEDICAL_TEST_URL_IDENTIFIER}/${params.row.id}`);
                            }}

                            sx={{
                                '& .MuiDataGrid-columnHeaders': {
                                    fontWeight: 'bolder',
                                    fontSize: '1.5em',
                                },
                                '& .MuiDataGrid-cell:hover': {
                                    color: 'primary.main',
                                },
                                '& .MuiDataGrid-cell': {
                                    fontSize: '1.2em',
                                    cursor: 'pointer'
                                },
                                '& .MuiDataGrid-cell .MuiChip-root': { // Targeting the Chip inside the cell
                                    cursor: 'pointer',
                                },
                                '& .MuiDataGrid-cell[data-field="consultationGroupSummaries"]:hover': {
                                    cursor: 'default',  // Resetting the cursor for the cell containing the Chip
                                },
                            }}
                            disableColumnMenu
                            rowsPerPageOptions={[25, 50,100]}
                            paginationMode="server"
                        />
                    </Paper>
                }
                {selectedTest && (
                    <SendResultDialog
                        isOpen={isDialogOpen}
                        onClose={() => setDialogOpen(false)}
                        currentTest={selectedTest}
                        defaultEmailAddress={emailAddress}
                        setEmailAddress={setEmailAddress}
                        result={medicalStatusForDialog}
                        onSendingResult={() => {
                            setDialogOpen(false);
                            fetchAllMedicalTests(); // Refresh the list after sending the result
                        }}
                    />
                )}
            </Box>
        );
    }
};

export default connector(MedicalTestSearch);
