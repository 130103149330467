import React, { useEffect, useState,useCallback } from 'react';
import { Dialog,DialogTitle,Grid,Button, DialogContent,Box,List, Link, ListItem, ListItemAvatar, ListItemText, Avatar, SvgIcon, Paper, Stack, Typography, Divider } from '@mui/material';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import PrintIcon from '@mui/icons-material/Print';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import medicationService from '../../../services/medication.service';
import consultationService from '../../../services/consultation.service';

const ChemoMedicatioDoc = ({ chemoTreatments, consultationGroupId }) => {
  const [documentationsByTreatment, setDocumentationsByTreatment] = useState([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const fetchTreatmentsByGroupId = useCallback(async (groupId) => {
    try {
      const response = await consultationService.getChemoTreatmentsByGroupId(groupId);
      return response.data;
    } catch (error) {
      console.error('Failed to fetch treatments for consultation group ID:', groupId, error);
      return [];
    }
  }, []);

  const fetchDocumentation = useCallback(async () => {
    let treatmentsToProcess = chemoTreatments;

    // If consultationGroupId is provided, fetch treatments
    if (consultationGroupId) {
      treatmentsToProcess = await fetchTreatmentsByGroupId(consultationGroupId);
    }

    if (!Array.isArray(treatmentsToProcess)) {
      return; // Exit the function
    }

    const documentationsByTreatmentTemp = [];
    for (let treatment of treatmentsToProcess) {
      try {
        const response = await medicationService.getMedicationDocumentation(treatment.drug.id);
        documentationsByTreatmentTemp.push({ treatmentId: treatment.drug.id, drugName : treatment.drug.name, documentations: response.data.content });
      } catch (error) {
        console.error('Failed to fetch documentation for drug with ID:', treatment.drug.id, error);
      }
    }
    setDocumentationsByTreatment(documentationsByTreatmentTemp);
  }, [chemoTreatments, consultationGroupId, fetchTreatmentsByGroupId]); // include all dependencies


  useEffect(() => {
    fetchDocumentation();
  }, [fetchDocumentation]);


  const displayFilteredDocumentations = (documentations) => {
    return documentations.map((documentationGroup, index) => (
      <Box key={index + documentationGroup.treatmentId}>
        <Divider/>
        <Stack direction="row" alignItems="center" justifyContent={'center'} p={1}>
          <Typography variant='h6' align="center">{documentationGroup.drugName}</Typography>
        </Stack>
        <List>
          {filterAndDisplayDrugDocumentations(documentationGroup.documentations)}
        </List>
      </Box>
    ));
  };

  const displayAllDocumentations = (documentations) => {
    return documentations.map((documentationGroup, index) => (
      <Box key={index + documentationGroup.treatmentId}>
        <Divider/>
        <Stack direction="row" alignItems="center" justifyContent={'center'} p={1}>
          <Typography variant='h6' align="center">{documentationGroup.drugName}</Typography>
        </Stack>
        <List>
          {documentationGroup.documentations.map((documentation, docIndex) => 
            displaySingleDocumentation(documentation)
          )}
      </List>

      </Box>
    ));
  };

  const displaySingleDocumentation = (documentation) => {
    return (<Link key={"docuKey" + documentation.url} href={documentation.url} target="_blank" rel="noreferrer">
            <ListItem key={documentation.url} alignItems="flex-start">
              <ListItemAvatar sx={{ marginTop: 0 }}>
                <Avatar sx={{ backgroundColor: 'primary.main', opacity: 0.8, maxHeight: 24, maxWidth: 24, cursor: 'pointer' }}>
                  <SvgIcon>
                    {documentation.type === 'AMELI' ? <PictureAsPdfIcon />: <PrintIcon/>}
                  </SvgIcon>
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={displayDocumentName(documentation.name,documentation.type)} />
            </ListItem>
          </Link>
          )
  };

  const filterAndDisplayDrugDocumentations = (drugDocumentations) => {
    // Filter the documentations to display based on the type
    //PATIENT and PHARMACY are displayed, OFFICIAL if non of them exists
    let filteredDocumentations = drugDocumentations.filter(
      (doc) => doc.type === 'OMEDIT_PHARMACY' || doc.type === 'OMEDIT_PATIENT'
    );

    // Check if 'OFFICIAL' type should be displayed
    if(filteredDocumentations.length === 0){
      filteredDocumentations = drugDocumentations.filter(
        (doc) => doc.type === 'AMELI_CHEMO_FIRST_CONSULTATION' || doc.type === 'AMELI_CHEMO_SIDE_EFFECT_CONSULTATION'
      );
    } 

    return filteredDocumentations.map((documentation) =>( displaySingleDocumentation(documentation)))};

    const displayDocumentName = (documentName, documentType) => {
      switch (documentType){
        case 'AMELI_CHEMO_FIRST_CONSULTATION':
          return "Fiche Ameli - Entretien d'initiation PDF";
        case 'AMELI_CHEMO_SIDE_EFFECT_CONSULTATION':
          return "Fiche Ameli - Vie quotidienne et gestion des effets indésirables PDF";
        case 'OMEDIT_PHARMACY':
          return "Fiche Omedit - Professionel: "+documentName;        
        case 'OMEDIT_PATIENT':
          return "Fiche Omedit - Patient: "+documentName;
        default:
          return  documentName;
      }
    }

  if (documentationsByTreatment.length ===0 ) {
    return   <Paper variant="outlined" >
                  <Stack direction="row"  alignItems="center" justifyContent={'center'} p={1} >
                  <Typography variant='h6' align="center">Pas de documentation disponible </Typography>
                  </Stack>
            </Paper>;
  }

  return (<Paper variant="outlined" >
        <Stack direction="row"  alignItems="center" justifyContent={'center'} p={1} >
          <Typography variant='h4' align="center">Fichier(s) à consulter</Typography>
        </Stack>
          {displayFilteredDocumentations (documentationsByTreatment)}
          <Stack direction="row"  alignItems="center" justifyContent={'center'} p={1} >
          <Button variant="outlined" style={{textTransform: 'none'}} onClick={()=> setIsDialogOpen(true)}>
            <Typography variant='h6' color='primary' align="center">Afficher toute la documentation</Typography>
          </Button>
        </Stack>
          <Dialog
          open={isDialogOpen}
          onClose={() => setIsDialogOpen(false)}
          fullWidth
          disableRestoreFocus={true}
          maxWidth="lg"
        >
          <DialogTitle>
          <Grid container>
              <Grid item md={10}>
                <Box display="flex" justifyContent="center" ><Typography variant="h4" > 
                    Toute la documentation disponlible
                </Typography></Box>
              </Grid>

              <Grid item md={2}>
                <Button
                  variant="outlined"
                  color="primary"
                  startIcon={<CloseRoundedIcon />}
                  onClick={() => setIsDialogOpen(false)}
                  > Quitter</Button>
              </Grid>
            </Grid>
            </DialogTitle>
            <DialogContent>
               {displayAllDocumentations(documentationsByTreatment)}
            </DialogContent>
        </Dialog>
      </Paper> 
  );


};

export default ChemoMedicatioDoc;
