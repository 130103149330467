import axios from "axios";
import {APIbaseURL} from "./axiosInstance.js"

export const USER_LOCAL_STORAGE_KEY = "entretien.healthbees.user"



function getPostDataForReCaptchaV3 (email,password,reCaptcha) {
    return { email : email, password : password, recaptchaV3Response: reCaptcha }
}

function getPostDataForReCaptchaV2 (email,password,reCaptcha) {
    return { email : email, password : password, recaptchaV2Response: reCaptcha }
}

function handleSuccessLogin (response) {
    if (response.data.accessToken) {
        localStorage.setItem(USER_LOCAL_STORAGE_KEY, JSON.stringify(response.data));
    }
    return response.data;
}

class AuthService {

    async loginWithReCaptchaV3(username, password, reCaptcha) {
        try {
            const response = await axios.post(APIbaseURL + "/auth/login", getPostDataForReCaptchaV3(username, password, reCaptcha));
            return handleSuccessLogin(response);
        } catch (error) {
            console.error('loginWithReCaptchaV3', error);
            throw error;
        }
    }


   async loginWithReCaptchaV2(username, password, reCaptcha) {
       try {
           const response = await axios.post(APIbaseURL + "/auth/login", getPostDataForReCaptchaV2(username, password, reCaptcha));
           return handleSuccessLogin(response);
       } catch (error) {
           console.error('loginWithReCaptchaV3', error);
           throw error;
       }
    }

  logout() {
    localStorage.removeItem(USER_LOCAL_STORAGE_KEY);
  }

}

export default AuthService = new AuthService() ; 
