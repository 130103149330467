import React from 'react';
import { TextField } from '@mui/material';

function EditableField({
  label,
  name,
  isEditable = true,
  getErrorMsgValidatingFieldFct = null,
  isRequired = false,
  InputProps = {},
  maxLength = null,
  value,
  type = 'text',
  onChange=null,
}) {
  const errorMsg = getErrorMsgValidatingFieldFct ? getErrorMsgValidatingFieldFct(value) : '';
  const valueOrDefault = isEditable ? (value || '') : (value || 'Non renseigné');
  const showError = isEditable && errorMsg.length>0;


  const combinedInputProps = {
    ...InputProps, // Spread any existing InputProps first
    readOnly: !isEditable,
    style: {
      ...InputProps.style, // Ensure you don't override styles passed through InputProps
      ...(isEditable ? { backgroundColor: '#e8f0fe' } : { color: 'black !important'}),
    },
  };

  return (
    <TextField
      label={label}
      value={valueOrDefault}
      name={name}
      type={type}
      error={showError}
      helperText={showError && errorMsg}
      InputLabelProps={{shrink: type === "date"? true: undefined }}
      InputProps={{ ...combinedInputProps}}
      inputProps={{maxLength : (maxLength ?maxLength : null),}}
      required={isRequired}
      fullWidth
      onChange={onChange}
      margin="normal"
      variant="outlined"
    />
  );
}

export default EditableField;
