import React from 'react';
import { Navigate  } from 'react-router-dom';
import { connect } from 'react-redux';
import UtilService from '../../services/util.service.js';

import {
    Grid,
    Paper,
    Typography,
    Alert,
    AlertTitle,
    Box,
    Button,

  } from "@mui/material";

function BillingDetail(props) {


    const handleSave = () => {
        props.handleClose();
       props.handleBillAction(props.consultationGroup.id);
        
    };

    if (!props.user.accessToken) {
          return <Navigate replace to="/" />;
    } 
    else {
      return (
    <>
      <Paper variant="outline"  > 
       <Box p={1}> 
       <Grid container> 
            <Box p={1}/> 
            <Grid item md={12} xs={12}>
            <Alert severity="success">  
                <AlertTitle> <Typography  variant="body1">Facturation à faire à part  </Typography></AlertTitle>
                <Typography   variant="body1"> Pour rappel : pour être valide, cet acte doit être <strong>facturé seul</strong>, c’est-à-dire indépendamment de toute autre facturation
            (médicaments, ordonnance, LPP…).
              </Typography>
            </Alert>
            <Box p={1}/>
            <Alert severity="info">  
                <AlertTitle> <Typography  variant="body1">Prise en charge par l'assurance maladie : </Typography></AlertTitle>
                {props.consultationGroup.billingCode === 'EFE' && (
                  <Typography variant="body1">
                    Le taux de prise en charge de ce code par l’assurance maladie est de{' '}
                    <strong>100% si l’assurée est couverte par l’assurance maternité</strong>(à partir de 6 mois de grossesse)
                    <strong> sinon il est pris en charge à 70%</strong>.
                  </Typography>
                )}
                {(props.consultationGroup.billingCode === 'AC1' || props.consultationGroup.billingCode === 'AC2') && (
                  <Typography variant="body1">
                    Le taux de prise en charge de ce code par l’assurance maladie est de <strong>100%</strong>.
                  </Typography>
                )}
            </Alert>
            </Grid>
            <Box p={1}/>
            <Grid item md={12} xs={12}><Typography align={'center'} p={1} paddingBottom={3} variant="h5"> Information de facturation</Typography></Grid>
            <Grid item md={6} xs={6}>
            <Typography variant="body1" align={'right'} pr={1} >
              Numéro de SS du patient : 
              </Typography>
            </Grid>
            <Grid item md={6} xs={6}>
            <Typography variant="body1" align={'left'} fontWeight={'bold'}>
              {props.patient.socialSecurityNumber &&(UtilService.formatFrenchSSN(props.patient.socialSecurityNumber)) }
            </Typography>
            </Grid>
            <Grid item md={6} xs={6}>
            <Typography variant="body1" align={'right'} pr={1} >
                Code de facturation : 
              </Typography>
            </Grid>
            <Grid item md={6} xs={6}>
            <Typography variant="body1" align={'left'} fontWeight={'bold'}>
                {props.consultationGroup.billingCode}
            </Typography>
            </Grid>
            <Grid item md={6} xs={6}>
            <Typography variant="body1" align={'right'} pr={1} >
                Date d'execution : 
              </Typography>
            </Grid>
            <Grid item md={6} xs={6}>
            <Typography variant="body1" align={'left'} fontWeight={'bold'}>
                {UtilService.handleDateFormat(props.consultationGroup.billingDate)} ( {UtilService.formatDate_ddMMYYYY(props.consultationGroup.billingDate)} )
            </Typography>
            </Grid>
            <Grid item md={6} xs={6}>
            <Typography variant="body1" align={'right'} pr={1} >
                Zone prescripteur : 
              </Typography>
            </Grid>
            <Grid item md={6} xs={6}>
            <Typography variant="body1" align={'left'}>
            Votre numéro d’identification
            </Typography>
            </Grid>
            <Grid item md={6} xs={6}>
            <Typography variant="body1" align={'right'} pr={1} >
              Zone exécutant : 
              </Typography>
            </Grid>
            <Grid item md={6} xs={6}>
              <Typography variant="body1" align={'left'} >
              Votre numéro d’identification
              </Typography>
            </Grid>
            <Grid item md={6} xs={6}>
            <Typography variant="body1" align={'right'} pr={1} >
              Honoraires : 
              </Typography>
            </Grid>
            <Grid item md={6} xs={6}>
            <Typography variant="body1" align={'left'} fontWeight={'bold'}>
              {props.consultationGroup.price &&(parseFloat(props.consultationGroup.price)).toFixed(2) } euros net
            </Typography>
            </Grid>
        </Grid>
        </Box>
       </Paper>
              <Paper variant="outline" >  <Box p={1}> <Grid container>
              <Grid item md={12}>
                <Button
                
                sx={{ justifyContent: 'center'}}
                variant="contained"
                color="primary"
                onClick={handleSave}
                >
                L'entretien a été facturé sur le LGO
                </Button>
              </Grid>
              </Grid></Box></Paper>
    </>
       );}

}
function mapStateToProps(state) {
    const user  = state.authReducer.user;
    return {
      user
    };
}

export default connect(mapStateToProps)(BillingDetail);