import React, {useEffect, useState} from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Button,
    Grid,
    Box,
    Typography,
    TextField,
    FormHelperText,
    Stack,
    CircularProgress,
    FormGroup, FormControlLabel, Checkbox, Alert
} from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import MedicalTestResultChip from '../../../components/field/MedicalTestResulChip.tsx';
import  { UtilService } from '../../../services/util.service.js';
import MedicalTestService from '../../../services/MedicalTestService.ts';
import { useDispatch } from 'react-redux';
import { getActionDisplayMessage } from '../../../actions/ReduxAction.ts';
import {MedicalTestStatus} from "../../../types/types";

interface SendResultCystitisProps {
    isOpen: boolean;
    onClose: () => void;
    currentTest: any;  // Adjust the type as necessary
    defaultEmailAddress: string;
    attachmentWithMail :boolean
    result?: MedicalTestStatus;
    onSendingResult?: () => void;  // Optional prop for additional logic after sending the result
    handleCystitisTestFieldChange?:(field:string , value : string) => void;
    pharmacyEmail :string ;
    emailContent : string;
}

const SendResultCystitis: React.FC<SendResultCystitisProps> = ({
                                                               isOpen,
                                                               onClose,
                                                               currentTest,
                                                               defaultEmailAddress,
                                                               result,
                                                               onSendingResult,
                                                               handleCystitisTestFieldChange,
                                                               pharmacyEmail,
                                                               attachmentWithMail,
                                                               emailContent
                                                           }) => {
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const [emailAddress, setEmailAddress] = useState(defaultEmailAddress);
    const [emailContentValidated, setEmailContentValidated] = useState(emailContent);

    const resultToSend= result ? result: currentTest.status;

    const [pharmacyEmailInCC, setPharmacyEmailInCC] = useState( false);

    useEffect(() => {
        handleCystitisTestFieldChange('hasPharmacyEmailInCC',pharmacyEmailInCC );
        handleCystitisTestFieldChange('emailAddress',emailAddress);
        handleCystitisTestFieldChange('emailContent',emailContentValidated );
       // eslint-disable-next-line
        }, [emailContentValidated,emailAddress,pharmacyEmailInCC]);

    const handleSendResult = async () => {
        setIsLoading(true);
        try {
            // trying to save medical Test
            await MedicalTestService.saveCystitisTest(currentTest);
            dispatch(getActionDisplayMessage('Test Enregistré avec succès', 'success'));

            try {

                // // trying to send medical result
                await MedicalTestService.validateCystitisTest(currentTest.id, resultToSend);
                dispatch(getActionDisplayMessage('Résultat envoyé avec succès', 'success'));

                if (onSendingResult) {
                    onSendingResult();
                }
                onClose();
            } catch (validateError) {
                console.error(validateError);
                dispatch(getActionDisplayMessage("Une erreur s'est produite lors de l'envoi du résultat", 'error'));
            }
        } catch (saveError) {
            console.error(saveError);
            dispatch(getActionDisplayMessage("Une erreur s'est produite lors de l'enregistrement du test", 'error'));
        } finally {
            setIsLoading(false);
        }
    };

    const handleDialogClose = () => {
        onClose();
    };

    return (
        <Dialog
            open={isOpen}
            onClose={handleDialogClose}
            fullWidth
            disableRestoreFocus={true}
            maxWidth="lg"
        >
            <DialogTitle>
                <Grid container>
                    <Grid item md={2}>
                        <Button
                            variant="outlined"
                            color="primary"
                            startIcon={<CloseRoundedIcon />}
                            onClick={handleDialogClose}
                        >
                            Quitter
                        </Button>
                    </Grid>
                    <Grid item md={6}>
                        <Box display="flex" justifyContent="center">
                            <Typography variant="h4">Envoie du compte rendu à la patiente</Typography>
                        </Box>
                    </Grid>
                    <Grid item md={4}>
                        <Button
                            variant="contained"
                            color="primary"
                            disabled={!UtilService.isValidEmail(emailAddress) || isLoading}
                            startIcon={<EmailIcon />}
                            onClick={handleSendResult}
                        >
                            {isLoading ? <CircularProgress size={24} /> : 'Envoyer et Finaliser le Test'}
                        </Button>
                    </Grid>

                </Grid>
            </DialogTitle>
            <DialogContent>
                <Grid container>
                    <Grid item md={10}>
                        <Box display="flex" justifyContent="center">
                            <Typography variant="h6">
                                Vous êtes sur le point d'envoyer le compte rendu pour un résultat <MedicalTestResultChip result={resultToSend} /> à l'adresse suivante :
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item md={12} xs={12}>
                        <TextField
                            label="Adresse Email"
                            required
                            value={emailAddress}
                            error={!UtilService.isValidEmail(emailAddress)}
                            color="success"
                            id="emailAddress"
                            name="emailAddress"
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            onChange={(event) => setEmailAddress(event.target.value)}
                            inputProps={{ maxLength: 250 }}
                        />
                        {!UtilService.isValidEmail(emailAddress) && (
                            <FormHelperText error>Adresse email incorrecte</FormHelperText>
                        )}
                    </Grid>
                    <Grid item md={12} xs={12}>
                        <FormGroup>
                            <FormControlLabel
                                control={<Checkbox
                                    checked={pharmacyEmailInCC}
                                    onChange={event => setPharmacyEmailInCC(event.target.checked)}/>}
                                label={"Envoyer une copie du mail à l'adresse email de la pharmacie ("+pharmacyEmail +")"}
                            />
                        </FormGroup>
                    </Grid>
                    <Grid item md={12} xs={12}>
                        <TextField
                            id="emailContent"
                            multiline
                            fullWidth
                            value={emailContentValidated}
                            onChange={event => setEmailContentValidated(event.target.value)}
                            minRows={10}
                            maxRows={30}
                            placeholder="Le mail envoyé à la patiente"
                            variant="outlined"
                            inputProps={{ maxLength: 2000}}
                        />
                        <FormHelperText id="comment">2000 caractères max</FormHelperText>
                    </Grid>
                    {( attachmentWithMail &&
                        <Alert severity="info" style={{width:'100%'}} >
                            <Typography px={2} variant="h5" >
                                la patiente recevra en pièce jointe, un compte rendu à transmettre à son medecin traitant
                            </Typography>
                        </Alert>
                    )}

                    <Grid item md={12} xs={12}>
                        <Stack direction="row" mt={2} justifyContent={"center"}>
                            <Button
                                sx={{ p: 2 }}
                                variant="contained"
                                color="primary"
                                disabled={!UtilService.isValidEmail(emailAddress) || isLoading}
                                startIcon={<EmailIcon />}
                                onClick={handleSendResult}
                            >
                                {isLoading ? <CircularProgress size={24} /> : 'Envoyer et Finaliser le Test'}
                            </Button>
                        </Stack>
                    </Grid>

                </Grid>
            </DialogContent>
        </Dialog>
    );
};

export default SendResultCystitis;
