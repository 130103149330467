//import axios from "axios";
import axios from "./axiosInstance.js";
import {ConsultationType} from "../types/types.ts";

export const CHEMO_URL_IDENTIFIER = 'chemo'
export const PREGNANCY_URL_IDENTIFIER = 'pregnancy'

class ConsultationService {

   getConsultationNameFromConsultationType (consultationType){
    switch (consultationType) {
      case ConsultationType.CHEMO_FIRST:
        return 'Entretien d\'initiation';
      case ConsultationType.CHEMO_DAILY_LIFE_AND_SIDE_EFFECTS:
        return 'Vie quotidienne et gestion des effets indésirables';
      case ConsultationType.CHEMO_OBSERVANCE:
        return 'Suivi d\'observance';
      case ConsultationType.PREGNANCY:
        return 'Entretien femme enceinte';
      default:
        return null;
    }
  }

  //CONSULTATION GROUP

  billConsultationGroupById(consultationId){
    return axios.post(`/consultation_group/${consultationId}/bill_group`);
  }
  getChemoTreatmentsByGroupId(consultationGroupId) {
    return axios.get(`/chemo_consultation_group/${consultationGroupId}/chemo_treatment`);
  }
  //PREGNANCY
  getAllPregnancyConsultationGroupsFromPatientId(patientId) {
    return axios.get('/consultation_group', { params : { patientId: patientId, type: 'PREGNANCY' }});
  }
  getPregnancyConsultationById(consultationId) {
    return axios.get(`/pregnancy_consultation/${consultationId}`);
  }
  savePregnancyConsultation(pregnancyConsultation) {
    return axios.post(`/pregnancy_consultation/${pregnancyConsultation.id}`,pregnancyConsultation);

  }
  validatePregnancyConsultation(pregnancyConsultation) {
    return axios.post(`/pregnancy_consultation/${pregnancyConsultation.id}/validate`,pregnancyConsultation);
  }
  
  startPregnancyConsultationForPatient(patientId) {
    return axios.post(`/patient/${patientId}/start_pregnancy_consultation_group`);
  }
  deletePregnancyConsultationById(pregnancyConsultationId) {
    return axios.delete(`/pregnancy_consultation/${pregnancyConsultationId}`);
  }

  //CHEMO

  getChemoTreatments(searchStr, page =0,pageSize=70 ) {
    return axios.get(`/chemo_consultation/treatments/${searchStr}?page=${page}&size=${pageSize}`);
  }
  getAllChemoConsultationGroupsFromPatientId(patientId) {
    return axios.get('/consultation_group', { params : { patientId: patientId, type: 'ORAL_CANCER_TREATMENT' }});
  }
  getChemoConsultationById(consultationId) {
    return axios.get(`/chemo_consultation/${consultationId}`);
  }
  saveChemoFirstConsultation(chemoConsultation) {
    return axios.post(`/chemo_consultation/first/${chemoConsultation.id}`,chemoConsultation);
  }
  validateChemoFirstConsultation(chemoConsultation) {
    return axios.post(`/chemo_consultation/first/${chemoConsultation.id}/validate`,chemoConsultation);
  }
  saveChemoDailyLifeSideEffectsConsultation(chemoConsultation) {
    return axios.post(`/chemo_consultation/daily_life_side_effect/${chemoConsultation.id}`,chemoConsultation);
  }
  validateChemoDailyLifeSideEffectsConsultation(chemoConsultation) {
    return axios.post(`/chemo_consultation/daily_life_side_effect/${chemoConsultation.id}/validate`,chemoConsultation);
  }
  saveChemoObservanceConsultation(chemoConsultation) {
    return axios.post(`/chemo_consultation/observance/${chemoConsultation.id}`,chemoConsultation);
  }
  validateChemoObservanceConsultation(chemoConsultation) {
    return axios.post(`/chemo_consultation/observance/${chemoConsultation.id}/validate`,chemoConsultation);
  }
  startChemoConsultationForPatient(patientId) {
    return axios.post(`/patient/${patientId}/start_chemo_consultation_group`);
  }
  addNewChemoFisrtConsultationToGroup(consultationGroupId) {
    return axios.post(`/chemo_consultation_group/${consultationGroupId}/start_first`);
  }
  addNewDailyLifeAndSideEffectsConsultationToGroup(consultationGroupId) {
    return axios.post(`/chemo_consultation_group/${consultationGroupId}/start_daily_life_side_effect`);
  }
  addNewObservanceConsultationToGroup(consultationGroupId) {
    return axios.post(`/chemo_consultation_group/${consultationGroupId}/start_observance`);
  }
  billTACForChemoConsultationGroupById(consultationGroupId){
    return axios.post(`/chemo_consultation_group/${consultationGroupId}/bill_tac`);
  }
  deleteChemoConsultationById(chemoConsultationId) {
    return axios.delete(`/chemo_consultation/${chemoConsultationId}`);
  }

}

export default ConsultationService = new ConsultationService() ; 
