import React, { useState, useEffect } from 'react';
import { Navigate,useNavigate  } from 'react-router-dom';
import { connect } from 'react-redux';
import {actionDisplayMessage} from "../../../actions/auth.action"

import {
    Grid,
    Paper,
    Typography,
    TextField,
    Box,
    Button,
    CircularProgress,
    FormHelperText,
    Dialog,
    DialogTitle,
    DialogContent,
    List,
    ListItem,
    ListItemText,
    ListItemAvatar,
    Avatar,
    Divider,
    Stack,
    Link,
    Accordion,
    AccordionDetails,
    AccordionSummary,
  } from "@mui/material";
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import UtilService from '../../../services/util.service.js';
import EmailIcon from '@mui/icons-material/Email';
import AssignmentIcon from '@mui/icons-material/Assignment';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'


import HorizontalSelect from '../../../components/field/horizontalSelect.component.js';
import ConsultationService from '../../../services/consultation.service.js'
import EmailConfirmation from '../../../components/email/emailConfirmation.component.js';

function PregnancyConsultationDetail(props) {
  const patientId = props.patient ? props.patient.id: null;
  const navigate = useNavigate();

  const [pregnancyConsultation, setPregnancyConsultation] = useState(null);
  const [patient, setPatient] = useState(null);
  const [pharmacy, setPharmacy] = useState(null);
  const [isFormValid/*, setIsFormValid*/] = useState(true);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const [pharmacyHasProvidedDocumentation, setPharmacyHasProvidedDocumentation] = useState(null);
  const [autoMedicationCheck, setAutoMedicationCheck] = useState(null);
  const [rulesCheck, setRulesCheck] = useState(null);
  const [patientComment, setPatientComment] = useState(undefined);
  const [emailAddress, setEmailAddress] = useState('');
  const [emailContent, setEmailContent] = useState('');
  const [emailDate,setEmailDate] = useState(null);
  const [status, setStatus]= useState('');


  const [formData, setFormData] = useState(null);

  const [isLoading, setIsLoading] = useState(true);
  const [docExpanded, setDocExpanded] = useState(false);


  const initiazeFormData = (formData) => { 
    setIsLoading(false);
    setFormData(formData);
    setPatient(formData.patientRef);
    setPharmacy(formData.pharmacyRef);
    setPatientComment(formData?.patientComment ?? patientComment);
    setAutoMedicationCheck(formData?.autoMedicationCheck ?? 
      autoMedicationCheck)
    setPharmacyHasProvidedDocumentation(formData?.pharmacyHasProvidedDocumentation ?? 
      pharmacyHasProvidedDocumentation);
    setEmailAddress(formData?.emailAddress ?? emailAddress);
    setEmailContent(formData?.emailContent ?? emailContent);
    setEmailDate(formData?.emailDate ?? emailDate);
    setRulesCheck(formData?.rulesCheck ?? rulesCheck);

    setStatus(formData?.status ?? status);
  }

  const closeDialog = () => {
    setIsDialogOpen(false);
  };

  const setValidEmailData = (email) => {
    const {emailContent, emailAddress,pharmacyEmailInCC } = email;
    setFormData(prevFormData => ({
      ...prevFormData,
      emailContent: emailContent,
      emailAddress: emailAddress,
      hasPharmacyEmailInCC: pharmacyEmailInCC
    }));
  }

  const pdfIconUrl               = process.env.PUBLIC_URL + "/icons/pdf_icon.png";

  const afficheUrl  = "https://fichiers-entretien-public.s3.eu-west-3.amazonaws.com/entretien/EFE/affiche-ansm.pdf";
  const brochureUrl = "https://fichiers-entretien-public.s3.eu-west-3.amazonaws.com/entretien/EFE/brochure-grossesse.pdf";
  const depliantUrl = "https://fichiers-entretien-public.s3.eu-west-3.amazonaws.com/entretien/EFE/depliant-grossesse.pdf";


  const handleSave= () => {
    setIsLoading(true);
    saveConsultation()
  }
  const saveConsultation= () => {
    ConsultationService.savePregnancyConsultation(formData)
    .then(() => {
        setIsLoading(false);
        props.dispatch(actionDisplayMessage("Entretien Enregistré avec succès","success"));
        navigate(`/patient/${patientId}/pregnancy/`); 
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
        props.dispatch(
          actionDisplayMessage(
            "Une erreur s'est produite lors de l'enregistrement des modifications",
            "error"));
      });
  };

  const validateConsultation= () => {
    ConsultationService.validatePregnancyConsultation(formData)
    .then(() => {
        setIsLoading(false);
        props.dispatch(actionDisplayMessage("Entretien Validé et email Envoyé","success"));
        navigate(`/patient/${patientId}/pregnancy/`); 
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
        props.dispatch(
          actionDisplayMessage(
            "Une erreur s'est produite lors de l'enregistrement des modifications",
            "error"));
      });

  }
  const handleSendEmail = () => {
    setIsLoading(true);
    validateConsultation()
  }

  const handleSaveAsDraft= () => {
    ConsultationService.savePregnancyConsultation(formData)
    .then(() => {
        setIsLoading(false);
        props.dispatch(actionDisplayMessage("Entretien Enregistré pour plus tard","success"));
        navigate(`/patient/${patientId}/pregnancy/`); 
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
        props.dispatch(
          actionDisplayMessage(
            "Une erreur s'est produite lors de l'enregistrement des modifications",
            "error"));
      });
  };

  const handleOpenEmailSendDialog = () => {
    setIsDialogOpen(true);
  };

  const handleDiscardClick = () => {
    setFormData(pregnancyConsultation);
    initiazeFormData(pregnancyConsultation)
  };

  useEffect(() => {
    setIsLoading(false);
    if(props.debug)console.log(props.consultation)
    setPregnancyConsultation(props.consultation);
    initiazeFormData(props.consultation);            
    // eslint-disable-next-line
  },[props.consultation,props.debug]);

  useEffect(() => {
    const handleFormDatachage= () => {
      setFormData(prevFormData => ({
        ...prevFormData,
        patientComment: patientComment,
        pharmacyHasProvidedDocumentation: pharmacyHasProvidedDocumentation,
        autoMedicationCheck: autoMedicationCheck,
        rulesCheck: rulesCheck
      }));
    }
    handleFormDatachage();
  },[patientComment,pharmacyHasProvidedDocumentation,autoMedicationCheck,rulesCheck]);

  useEffect(() => {
    if(props.debug) console.log(formData);
  },[formData,props.debug]);


  const avatarInDocumentationStyles ={
      width:'inherit',
      height:'inherit', 
      maxWidth:'40px',
      maxHeight:'45px',
      borderRadius:'0'}

  if (!props.user.accessToken) {
      return <Navigate replace to="/" />;
    } 
  if( isLoading) {
      return <CircularProgress />;
    }
  if(!patient || !formData){
   return  <CircularProgress />;
  }
  return (
    <Grid container justifyContent="center"  p={2} >
      <Grid item xs={12} sm={12} md={12} lg={11} xl={10} >
      <Paper variant="outlined">
        <Stack direction="row" mt={1} justifyContent={"right"}>
          <Button sx={{maxWidth:'300px'}} variant="outlined" startIcon={<CancelIcon />} onClick={handleDiscardClick}>
                Annuler les modifications
          </Button>
        </Stack>
        <Stack direction="row" mb={3} justifyContent={"center"}>
          <Typography variant="h3" >
                Entretien de la femme enceinte
          </Typography>
        </Stack>
      </Paper>
      <Paper variant="outlined">
      <Accordion onChange={(event, isExpanded) => {setDocExpanded(isExpanded);}}>
      <AccordionSummary
          expandIcon={<ArrowDownwardIcon />}
          aria-controls="documentationContent"
          id="documentationContent"
          sx={{
            '&.Mui-expanded > .MuiAccordionSummary-content': {
              marginBottom: '0px',
          },
            '& .MuiAccordionSummary-content': {
                justifyContent: 'center',
            }}}
        >
      <Stack direction="column" justifyContent={'center'} alignItems="center" gap={1} >
        <Stack direction="row" justifyContent={'center'} alignItems="center" gap={1} >
          <AssignmentIcon />
          <Typography variant='h4' >Documentation</Typography>
        </Stack>
        <Typography variant='subtitle1' >{docExpanded ? 'Cliquer pour fermer' : 'Cliquer pour ouvrir'}</Typography>
        </Stack>
        </AccordionSummary>
        <AccordionDetails>
              <List>
              <ListItem alignItems="flex-start" >
                <ListItemAvatar>
                <Avatar src={pdfIconUrl} sx={avatarInDocumentationStyles} />
                </ListItemAvatar>
                <Link target="_blank" rel="noopener" href={afficheUrl} underline="none">
                <ListItemText
                  primary="L’affiche"
                  secondary=         
                      {"elle rappelle le message clé de la campagne et l’adresse où s’informer"}
                />            
                </Link>
              </ListItem>
              <Divider variant="inset" component="li" />
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar src={pdfIconUrl} sx={avatarInDocumentationStyles} />
                </ListItemAvatar>
                <Link target="_blank" rel="noopener" href={brochureUrl} underline="none">
                <ListItemText
                  primary="Une brochure d’information"
                  secondary=
                      {"pour le grand public rappelant ces 4 « règles d’or » et expliquant leur importance"}
                />
                 </Link>
              </ListItem>
              <Divider variant="inset" component="li" />
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar src={pdfIconUrl} sx={avatarInDocumentationStyles} />
                </ListItemAvatar>
                <Link target="_blank" rel="noopener" href={depliantUrl} underline="none">
                <ListItemText
                  primary="Un dépliant"
                  secondary=
                      {'Rappelant les 4 règles d\'or à glisser dans sa poche'}
                />
                 </Link>
              </ListItem>
            </List>
        </AccordionDetails>
      </Accordion>
      </Paper>

      <Paper variant="outlined">
      <Box p={2} display="block">
        <Typography variant="h5" align="center">
          Informations personnelles
        </Typography>
        <Grid container spacing={2} mt={2}>
          <Grid item md={4}>
            <Typography variant="text" color="grey">{ patient.firstName} {patient && patient.lastName} </Typography>
          </Grid>
          <Grid item md={4}>
            <Typography variant="text" color="grey">Né le { UtilService.handleDateFormat(patient.birthday)} </Typography>
          </Grid>
          <Grid item md={4}>
          <Typography variant="text" color="grey">Numéro de SS:  {patient.socialSecurityNumber} </Typography>
          </Grid>
        </Grid>  
      </Box>
    </Paper>
<Box p={'2px'}/>
<Paper  variant="outlined">
  <Box  p={2} display="block">
      <Typography pb={3} variant="h5" align="center">
           Rappel à faire à la patiente
      </Typography>
      <Grid container spacing={1}>
      <Grid item sm={5} md={5} lg={5}>
        <Box sx={{ height: '100%', display: 'flex', flexDirection:'column' , justifyContent:'center'}}> 
          <Typography variant="text">
          Vous rappelez les 4 règles d'or officielles de la sécurité sociale :
          </Typography>

          <List>

              <ListItem alignItems="flex-start" >
                <ListItemText
                  primary="1. Préparer sa grossesse avec son médecin ou sa sage-femme"   
                />            
              </ListItem>
              <Divider variant="inset" component="li" />
              <ListItem alignItems="flex-start">
                
                <ListItemText
                  primary="2. Ne pas faire d'automédication"/>
              </ListItem>
              <Divider variant="inset" component="li" />
              <ListItem alignItems="flex-start">
                <ListItemText
                  primary="3. Ne pas arrêter seule son traitement"
                />
              </ListItem>
              <Divider variant="inset" component="li" />
              <ListItem alignItems="flex-start">
                <ListItemText
                  primary="4. Informer tous les professionnels de santé consultés"
                />
              </ListItem>
            </List>
            
        </Box>
          </Grid>
          <Grid pl={2} item sm={7} md={7} > 
                  <HorizontalSelect 
                    choices={['Fait','Non fait']}
                    changeValue={setRulesCheck}
                    defaultValueIndex={0}
                    value={rulesCheck}/>
          </Grid>
          <Grid item pb={2} sm={5} md={5} lg={5}>
              <Box sx={{ height: '100%', display: 'flex', flexDirection:'column' , justifyContent:'center'}}> <Typography variant="text">
              Vous vérifiez que la patiente ne prend pas de traitements en automédication susceptibles d’être dangereux pour le fœtus.
              </Typography></Box>
          </Grid>
          <Grid pl={2} item sm={7} md={7} > 
                  <HorizontalSelect 
                    choices={['Acquis', 'Partiellement acquis', 'Non acquis']}
                    values ={['ACQUIS', 'PARTIELEMENT', 'NON ACQUIS']} 
                    changeValue={setAutoMedicationCheck}
                    value={autoMedicationCheck}/>
          </Grid>
          <Grid item pb={2} sm={5} md={5} lg={5}>
              <Box sx={{ height: '100%', display: 'flex', flexDirection:'column' , justifyContent:'center'}}> <Typography variant="text">
                Vous informez la patiente qu'elle va recevoir un email avec des liens utiles à sa grossesse et que vous êtes disponible à la moindre question.           
              </Typography></Box>
          </Grid>
          <Grid pl={2} item sm={7} md={7} > 
                  <HorizontalSelect
                    choices={['Fait', 'Non fait']} 
                    changeValue={setPharmacyHasProvidedDocumentation}
                    value={pharmacyHasProvidedDocumentation}/>
          </Grid>
      </Grid>    
    </Box>
</Paper>
<Box p={'2px'}/>

<Paper  variant="outlined">
  <Box  p={2} display="block">
      <Typography pb={3} variant="h5" align="center">
          Informations complementaires demandés par la patiente
      </Typography>
      <Grid container spacing={1}>
      <Grid item md={12}>
            <TextField
                id="patientComment"
                multiline
                fullWidth
                value={patientComment}
                onChange={(event) => setPatientComment(event.target.value)}
                minRows={2}
                maxRows={10}
                placeholder="Informations complémentaires demandées par le patient"
                variant="outlined"
                inputProps={{ maxLength: 250 }}
                />
                <FormHelperText id="comment">250 caractères max</FormHelperText>
            </Grid>
      </Grid>
  </Box>
</Paper>

{(status === 'VALIDATED' || status === 'BILLED') && (
  <Paper  variant="outlined">
    <Box  p={2} display="block">
        <Typography pb={3} variant="h5" align="center">
          Email envoyé à la patiente 
          {(emailDate && UtilService.handleDateFormat(emailDate)!=='Non renseigné' ) ? 
          ` le  ${UtilService.handleDateFormat(emailDate)}` : ''}
          {(emailDate && UtilService.get24HourTime(emailDate) ) ? 
          ` à  ${UtilService.get24HourTime(emailDate)}` : ''}

        </Typography>
        <Grid container spacing={1}>
        <Grid item md={12}>
          <TextField
            label="Adresse Email"
            required
            value={emailAddress}
            id="emailAddress"
            name="emailAddress" 
            fullWidth
            margin="normal"
            variant="outlined"
            disabled
          />            
          </Grid>
          <Grid item md={12} xs={12}>
              <TextField
                  id="emailContent"
                  multiline
                  fullWidth
                  value={emailContent}
                  disabled
                  variant="outlined"
                  />
            </Grid>
        </Grid>
    </Box>
  </Paper>
)}

<Box p={'2px'}/>
    <Box p={'2px'}/>
    {status === 'DRAFT'?(
      <>
        <Button
          variant="contained"
          color="primary"
          startIcon={<EmailIcon />}
          onClick={handleOpenEmailSendDialog}
          sx={{ mr: 1 }}
          disabled={!isFormValid}
        >
          Suivant 
       </Button>
          <Button
          variant="outlined"
          color="primary"
          startIcon={<SaveIcon />}
          onClick={handleSaveAsDraft}
          sx={{ mr: 1 }}
          disabled={!isFormValid}
        >
          Enregistrer pour plus tard
        </Button>
      </>
    ):(
      <Button
        variant="contained"
        color="primary"
        startIcon={<SaveIcon />}
        onClick={handleSave}
        sx={{ mr: 1 }}
        disabled={!isFormValid}
        >
        Enregistrer
      </Button>
    )}
      

      </Grid>
      
      <Dialog
        open={isDialogOpen}
        onClose={closeDialog}
        fullWidth
        disableRestoreFocus={true}
        maxWidth="lg"
      >
        <DialogTitle>
        <Grid container>
            <Grid item md={10}>
              <Box display="flex" justifyContent="center" ><Typography variant="h4" > Email à envoyer </Typography></Box>
            </Grid>

            <Grid item md={2}>
              <Button
                variant="outlined"
                color="primary"
                startIcon={<CloseRoundedIcon />}
                onClick={closeDialog}
                > Quitter</Button>
            </Grid>
          </Grid>
          </DialogTitle>
          <DialogContent>
            <EmailConfirmation
              emailAddress={emailAddress}
              emailContent={emailContent}
              hasPharmacyEmailInCC ={pregnancyConsultation?.hasPharmacyEmailInCC}
              pharmacyEmail= {pharmacy?.pharmacyContactInfo?.email}
              setValidEmailData={setValidEmailData}
              handleSendEmail={handleSendEmail}
              handleSaveAsDraft={handleSaveAsDraft}

              closeDialog={closeDialog} 
            />
          </DialogContent>
      </Dialog>
    </Grid>
  );  
}

function mapStateToProps(state) {
    const user  = state.authReducer.user;
    return {
      user
    };
}
export default connect(mapStateToProps)(PregnancyConsultationDetail);
