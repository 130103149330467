import {
  Avatar,
  Card,
  CardContent,
  Stack,
  SvgIcon,
  Typography
} from '@mui/material';
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined';
import FeaturedPlayListOutlinedIcon from '@mui/icons-material/FeaturedPlayListOutlined';
export const OverviewTotalConsultation = (props) => {
  const { value, label, sx, isWarningInfo = false} = props;

  return (
    <Card sx={sx}>
      <CardContent>
        <Stack
          alignItems="flex-start"
          direction="row"
          justifyContent="space-between"
          spacing={3}
        >
          <Stack spacing={1}>
            <Typography
              color="text.secondary"
              variant="h5"
            >
              {label}
            </Typography>
            <Typography variant="h2">
              {value}
            </Typography>
          </Stack>
          <Avatar
            sx={{
              backgroundColor: isWarningInfo ? 'warning.main':'success.main',
              height: 46,
              width: 46
            }}
          >
            <SvgIcon>
              {isWarningInfo ?<FeaturedPlayListOutlinedIcon/> :<FactCheckOutlinedIcon />}
            </SvgIcon>
          </Avatar>
        </Stack>
      </CardContent>
    </Card>
  );
};
