// CovidTestSelector.tsx
import React, { useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { medicalCovidTestList} from './CovidTestList.ts';
import {CovidTestingKit} from "../../types/types";
import { FormHelperText} from "@mui/material";

interface CovidTestSelectorProps {
    onSelectKit: (test: CovidTestingKit) => void;
    initialCovidTest : CovidTestingKit
}

const CovidTestSelector: React.FC<CovidTestSelectorProps> = ({ onSelectKit,initialCovidTest }) => {

    const [medicalCovidTest, setMedicalCovidTest] = useState(initialCovidTest ? initialCovidTest :medicalCovidTestList[medicalCovidTestList.length - 1]); // Default to the last item
    const handleBlur = () => {
        if (medicalCovidTest && medicalCovidTest.testName) { // Check if there is a value selected or entered
            onSelectKit(medicalCovidTest);// Call the onSelectTest with the current value
        }
    };
    return (
        <>
            <Autocomplete
            value={medicalCovidTest}
            onInputChange={(event, newValue) => {
                if (typeof newValue === 'string') {
                    const newMedicalCovidTest: CovidTestingKit = {testName: newValue, manufacturer: null};
                    setMedicalCovidTest(newMedicalCovidTest);
                    onSelectKit(newMedicalCovidTest);
                } else {
                    setMedicalCovidTest(newValue);
                    onSelectKit(newValue);
                }
            }}
            selectOnFocus
            onBlur={handleBlur}
            handleHomeEndKeys
            options={medicalCovidTestList}
            getOptionLabel={(option: CovidTestingKit) => {
                return option.manufacturer ? `${option.testName} - ${option.manufacturer}` : `${option.testName}`;
            }}
            renderOption={(props, option) => (
                <li {...props}>
                    {option.manufacturer ? `${option.testName} - ${option.manufacturer}` : `${option.testName}`}
                </li>
            )}
            freeSolo
            renderInput={(params) => (
                <TextField
                    {...params}
                    label="Marque et référence"
                    variant="outlined"
                    inputProps={{ ...params.inputProps, maxLength: 150 }} // Limiting input to 300 characters
                />
            )}
            sx={{ backgroundColor: '#e8f0fe' }}/>
            <FormHelperText>Selectionner ou entrez un test Covid</FormHelperText>

        </>

);
};

export default CovidTestSelector;
