import React, { useState, useEffect } from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import MedicalTestService, {MEDICAL_TEST_URL_IDENTIFIER} from '../../services/MedicalTestService.ts';
import COVID19TestDetail from './covid19/COVID19TestDetail.tsx';
import CystisisTestDetail from './cystitis/CystisisTestDetail.tsx';
import SoreThroatTestDetail from "./soreThroat/SoreThroatTestDetail.tsx";
import {MedicalTest, MedicalTestType} from '../../types/types.ts';
import {Dispatch} from "redux";
import {useDispatch} from "react-redux";
import {getActionDisplayMessage} from "../../actions/ReduxAction.ts";


const MedicalTestDetail: React.FC = () => {
    const { testId } = useParams<{ testId: string }>();
    const [medicalTest, setMedicalTest] = useState<MedicalTest | null>(null);
    const [isLoading, setIsLoading] = useState(true);

    const dispatch: Dispatch = useDispatch()
    const navigate = useNavigate();
    const { patientId } = useParams<{ patientId: string }>();

    useEffect(() => {
        MedicalTestService.getMedicalTestById(testId)
            .then(medicalTest => {
                setIsLoading(false);
                setMedicalTest(medicalTest);
            })
            .catch(error => {
                console.error('Error fetching Medical test details:', error);
                setIsLoading(false);
                dispatch(getActionDisplayMessage("Impossible de récuperer le test ", "error"))
                navigate(`/patient/${patientId}/${MEDICAL_TEST_URL_IDENTIFIER}`);
            });
    }, [testId,patientId,dispatch,navigate]);

    const getComponentToDisplay = () => {
        if (!medicalTest) return null;

        switch (medicalTest.type) {
            case MedicalTestType.COVID19:
                return <COVID19TestDetail medicalTest={medicalTest} patient={medicalTest.patientRef} /* *__/ debug={true} /* /*//>;
            case MedicalTestType.CYSTITIS:
                return <CystisisTestDetail medicalTest={medicalTest} patient={medicalTest.patientRef} /* *__/ debug={true} /* /*//>;
            case MedicalTestType.SORE_THROAT:
                return <SoreThroatTestDetail medicalTest={medicalTest} patient={medicalTest.patientRef} /* *__/ debug={true} /* /*//>;
             default:
                console.error("Medical type not recognised: " + medicalTest.type);
                return <div>Erreur: Test non reconnue</div>;
        }
    };


    if (isLoading) {
        return <CircularProgress />;
    }

    return getComponentToDisplay();
};

export default MedicalTestDetail;
