import axios from 'axios';
import { USER_LOCAL_STORAGE_KEY } from './auth.service.js';
import jwt_decode from 'jwt-decode';

const hostname = window.location.hostname;
let APIbaseURLVar;
export const PROD_ENVIRONMENT = "PROD_ENVIRONMENT";
export const TEST_ENVIRONMENT = "TEST_ENVIRONMENT";

let ENV = "UNKWOWN";
switch (true) {
  case hostname.includes("entretien-test"):
    APIbaseURLVar = 'https://api-test.healthbees.fr/api/v1';
    ENV=TEST_ENVIRONMENT;
    break;
  case hostname.includes("entretien"):
    APIbaseURLVar = 'https://api.healthbees.fr/api/v1';
    ENV=PROD_ENVIRONMENT;
    break;
  default:
    APIbaseURLVar = 'http://localhost:8080/api/v1'; 
}
export  const ENVIRONMENT = ENV;
export  const APIbaseURL = APIbaseURLVar;

const instance = axios.create({
  baseURL: APIbaseURL,
});
export const CancelToken = axios.CancelToken;
export const isCancel = axios.isCancel;


const nonLoginEndpoints =["/users/register","/users/verify"]
// Add a request interceptor to check the expiration of the token and refresh it if needed
instance.interceptors.request.use(
  (config) => {
    // Get the access token from local storage
    const userStorageStr = localStorage.getItem(USER_LOCAL_STORAGE_KEY);

    const userStorage = userStorageStr && JSON.parse(localStorage.getItem(USER_LOCAL_STORAGE_KEY));
    const accessToken = userStorage && userStorage.accessToken;
    const refreshToken = userStorage && userStorage.refreshToken;

    if (accessToken) {
      // Check if the token is expired
      const decodedToken = jwt_decode(accessToken);
      const currentTime = Date.now() / 1000;
      const tenMinutesInSeconds = 10 * 60;

      if (decodedToken.exp < currentTime +tenMinutesInSeconds) {
        // Token is expired or expires in less than 10 minutes, try refreshing it
        if (refreshToken) {
          // Send a refresh request to the server
          return axios.post('/auth/refresh', { refreshToken: refreshToken }, {
            baseURL: instance.defaults.baseURL, // Use the baseURL from the instance
          })
          .then((response) => {
            // Save the new access and refresh tokens to local storage
            localStorage.setItem(USER_LOCAL_STORAGE_KEY, JSON.stringify(response.data));

            // Add the new access token to the request headers
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;

            return config;
          }).catch(() => {
            // Failed to refresh the token, redirect to the login page
            redirectToLoginPageAndLogout();
          });
        } else {
          // No refresh token, redirect to the login page
          redirectToLoginPageAndLogout();
        }
      } else {
        // Token is not expired, add it to the request headers
        config.headers.Authorization = `Bearer ${accessToken}`;
        return config;
      }
    } else {
      if(nonLoginEndpoints.some(endpoint => config.url.startsWith(endpoint))){
        return config;
      }
      // No access token for logged enpoints, redirect to the login page
      redirectToLoginPageAndLogout();
    }
  },
  (error) => {
    return Promise.reject(error);
  }
);

function redirectToLoginPageAndLogout() {
    localStorage.removeItem(USER_LOCAL_STORAGE_KEY)
    window.location.href = '/';

  }
export default instance;
