import React, { useState, useEffect } from 'react';
import { Navigate,useNavigate  } from 'react-router-dom';
import { connect } from 'react-redux';
import {actionDisplayMessage} from "../../../actions/auth.action"
import {
    Grid,
    Stack,
    Paper,
    Typography,
    TextField,
    Box,
    Button,
    CircularProgress,
    FilledInput,
    InputAdornment,
    FormHelperText,
    Dialog,
    DialogTitle,
    DialogContent,
    FormControl,
    MenuItem,
    Select,
    InputLabel,
    Checkbox,
    FormControlLabel,
    FormGroup,
    Divider,
    List,
    ListItemText,
    ListItem,
    Tooltip
  } from "@mui/material";
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import TreatmentDetail from '../../../components/consultation/treatmentDetail.component.js';
import IconButton from '@mui/material/IconButton';
import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Cancel';
import MedicationIcon from '@mui/icons-material/Medication';
import HelpIcon from '@mui/icons-material/Help';
import UtilService from '../../../services/util.service.js';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import DoneIcon from '@mui/icons-material/Done';
import HorizontalSelect from '../../../components/field/horizontalSelect.component.js';
import ConsultationService from '../../../services/consultation.service.js'
import ChemoMedicatioDoc from './chemoMedicationDoc.component';



function ChemoFirstConsultationDetail(props) {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [chemoInterview, setChemoInterview] = useState(null);

  const patientId = props.patient? props.patient.id:null;


  const [patient, setPatient] = useState(null);
  const [isFormValid/*, setIsFormValid*/] = useState(true);
  const [isDialogOtherTreatmentOpen, setIsDialogOtherTreatmentOpen] = useState(false);
  const [isDialogChemoTreatmentOpen, setIsDialogChemoTreatmentOpen] = useState(false);


  const [editingChemoTreatment, setEditingChemoTreatment] = useState({});
  const [editingChemoTreatmentIndex, setEditingChemoTreatmentIndex] = useState(-1);

  const [editingOtherTreatment, setEditingOtherTreatment] = useState({});
  const [editingOtherTreatmentIndex, setEditingOtherTreatmentIndex] = useState(-1);
  
  const [weight, setWeight] = useState('');
  const [height, setHeight] = useState('');
  const [imc, setIMC] = useState('');
  
  const [allergiesFreeText, setAllergiesFreeText] = useState('');
  const [socialMotorCognitiveDifficultiesFreeText, setSocialMotorCognitiveDifficultiesFreeText] = useState('');

  const [medicalTeamFreeText, setMedicalTeamFreeText] = useState('');
  
  const [lifePlace, setLifePlace] = useState('');
  const [aideMenagere, setAideMenagere] = useState(false);
  const [ide, setIde] = useState(false);
  const [kinesitherapeute, setKinesitherapeute] = useState(false);
  const [portageRepas, setPortageRepas] = useState(false);
  const [telealarme, setTelealarme] = useState(false);

  const [alcoolConsumption, setAlcoolConsumption] = useState('');
  const [tobacoConsumption, setTobacoConsumption] = useState('');
  const [doWeeklyPhysicalActivity, setDoWeeklyPhysicalActivity] = useState('');
  const [doManualActivity, setDoManualActivity] = useState('');
  const [doContactSport, setDoContactSport] = useState('');
  const [lifeHabitsComments, setLifeHabitsComments] = useState('');

  const [knowTreatment, setKnowTreatment] = useState('');
  const [knowWhatTheTreatmentIsUsedFor, setKnowWhatTheTreatmentIsUsedFor] = useState('');
  const [knowWhatToDoWhenForgetTreatment, setKnowWhatToDoWhenForgetTreatment] = useState('');
  const [knowSideEffectsOfTreatment, setKnowSideEffectsOfTreatment] = useState('');
  const [knowPreventiveMeasuresForSideEffects, setKnowPreventiveMeasuresForSideEffects] = useState('');
  const [knowHowToHandleSideEffect, setKnowHowToHandleSideEffect] = useState('');
  const [knowSideEffectsWithOtherTreatment, setKnowSideEffectsWithOtherTreatment] = useState('');
  const [knowRestrictedFoodOfTreatment, setKnowRestrictedFoodOfTreatment] = useState('');
  const [referringPhysicianKnowPatientIsOnOralCancerMedication, 
         setReferringPhysicianKnowPatientIsOnOralCancerMedication] = useState('');
   
  const [treatmentTrust, setTreatmentTrust] = useState(null);

  const [patientComment, setPatientComment] = useState('');
  const [conclusion, setConclusion] = useState('');

  const [formData, setFormData] = useState(null);
  const helpingToolTip = `Renseigner les 
Difficultés sociales, par exemple :
- Personne seule ou en contact limité avec des personnes aidantes,
- Éloignement physique des villes ou des lieux de soin.

Difficultés motrices, par exemple:
- Besoin d'une canne, d'un fauteuil roulant, d'un déambulateur, etc.
- Mobilité réduite suite à une opération chirurgicale ou à une blessure.

Difficultés cognitives ou sensorielles, par exemple:
- Problèmes de mémoire, maladie d'Alzheimer.
- Problèmes d'empathie, absence de filtre, etc.`;
  const initiazeFormData = (formData) => { 
    setIsLoading(false);
    setFormData(formData);
    setPatient(formData.patientRef);
    setHeight(formData?.patientHeight ?? height);
    setWeight(formData?.patientWeight ?? weight);
    setIMC(formData?.imc ?? imc);
    setMedicalTeamFreeText(formData?.medicalTeamFreeText?? medicalTeamFreeText)
    setAllergiesFreeText(formData?.allergiesFreeText?? allergiesFreeText)
    setSocialMotorCognitiveDifficultiesFreeText(formData?.socialMotorCognitiveDifficultiesFreeText?? socialMotorCognitiveDifficultiesFreeText)
    setLifePlace(formData?.lifeHabits?.lifePlace ?? lifePlace)
    setAideMenagere((formData?.lifeHabits?.existingSupport ?? []).includes('Aide-ménagère'))
    setIde((formData?.lifeHabits?.existingSupport ?? []).includes('IDE'))
    setKinesitherapeute((formData?.lifeHabits?.existingSupport ?? []).includes('Kinésithérapeute'))
    setPortageRepas((formData?.lifeHabits?.existingSupport ?? []).includes('Portage des repas'))
    setTelealarme((formData?.lifeHabits?.existingSupport ?? []).includes('Téléalarme'))
    setAlcoolConsumption(formData?.lifeHabits?.alcoolConsumption ?? '')
    setTobacoConsumption(formData?.lifeHabits?.tobacoConsumption ?? tobacoConsumption)
    setDoWeeklyPhysicalActivity(formData?.lifeHabits?.doWeeklyPhysicalActivity ?? doWeeklyPhysicalActivity)
    setDoContactSport(formData?.lifeHabits?.doContactSport ?? doContactSport)
    setDoManualActivity(formData?.lifeHabits?.doManualActivity ?? doManualActivity)
    setLifeHabitsComments(formData?.lifeHabits?.comment ?? lifeHabitsComments)

    setKnowTreatment(formData?.chemoTherapyGeneral?.knowTreatment ?? knowTreatment)
    setKnowWhatTheTreatmentIsUsedFor(formData?.chemoTherapyGeneral?.knowWhatTheTreatmentIsUsedFor ?? knowWhatTheTreatmentIsUsedFor)
    setKnowWhatToDoWhenForgetTreatment(formData?.chemoTherapyGeneral?.knowWhatToDoWhenForgetTreatment ?? knowWhatToDoWhenForgetTreatment)
    setKnowSideEffectsOfTreatment(formData?.chemoTherapyGeneral?.knowSideEffectsOfTreatment ?? knowSideEffectsOfTreatment)
    setKnowPreventiveMeasuresForSideEffects(formData?.chemoTherapyGeneral?.knowPreventiveMeasuresForSideEffects ?? knowPreventiveMeasuresForSideEffects)
    setKnowHowToHandleSideEffect(formData?.chemoTherapyGeneral?.knowHowToHandleSideEffect ?? knowHowToHandleSideEffect)
    setKnowSideEffectsWithOtherTreatment(formData?.chemoTherapyGeneral?.knowSideEffectsWithOtherTreatment ?? knowSideEffectsWithOtherTreatment)
    setKnowRestrictedFoodOfTreatment(formData?.chemoTherapyGeneral?.knowRestrictedFoodOfTreatment ?? knowRestrictedFoodOfTreatment)
    setReferringPhysicianKnowPatientIsOnOralCancerMedication(formData?.referringPhysicianKnowPatientIsOnOralCancerMedication ?? referringPhysicianKnowPatientIsOnOralCancerMedication )
    setTreatmentTrust(formData?.treatmentTrust ?? treatmentTrust)
    setPatientComment(formData?.patientComment ?? patientComment)
    setConclusion(formData?.conclusion ?? conclusion)
  }


  useEffect(() => {
    setIsLoading(false);
    setChemoInterview(props.consultation);
    initiazeFormData(props.consultation);
     // eslint-disable-next-line
 }, [props.consultation]);
 

  const openDialogOtherTreatment = () => {
    setIsDialogOtherTreatmentOpen(true);
  };

  const openDialogChemoTreatment = () => {
    setIsDialogChemoTreatmentOpen(true);
  };

  const closeDialogTreatment = () => {
    if(isDialogChemoTreatmentOpen)
    {
      setIsDialogChemoTreatmentOpen(false);
    }
    else if(isDialogOtherTreatmentOpen)
    {
      setIsDialogOtherTreatmentOpen(false)
    }
  };

  const handleSaveTreatment = (treatmentType,treatment,index) => {
    // If the treatment has an index, update the existing treatment
    if (index !== null && index !== undefined) {
      setFormData(prevState => {
        let treatments = treatmentType === 'CHEMO' ? prevState.chemoTreatments : prevState.otherTreatments
        const updatedTreatments = treatments.map((t, i) => {
          if (i === index) {
            return treatment;
          }
          return t;
        });

        return treatmentType === 'CHEMO' ? 
                { ...prevState, chemoTreatments: updatedTreatments }:
                { ...prevState, otherTreatments: updatedTreatments };

      });
    } else {
      // Otherwise, add a new treatment
      setFormData(prevState => {
        return treatmentType === 'CHEMO' ? 
        { ...prevState, chemoTreatments: [...prevState?.chemoTreatments?? [], treatment] }:
        { ...prevState, otherTreatments: [...prevState?.otherTreatments?? [], treatment] };
      });
    }
  };


  const handleTreatmentItemRemove = (treatmentType,index) => {

    let treatments = treatmentType === 'CHEMO' ? formData.chemoTreatments : formData.otherTreatments ;
    const newTreatments = treatments.filter((_, i) => i !== index);
    if (treatmentType === 'CHEMO') {
      setFormData({ ...formData, chemoTreatments: newTreatments });
    } else {
      setFormData({ ...formData, otherTreatments: newTreatments });
    }

  };

  const handleNewTreatment = (treatmentType) => {
    if(treatmentType === 'CHEMO' ){
      setEditingChemoTreatmentIndex(null)
      setEditingChemoTreatment(null);
      openDialogChemoTreatment();
    }
    else {
      setEditingOtherTreatmentIndex(null)
      setEditingOtherTreatment(null);
      openDialogOtherTreatment();
    }

  };
  
  const handleTreatmentItemEdit = (treatmentType,index) => {
    if(treatmentType === 'CHEMO' ){
      setEditingChemoTreatmentIndex(index)
      setEditingChemoTreatment(formData.chemoTreatments[index])
      openDialogChemoTreatment();
    }
    else {
      setEditingOtherTreatmentIndex(index)
      setEditingOtherTreatment(formData.otherTreatments[index])
      openDialogOtherTreatment();
    }
  };

  const handleLifePlaceChange = (event) => {
    setLifePlace(event.target.value);
  }

  
  const handleValidateClick = () => {
    setIsLoading(true);
    const successMessage = "L'entretien a été enregistrées avec succès";

    ConsultationService.validateChemoFirstConsultation(formData)
        .then(() => {
        setIsLoading(false);
        props.dispatch(actionDisplayMessage(successMessage,"success"));
        navigate(`/patient/${patientId}/chemo/`);
        })
        .catch((error) => {
        console.error(error);
        setIsLoading(false);
        props.dispatch(
          actionDisplayMessage(
            "Une erreur s'est produite lors de l'enregistrement des modifications",
            "error"));
        });
  };  
  
  const handleSaveClick = () => {
    setIsLoading(true);
    const successMessage = "Les modifications ont été enregistrées avec succès";

    ConsultationService.saveChemoFirstConsultation(formData)
        .then(() => {
        setIsLoading(false);
        props.dispatch(actionDisplayMessage(successMessage,"success"));
        navigate(`/patient/${patientId}/chemo/`);
        })
        .catch((error) => {
        console.error(error);
        setIsLoading(false);
        props.dispatch(
          actionDisplayMessage(
            "Une erreur s'est produite lors de l'enregistrement des modifications",
            "error"));
        });
  };

  const handleDiscardClick = () => {
    setFormData(chemoInterview);
    initiazeFormData(chemoInterview)
  };

  useEffect(() => {
    const handleLifeHabitsChange = () => {
      const selectedCheckboxes = [];
      if (aideMenagere) selectedCheckboxes.push("Aide-ménagère");
      if (telealarme) selectedCheckboxes.push("Téléalarme");
      if (kinesitherapeute) selectedCheckboxes.push("Kinésithérapeute");
      if (portageRepas) selectedCheckboxes.push("Portage des repas");
      if (ide) selectedCheckboxes.push("IDE")
  
      setFormData(prevFormData => ({
        ...prevFormData,
        lifeHabits: {
          ...(prevFormData?.lifeHabits?? {}), // initialize with empty object if null
          lifePlace:lifePlace,
          existingSupport: selectedCheckboxes,
          alcoolConsumption: alcoolConsumption,
          tobacoConsumption: tobacoConsumption,
          doContactSport: doContactSport,
          doManualActivity: doManualActivity,
          doWeeklyPhysicalActivity:doWeeklyPhysicalActivity,
          comment: lifeHabitsComments
        }
      }));
    }
    handleLifeHabitsChange();
  },[lifePlace,aideMenagere,telealarme,
    kinesitherapeute,portageRepas,
    ide,alcoolConsumption,tobacoConsumption,
  doContactSport,doWeeklyPhysicalActivity,
  doManualActivity,lifeHabitsComments]);


  useEffect(() => {
    const handleChemoTherapyGeneralChange= () => {
    
      setFormData(prevFormData => ({
        ...prevFormData,
        chemoTherapyGeneral: {
          ...prevFormData.chemoTherapyGeneral,
          knowTreatment:knowTreatment,
          knowHowToHandleSideEffect: knowHowToHandleSideEffect,
          knowPreventiveMeasuresForSideEffects: knowPreventiveMeasuresForSideEffects,
          knowRestrictedFoodOfTreatment: knowRestrictedFoodOfTreatment,
          knowSideEffectsOfTreatment: knowSideEffectsOfTreatment,
          knowSideEffectsWithOtherTreatment: knowSideEffectsWithOtherTreatment,
          knowWhatToDoWhenForgetTreatment: knowWhatToDoWhenForgetTreatment,
          knowWhatTheTreatmentIsUsedFor:knowWhatTheTreatmentIsUsedFor
        }
      }));
    }
    handleChemoTherapyGeneralChange();
  },[knowTreatment,knowHowToHandleSideEffect,
    knowPreventiveMeasuresForSideEffects,knowRestrictedFoodOfTreatment,
    knowSideEffectsOfTreatment,knowSideEffectsWithOtherTreatment,
    knowWhatToDoWhenForgetTreatment,knowWhatTheTreatmentIsUsedFor]);

  useEffect(() => {
    const handlePatientCommentAndConclusionAndTreatmentTrustChange= () => {
    
      setFormData(prevFormData => ({
        ...prevFormData,
        treatmentTrust: treatmentTrust,
        patientComment: patientComment,
        conclusion:conclusion
      }));
    }
    handlePatientCommentAndConclusionAndTreatmentTrustChange();
  },[patientComment,conclusion,treatmentTrust]);

  useEffect(() => {
    if(props.debug) console.log(formData);
  },[formData,props.debug]);

  useEffect(() => {
    let imc = '';
    if (weight && height) {
      const heightInMeters = height / 100;
      imc = (weight / (heightInMeters * heightInMeters)).toFixed(2);
    } else {
      imc  = ''
    }
    setFormData(prevFormData => ({
      ...prevFormData,
      patientWeight: weight,
      patientHeight: height,
      imc: imc
    }));
    setIMC(imc)
  }, [weight, height]);

  useEffect(() => {
    const handleMedicalTeamFreeTextChange= () => {
    
      setFormData(prevFormData => ({
        ...prevFormData,
        medicalTeamFreeText: medicalTeamFreeText
        }
      ));
    }
    handleMedicalTeamFreeTextChange();
  },[medicalTeamFreeText]);

  useEffect(() => {
    const handleAllergiesFreeTextChange= () => {
    
      setFormData(prevFormData => ({
        ...prevFormData,
        allergiesFreeText: allergiesFreeText
        }
      ));
    }
    handleAllergiesFreeTextChange();
  },[allergiesFreeText]);

  useEffect(() => {
    const handleSocialMotorCognitiveDifficultiesFreeTextChange= () => {
    
      setFormData(prevFormData => ({
        ...prevFormData,
        socialMotorCognitiveDifficultiesFreeText: socialMotorCognitiveDifficultiesFreeText
        }
      ));
    }
    handleSocialMotorCognitiveDifficultiesFreeTextChange();
  },[socialMotorCognitiveDifficultiesFreeText]);
  
  useEffect(() => {
    const handleReferringPhysicianKnowPatientIsOnOralCancerMedication= () => {
    
      setFormData(prevFormData => ({
        ...prevFormData,
        referringPhysicianKnowPatientIsOnOralCancerMedication: referringPhysicianKnowPatientIsOnOralCancerMedication
        }
      ));
    }
    handleReferringPhysicianKnowPatientIsOnOralCancerMedication();
  },[referringPhysicianKnowPatientIsOnOralCancerMedication]);

  const handleWeightChange = (event) => {
    const value = event.target.value;
    if (/^\d+(\.\d+)?$/.test(value)) { // check if the input value is numeric or double
      setWeight(value);
    }
    else{
      setIMC('')
    }
  };

  const handleHeightChange = (event) => {
    const value = event.target.value;
    if (/^\d+(\.\d+)?$/.test(value)) { // check if the input value is numeric or double
      setHeight(value);
    }
    else{

      setIMC('')
    }
  };

  const displayTreatment = (treatmentDetail,treatment, index) => {
   return ( 
      <Grid key={'treatment'+index} item md={4} > <Paper variant="outlined" p={1}>
      <Box  display="flex" flexDirection={"column"}>

        <Typography align="center" variant="h6">
          {treatment.drug.name}, 
          {treatment.dosage.quantity}
          {treatment.endingDate && UtilService.isDateInPast(treatment.endingDate) ?"(Arrêté)":"(En cours)"}  </Typography>
        <Typography variant="text">
          Débuté le
          <strong> {UtilService.handleDateFormat(treatment.beginingDate)} </strong>
          {treatment.endingDate && " arrété le "}
          {treatment.endingDate && <strong>{UtilService.handleDateFormat(treatment.endingDate)}</strong>}            </Typography>
      </Box>
      <Box  display="flex" justifyContent={"center"}>
        <IconButton
          onClick={() => handleTreatmentItemRemove(treatmentDetail,index)}
        ><CancelIcon /> </IconButton>
        <IconButton
          onClick={() => handleTreatmentItemEdit(treatmentDetail,index)}
        ><EditIcon /> </IconButton>

      </Box>
      </Paper></Grid>
   
   )};

  
  if (!props.user.accessToken) {
      return <Navigate replace to="/" />;
    } 
  if( isLoading) {
      return <CircularProgress />;
    }
  if(!patient || !formData){
   return  <CircularProgress />;
  }
  return (
    <Grid container justifyContent="center"  >
    <Grid item sm={8} >
    <Box mx="auto" p={4}   width={'100%'}>
      <Paper variant="outlined">
        <Stack direction="row" mt={1} justifyContent={"right"}>
          <Button sx={{maxWidth:'300px'}} variant="outlined" startIcon={<CancelIcon />} onClick={handleDiscardClick}>
                Annuler les modifications
          </Button>
        </Stack>
        <Stack direction="row" mb={3} justifyContent={"center"}>
          <Typography variant="h3">
                Entretien d'initiation pour traitement anticancéreux par voie orale
          </Typography>
        </Stack>
      </Paper>
      <Paper variant="outlined">
      <Box p={2} display="block">
        <Typography variant="h5" align="center">
          Informations personnelles
        </Typography>
        <Grid container spacing={2} mt={2}>
          <Grid item md={4}>
            <Typography variant="text" color="grey">{ patient.firstName} {patient && patient.lastName} </Typography>
          </Grid>
          <Grid item md={4}>
            <Typography variant="text" color="grey">Né le { UtilService.handleDateFormat(patient.birthday)} </Typography>
          </Grid>
          <Grid item md={4}>
          <Typography variant="text" color="grey">Numéro de SS:  {patient.socialSecurityNumber} </Typography>
          </Grid>
        </Grid>
          <Grid container spacing={2} mt={2}>
          <Grid item md={4}>
            <FilledInput
              id="weight"
              onChange={handleWeightChange}
              value={weight}
              endAdornment={<InputAdornment position="end">KG</InputAdornment>}
            />  
            <FormHelperText id="weight">Poids (en kg)</FormHelperText>  
          </Grid>
          <Grid item md={4}>
            <FilledInput
              id="height"
              onChange={handleHeightChange}
              value={height}
              endAdornment={<InputAdornment position="end">CM</InputAdornment>}
            />  
            <FormHelperText id="height">Taille (en cm)</FormHelperText>  
          </Grid>
          <Grid item md={4}>
            <TextField
                id="imc"
                label={"IMC"}
                value={imc}
                inputProps={{
                  readOnly: true,
                }}
                variant="outlined"
                />
            <FormHelperText id="imc">IMC (calculé automatiquement)</FormHelperText>  
          </Grid>
            
          </Grid>
      </Box>
    </Paper>
    <Box p={'2px'}/>
<Paper variant="outlined">
  <Box p={2} display="block">
    <Typography pb={3} variant="h5" align="center">
    Équipe de soins
    </Typography>
    <Grid container spacing={1}>
      <Grid item md={12}>
        <TextField
            id="medicalTeamFreeText"
            multiline
            fullWidth
            value={medicalTeamFreeText}
            onChange={(event) => setMedicalTeamFreeText(event.target.value)}
            minRows={2}
            maxRows={10}
            placeholder="Renseigner les personnes en charge du suivi médical du patient (medecin traitant,spécialistes, services oncologie..)"
            variant="outlined"
            inputProps={{ maxLength: 300 }}
            />
            <FormHelperText id="comment">300 caractères maximum</FormHelperText>
      </Grid>
      <Grid item md={12} p={2}><Divider /></Grid>
      <Grid item sm={3} md={3} lg={3}>
              <Box sx={{ height: '100%', display: 'flex', flexDirection:'column' , justifyContent:'center'}}> 
              <Typography variant="text">
              Le médecin traitant sait-il que le patient prend des médicaments anticancéreux par voie orale ?              
              </Typography></Box>
          </Grid>
      <Grid pl={2} item sm={9} md={9}  lg={9} > 
                  <HorizontalSelect 
                    changeValue={setReferringPhysicianKnowPatientIsOnOralCancerMedication}
                    value={referringPhysicianKnowPatientIsOnOralCancerMedication}/>
          </Grid>
    </Grid>
  </Box>
</Paper>
<Box p={'2px'}/>
<Paper  variant="outlined">
  <Box  p={2} display="block">
      <Typography pb={3}variant="h5" align="center">
      Traitement(s) anticancéreux oraux
      </Typography>
    <Grid container>
      {formData.chemoTreatments && formData.chemoTreatments.map((treatment, index) => (
        displayTreatment('CHEMO',treatment, index)
      ))}
      
      <Grid item md={12} xs={12}/>
      <Grid item md={4} xs={6} >
        <Box  p={1} display="flex" flexDirection={"column"} justifyContent="center">  <Button
                variant="outlined"
                color="primary"
                startIcon={<MedicationIcon />}
                onClick={()=>handleNewTreatment('CHEMO')}
              >
                Ajouter un traitement anticancéreux oral
          </Button></Box>
        </Grid>
      </Grid>
    </Box>
</Paper>
<Box p={'2px'}/>
<Paper  variant="outlined">
  <Box  p={2} display="block">
      <Typography pb={3}variant="h5" align="center">
      Autre(s) traitement(s) en cours
      </Typography>
    <Grid container>
      {formData.otherTreatments && formData.otherTreatments.map((treatment, index) => (
        displayTreatment('OTHER',treatment, index)
      ))}
      <Grid item md={12} xs={12}/>
      <Grid item md={4} xs={6} >
      <Box  p={1} display="flex" flexDirection={"column"} justifyContent="center">
          <Button
                variant="outlined"
                color="primary"
                startIcon={<MedicationIcon />}
                onClick={()=>handleNewTreatment('OTHER')}
              >
                Ajouter un autre traitement
          </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
</Paper>

<Box p={'2px'}/>
<Paper  variant="outlined">
  <Box  p={2} display="block">
      <Typography pb={3} variant="h5" align="center">
          Habitudes de vie
      </Typography>
      <Grid container spacing={1}>
      <Grid item sm={3} md={3} lg={3}>
         <Box sx={{ height: '100%', display: 'flex', flexDirection:'column' , justifyContent:'center'}}> 
              <Typography variant="text" >
                Lieu de vie
              </Typography>
          </Box></Grid>
          <Grid item sm={9} md={9}  lg={9} > 
            <FormControl sx={{minWidth:'200px'}}  >
              <InputLabel id="lifePlace">Lieu de vie</InputLabel>
              <Select
                labelId="lifePlace"
                id="lifePlace"
                label="Lieu de vie"
                value={lifePlace}
                onChange={handleLifePlaceChange}
              >
                <MenuItem value="aloneAtHome">Seul(e) à son domicile</MenuItem>
                <MenuItem value="withSpouseAtHome">Avec conjoint à son domicile</MenuItem>
                <MenuItem value="withFamilyAtHome">Avec famille à son domicile</MenuItem>
                <MenuItem value="collectiveHousing">Domicile (habitation collective)</MenuItem>
                <MenuItem value="homeHousing">Foyer / logement</MenuItem>
                <MenuItem value="ehpad">EHPAD / Maison médicalisée</MenuItem>
                <MenuItem value="other">Autre</MenuItem>
              </Select>
            </FormControl>
          </Grid>
           <Grid item sm={3} md={3} lg={3}>
              <Box sx={{ height: '100%', display: 'flex', flexDirection:'column' , justifyContent:'center'}}> <Typography variant="text">
                Aides existantes
              </Typography></Box>
          </Grid>
          <Grid pl={2} item sm={9} md={9}  lg={9} > 
          <FormControl component="fieldset">
            <FormGroup aria-label="position" row>
              <FormControlLabel
                value="Aide-ménagère"
                control={<Checkbox
                  checked={aideMenagere}
                  name="Aide-ménagère"
                  onChange={(e) => setAideMenagere(e.target.checked)}
                  />}
                label="Aide-ménagère"
                labelPlacement="end"
              />
              <FormControlLabel
                value="IDE"
                control={<Checkbox 
                  checked={ide}
                  name="IDE"
                  onChange={(e) => setIde(e.target.checked)}
                  />}
                label="IDE"
                labelPlacement="end"
              />
              <FormControlLabel
                value="Kinésithérapeute"
                control={<Checkbox 
                  checked={kinesitherapeute}
                  name="Kinésithérapeute"
                  onChange={(e) => setKinesitherapeute(e.target.checked)}
                  />}
                label="Kinésithérapeute"
                labelPlacement="end"
              />
              <FormControlLabel
                value="Portage de repas"
                control={<Checkbox 
                  checked={portageRepas}
                  name="Portage de repas"
                  onChange={(e) => setPortageRepas(e.target.checked)}
                  />}
                label="Portage de repas"
                labelPlacement="end"
              />
              <FormControlLabel
                value="Téléalarme"
                control={<Checkbox 
                  checked={telealarme}
                  name="Téléalarme"
                  onChange={(e) => setTelealarme(e.target.checked)}
                  />}
                label="Téléalarme"
                labelPlacement="end"
              />
            </FormGroup>
          </FormControl>
          </Grid>
          <Grid item sm={3} md={3} lg={3}>
              <Box sx={{ height: '100%', display: 'flex', flexDirection:'column' , justifyContent:'center'}}> <Typography variant="text">
                Alcool par semaine
              </Typography></Box>
          </Grid>
          <Grid pl={2} item sm={9} md={9}  lg={9} > 
                  <HorizontalSelect 
                    choices={['Non', '< 10 verres', '>= 10 verres']}
                    values ={['Non', '< 10 verres', '>= 10 verres']} 
                    changeValue={setAlcoolConsumption}
                    value={alcoolConsumption}/>
          </Grid>
          <Grid item sm={3} md={3} lg={3}>
              <Box sx={{ height: '100%', display: 'flex', flexDirection:'column' , justifyContent:'center'}}> <Typography variant="text">
                Tabac
              </Typography></Box>
          </Grid>
          <Grid pl={2} item sm={9} md={9}  lg={9} > 
                  <HorizontalSelect 
                    changeValue={setTobacoConsumption}
                    value={tobacoConsumption}/>
          </Grid>
          
          <Grid item sm={3} md={3} lg={3}>
              <Box sx={{ height: '100%', display: 'flex', flexDirection:'column' , justifyContent:'center'}}> <Typography variant="text">
                Activité physique par semaine
              </Typography></Box>
          </Grid>
          <Grid pl={2} item sm={9} md={9}  lg={9} > 
                  <HorizontalSelect 
                    changeValue={setDoWeeklyPhysicalActivity}
                    value={doWeeklyPhysicalActivity}
                    />
          </Grid>
          <Grid item sm={3} md={3} lg={3}>
              <Box sx={{ height: '100%', display: 'flex', flexDirection:'column' , justifyContent:'center'}}> <Typography variant="text">
                Sport de contact
              </Typography></Box>
          </Grid>
          <Grid pl={2} item sm={9} md={9}  lg={9} > 
                  <HorizontalSelect 
                    changeValue={setDoContactSport}
                    value={doContactSport}
                    />
          </Grid>

          <Grid item sm={3} md={3} lg={3}>
          <Box sx={{ height: '100%', display: 'flex', flexDirection:'column' , justifyContent:'center'}}> <Typography variant="text">
            Activité manuelle
          </Typography></Box>
          </Grid>
          <Grid pl={2} item sm={9} md={9}  lg={9} > 
                  <HorizontalSelect 
                    changeValue={setDoManualActivity}
                    value={doManualActivity}/>
          </Grid>
          <Grid item md={12}>
            <TextField
                id="lifeHabitsComments"
                multiline
                fullWidth
                value={lifeHabitsComments}
                onChange={(event) => setLifeHabitsComments(event.target.value)}
                minRows={2}
                maxRows={10}
                placeholder="Vos commentaires sur les habitudes de vie du patient"
                variant="outlined"
                inputProps={{ maxLength: 250 }}
                />
                <FormHelperText id="comment">250 caractères maximum</FormHelperText>

            </Grid>
      </Grid>
      </Box>
</Paper>
<Box p={'2px'}/>
<Paper  variant="outlined">
  <Box  p={2} display="block">

      <Typography pb={3} variant="h5" align="center"> 
        Assistance, difficulté(s) motrice(s), cognitive(s), sensoriel(s) ou sociale(s) 
        <Tooltip title={
          <React.Fragment>
            {helpingToolTip.split('\n').map((line, index) => (
              <Typography key={index} variant="h5">
                {line} {index < helpingToolTip.split('\n').length - 1 ? <br /> : ''}
               </Typography>
              ))}
          </React.Fragment>
          }>
            <HelpIcon sx={{ transform: "scale(1.2)"}} />
          </Tooltip>

      </Typography>
      
      <Grid container spacing={1}>
      <Grid item md={12}>
            <TextField
                id="socialMotorCognitiveDifficultiesFreeText"
                multiline
                fullWidth
                value={socialMotorCognitiveDifficultiesFreeText}
                onChange={(event) => setSocialMotorCognitiveDifficultiesFreeText(event.target.value)}
                minRows={10}
                maxRows={15}
                placeholder={helpingToolTip}
                variant="outlined"
                inputProps={{ maxLength: 600 }}
                InputLabelProps={{shrink: true}}
                />
                <FormHelperText id="comment">600 caractères maximum</FormHelperText>
            </Grid>
      </Grid>
  </Box>
</Paper>

<Box p={'2px'}/>
<Paper  variant="outlined">
  <Box  p={2} display="block">
      <Typography pb={3} variant="h5" align="center"> Allergies et intolérances</Typography>
      <Grid container spacing={1}>
      <Grid item md={12}>
            <TextField
                id="allergiesFreeText"
                multiline
                fullWidth
                value={allergiesFreeText}
                onChange={(event) => setAllergiesFreeText(event.target.value)}
                minRows={2}
                maxRows={10}
                placeholder="Renseigner les allergies et intolérances éventuelles du patient :"
                variant="outlined"
                inputProps={{ maxLength: 300 }}
                />
                <FormHelperText id="comment">300 caractères maximum</FormHelperText>
            </Grid>
      </Grid>
  </Box>
</Paper>

<Box p={'2px'}/>
<Paper  variant="outlined">
  <Box  p={2} display="block">
      <Typography pb={3} variant="h5" align="center">
          Notion générale sur le traitement anticancéreux
      </Typography>
      <Grid container spacing={1}>
          <Grid item sm={5} md={5} lg={5}>
              <Box sx={{ height: '100%', display: 'flex', flexDirection:'column' , justifyContent:'center'}}> <Typography variant="text">
              Le patient connaît-il son traitement et son plan de prise ?
            </Typography></Box>
          </Grid>
          <Grid pl={2} item sm={7} md={7} > 
                  <HorizontalSelect 
                    choices={['Acquis', 'Partiellement acquis', 'Non acquis']}
                    values ={['ACQUIS', 'PARTIELEMENT', 'NON ACQUIS']} 
                    changeValue={setKnowTreatment}
                    value={knowTreatment}/>
          </Grid>
          <Grid item sm={5} md={5} lg={5}>
              <Box sx={{ height: '100%', display: 'flex', flexDirection:'column' , justifyContent:'center'}}> <Typography variant="text">
                La patient sait-il à quoi sert son traitement ?
              </Typography></Box>
          </Grid>
          <Grid pl={2} item sm={7} md={7} > 
                  <HorizontalSelect 
                    choices={['Acquis', 'Partiellement acquis', 'Non acquis']}
                    values ={['ACQUIS', 'PARTIELEMENT', 'NON ACQUIS']} 
                    changeValue={setKnowWhatTheTreatmentIsUsedFor}
                    value={knowWhatTheTreatmentIsUsedFor}/>
          </Grid>
          <Grid item sm={5} md={5} lg={5}>
              <Box sx={{ height: '100%', display: 'flex', flexDirection:'column' , justifyContent:'center'}}> <Typography variant="text">
                La patient sait-il quoi faire en cas d'oubli de prise ?
              </Typography></Box>
          </Grid>
          <Grid pl={2} item sm={7} md={7} > 
                  <HorizontalSelect 
                    choices={['Acquis', 'Partiellement acquis', 'Non acquis']}
                    values ={['ACQUIS', 'PARTIELEMENT', 'NON ACQUIS']} 
                    changeValue={setKnowWhatToDoWhenForgetTreatment}
                    value={knowWhatToDoWhenForgetTreatment}/>
          </Grid>
          <Grid item sm={5} md={5} lg={5}>
              <Box sx={{ height: '100%', display: 'flex', flexDirection:'column' , justifyContent:'center'}}> <Typography variant="text">
                La patient connait-il les effets indésirable de son traitement ?
              </Typography></Box>
          </Grid>
          <Grid pl={2} item sm={7} md={7} > 
                  <HorizontalSelect 
                    choices={['Acquis', 'Partiellement acquis', 'Non acquis']}
                    values ={['ACQUIS', 'PARTIELEMENT', 'NON ACQUIS']} 
                    changeValue={setKnowSideEffectsOfTreatment}
                    value={knowSideEffectsOfTreatment}/>
          </Grid>
          <Grid item sm={5} md={5} lg={5}>
              <Box sx={{ height: '100%', display: 'flex', flexDirection:'column' , justifyContent:'center'}}> <Typography variant="text">
                La patient connait-il les mesures préventives des effets indésirables ?
              </Typography></Box>
          </Grid>
          <Grid pl={2} item sm={7} md={7} > 
                  <HorizontalSelect 
                    choices={['Acquis', 'Partiellement acquis', 'Non acquis']}
                    values ={['ACQUIS', 'PARTIELEMENT', 'NON ACQUIS']} 
                    changeValue={setKnowPreventiveMeasuresForSideEffects}
                    value={knowPreventiveMeasuresForSideEffects}/>
          </Grid>
          <Grid item sm={5} md={5} lg={5}>
              <Box sx={{ height: '100%', display: 'flex', flexDirection:'column' , justifyContent:'center'}}> <Typography variant="text">
                La patient sait il comment gérer l'apparition des effets indésirables ?
              </Typography></Box>
          </Grid>
          <Grid pl={2} item sm={7} md={7} > 
                  <HorizontalSelect 
                    choices={['Acquis', 'Partiellement acquis', 'Non acquis']}
                    values ={['ACQUIS', 'PARTIELEMENT', 'NON ACQUIS']} 
                    changeValue={setKnowHowToHandleSideEffect}
                    value={knowHowToHandleSideEffect}/>
          </Grid>
          <Grid item sm={5} md={5} lg={5}>
              <Box sx={{ height: '100%', display: 'flex', flexDirection:'column' , justifyContent:'center'}}> <Typography variant="text">
                La patient est-il avisé des intéractions de son traitement avec d'autres médicaments et d'autres produits qu'il serait amené à prendre ?
              </Typography></Box>
          </Grid>
          <Grid pl={2} item sm={7} md={7} > 
                  <HorizontalSelect 
                    choices={['Acquis', 'Partiellement acquis', 'Non acquis']}
                    values ={['ACQUIS', 'PARTIELEMENT', 'NON ACQUIS']} 
                    changeValue={setKnowSideEffectsWithOtherTreatment}
                    value={knowSideEffectsWithOtherTreatment}/>
          </Grid>
          <Grid item sm={5} md={5} lg={5}>
              <Box sx={{ height: '100%', display: 'flex', flexDirection:'column' , justifyContent:'center'}}> <Typography variant="text">
                La patient connait-il les aliments déconseillés avec son traitement ?
              </Typography></Box>
          </Grid>
          <Grid pl={2} item sm={7} md={7} > 
                  <HorizontalSelect 
                    choices={['Acquis', 'Partiellement acquis', 'Non acquis']}
                    values ={['ACQUIS', 'PARTIELEMENT', 'NON ACQUIS']} 
                    changeValue={setKnowRestrictedFoodOfTreatment}
                    value={knowRestrictedFoodOfTreatment}/>
          </Grid>
      </Grid>    
    </Box>
</Paper>
<Box p={'2px'}/>
<Paper  variant="outlined">
  <Box  p={2} display="block">
      <Typography pb={3} variant="h5" align="center">
          Evaluation de la confiance dans le traitement et de la motivation à prendre le traitement
      </Typography>
      <Grid container spacing={1}>
          <Grid item sm={12} md={12} lg={12}>
              <Box sx={{ height: '100%', display: 'flex', flexDirection:'column' , justifyContent:'center'}}> <Typography variant="text">
              Sur une échelle de 1 à 10, à quel point vous sentez-vous à l'aise avec la prise de votre traitement ?
              </Typography></Box>
          </Grid>
          <Grid item sm={2} md={2} > 
          <Box sx={{ height: '100%', display: 'flex', flexDirection:'column' , justifyContent:'center'}}> <Typography variant="text">
              1 = Très peu confiant
              </Typography></Box>
          </Grid>
          <Grid item sm={8} md={8} lg={8}>
            <HorizontalSelect 
                choices={[ '1', '2', '3', '4', '5', '6', '7', '8', '9', '10']}
                values ={[  1,   2,   3,   4,   5,   6,   7,    8,   9,  10 ]}
                changeValue={setTreatmentTrust}
                value={treatmentTrust}
                borderRadius={'30px'}/>
          </Grid>
          <Grid item sm={2} md={2} > 
          <Box sx={{ height: '100%', display: 'flex', flexDirection:'column' , justifyContent:'center'}}> <Typography variant="text">
              10 = Extrêmement confiant
            </Typography></Box>
          </Grid>
        </Grid>
  </Box>
</Paper>
<Box p={'2px'}/>
<Paper  variant="outlined">
  <Box  p={2} display="block">
      <Typography pb={3} variant="h5" align="center">
          Informations complementaires demandées par le patient
      </Typography>
      <Grid container spacing={1}>
      <Grid item md={12}>
            <TextField
                id="patientComment"
                multiline
                fullWidth
                value={patientComment}
                onChange={(event) => setPatientComment(event.target.value)}
                minRows={2}
                maxRows={10}
                placeholder="Informations complémentaires demandées par le patient"
                variant="outlined"
                inputProps={{ maxLength: 250 }}
                />
                <FormHelperText id="comment">250 caractères max</FormHelperText>
            </Grid>
      </Grid>
  </Box>
</Paper>
<Box p={'2px'}/>

<Box p={'2px'}/>
<Paper  variant="outlined">
  <Box  p={2} display="block">
      <Typography pb={3} variant="h5" align="center">
    Vos conclusions     
    </Typography>
      <Grid container spacing={1}>
      <Grid item md={12}>
            <TextField
                id="conclusion"
                multiline
                fullWidth
                value={conclusion}
                onChange={(event) => setConclusion(event.target.value)}
                minRows={2}
                maxRows={10}
                placeholder="Vos conclusions sur l'entretien"
                variant="outlined"
                inputProps={{ maxLength: 250 }}
                />
                <FormHelperText id="comment">250 caractères max</FormHelperText>
            </Grid>
      </Grid>
  </Box>
</Paper>
    <Box p={'2px'}/>
    {chemoInterview.status === 'DRAFT'?( <>
      <Button
        variant="contained"
        color="primary"
        startIcon={<DoneIcon />}
        onClick={handleValidateClick}
        sx={{ mr: 1 }}
        disabled={!isFormValid}
      >
        Valider l'entretien
      </Button>
      <Button
          variant="outlined"
          color="primary"
          startIcon={<SaveIcon />}
          onClick={handleSaveClick}
          sx={{ mr: 1 }}
          disabled={!isFormValid}
        >
          Enregistrer pour plus tard
        </Button></>
        ):(
          <Button
          variant="contained"
          color="primary"
          startIcon={<SaveIcon />}
          onClick={handleSaveClick}
          sx={{ mr: 1 }}
          disabled={!isFormValid}
        >
          Enregistrer
          </Button>
         )}
      <Box mt={4} />
     <Dialog
        open={isDialogOtherTreatmentOpen ||isDialogChemoTreatmentOpen }
        onClose={closeDialogTreatment}
        fullWidth
        disableRestoreFocus={true}
        maxWidth="lg"
      >
        <DialogTitle>
        <Grid container>
            <Grid item md={10}>
              <Box display="flex" justifyContent="center" ><Typography variant="h4" > 
              {isDialogChemoTreatmentOpen ? "Traitement(s) anticancéreux oral" :"Autre(s) traitement(s)" }
              </Typography></Box>
            </Grid>

            <Grid item md={2}>
              <Button
                variant="outlined"
                color="primary"
                startIcon={<CloseRoundedIcon />}
                onClick={closeDialogTreatment}
                > Quitter</Button>
            </Grid>
          </Grid>
          </DialogTitle>
          <DialogContent>


          <TreatmentDetail
              treatmentDetailType ={isDialogChemoTreatmentOpen ? 'CHEMO': 'OTHER'}
              handleSave={(treatment,index) => 
                            handleSaveTreatment(isDialogChemoTreatmentOpen ? 'CHEMO': 'OTHER',
                                      treatment,index)}
              closeDialog={closeDialogTreatment} 
              treatment={isDialogChemoTreatmentOpen ? editingChemoTreatment: editingOtherTreatment}
              index={isDialogChemoTreatmentOpen ? editingChemoTreatmentIndex: editingOtherTreatmentIndex}
            />
        
          </DialogContent>
      </Dialog>
    </Box>
    </Grid>
    <Grid item  paddingTop={4} sm={4} >  
    <Box  paddingBottom={0.5}><Paper variant="outlined">
            <Stack direction="row" align="center" justifyContent={'center'}  >
              <Typography variant='h3' align="center">Documentation</Typography>
            </Stack>
          </Paper></Box>
          <Paper variant="outlined" >
             <Stack direction="row"  alignItems="center" justifyContent={'center'} gap={1} >
              <NewReleasesIcon />
              <Typography variant='h5' align="center">Objectifs de l’entretien :</Typography>
             </Stack>

              <List>
             
              <ListItem alignItems="flex-start" >
                <ListItemText
                  primary="Recueillir les informations générales sur le patient "   
                />            
              </ListItem>
              <Divider variant="inset" component="li" />
              <ListItem alignItems="flex-start">
                <ListItemText
                  primary="Évalue-le ressenti du patient par rapport à son traitement"
                />
              </ListItem>
              <Divider variant="inset" component="li" />
              <ListItem alignItems="flex-start">
                <ListItemText
                  primary="Présenter au patient le schéma et les règles de prise de son traitement."/>
              </ListItem>
            </List>
          </Paper>
          <ChemoMedicatioDoc chemoTreatments={formData.chemoTreatments} />
          
    </Grid>
    </Grid>
  );  
}

function mapStateToProps(state) {
    const user  = state.authReducer.user;
    return {
      user
    };
}
export default connect(mapStateToProps)(ChemoFirstConsultationDetail);
