//import axios from "axios";
import {APIbaseURL} from "./axiosInstance.js";
import axios from 'axios';


class RegsiterService {

  register(registrationData) {
    return axios.post(APIbaseURL+"/users/register",registrationData);
  }
  verify(tokenId) {
    return axios.post(APIbaseURL+`/users/verify?token=${tokenId}`);
  }
  //http://localhost:3000/verify?verificationToken=649b02cb6497521684901752
}

export default RegsiterService = new RegsiterService() ; 
