//import axios from "axios";
import axios from "./axiosInstance.js";


class UserService {
    
  getCurrentUser() {
    return axios.get("/users");
  }
  getUser(userId){
    return axios.get(`/users/${userId}`);
  }

  saveUser(userId,user){
    return axios.post(`/users/${userId}`, user);
  }

}

export default UserService = new UserService() ; 
