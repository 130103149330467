//import axios from "axios";
import axios from "./axiosInstance.js";


class PharmacyService {
    
  getCurrentPharmacie() {
    return axios.get("/pharmacies");
  }
  getPharmacy(pharmacyId){
    return axios.get(`/pharmacies/${pharmacyId}`);
  }

  savePharmacy(pharmacyId,pharmacy){
    return axios.post(`/pharmacies/${pharmacyId}`, pharmacy);
  }
  getDefaultSignature(pharmacyId){
    return axios.get(`/pharmacies/${pharmacyId}/defaultSignature`);
  }
}

export default PharmacyService = new PharmacyService() ; 
