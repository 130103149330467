import React, { useState, useEffect } from 'react';
import { useNavigate  } from 'react-router-dom';
import { InputAdornment,IconButton, Box, Button, CircularProgress, Grid, Paper, Typography,Alert,Divider } from "@mui/material";
import SaveIcon from '@mui/icons-material/Save';
import RegisterService from '../../services/register.service.js';
import UtilService from '../../services/util.service.js';
import EditableField from '../../components/field/editableField.component.js';
import { Dialog, DialogTitle, DialogActions,DialogContent  } from "@mui/material";
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { Visibility } from '@mui/icons-material';
import { VisibilityOff } from '@mui/icons-material';



function SignUp(props) {
  const [formData, setFormData] = useState({});
  const [isFormValid, setIsFormValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [dialogTitle, setDialogTitle] = useState("");
  const [dialogAlertContent, setDialogAlertContent] = useState("");
  const [dialogContent, setDialogContent] = useState("");
  const [dialogSeverity, setDialogSeverity] = useState("info");



  const { executeRecaptcha } = useGoogleReCaptcha();

  const entretienTransaparentLogoURL  = process.env.PUBLIC_URL + "/icons/entretien_logo.png";

  const handleChangeInfo = (event) => {
    let { name, value } = event.target;
    const updatedFormData = { ...formData, [name]: value }; 
    setFormData(updatedFormData);           
  };

  const navigate = useNavigate();
  const handleCloseDialog = () => {
    setDialogOpen(false);
    if(dialogSeverity ==="success"){
      const email = encodeURIComponent(formData.email);
      navigate(`/?email=${email}`); 
    }
  };
  
  const getErrorMsgValidatingRPPS = (rpps) =>{
    const rppsRegex = /^\d{11}$/;
    if (!rppsRegex.test(rpps)) {
      return 'le numéro RPPS doit contenir 11 chiffres uniquement';
    }
    return '';
  }

  useEffect(() => {
    const getErrorMsgValidatingConfirmPassword = (confirmPassword) =>{
        if (formData?.password && confirmPassword !== formData?.password) {
            return 'Les mots de passe ne correspondent pas';
        }
        return '';
      }

    if(!formData) return;
    const errorsMgs = UtilService.getErrorMsgValidatingName(formData.firstName)
    + UtilService.getErrorMsgValidatingName(formData.lastName)
    + UtilService.getErrorMsgValidatingEmail(formData.email)
    + UtilService.getErrorMsgValidatingPhoneNumber(formData.pharmacyPhoneNumber)
    + UtilService.getErrorMsgValidatingAddress(formData.pharmacyAddress)
    + UtilService.getErrorMsgValidatingPassword(formData.password)
    + getErrorMsgValidatingConfirmPassword(formData.confirmPassword)
    + getErrorMsgValidatingRPPS(formData.rpps)
    + UtilService.getErrorMsgValidatingPharmacyName(formData.pharmacyName)
    + UtilService.getErrorMsgValidatingEmail(formData.pharmacyEmail, true);
    const formValid = errorsMgs.length === 0;
    setIsFormValid(formValid);
  }, [formData]);

  const handleSaveClick = async () => {
    if(!isFormValid) return;
    setIsLoading(true);
    const recaptchaToken = await executeRecaptcha('signUp');
    formData.recaptchaResponse = recaptchaToken;
    RegisterService.register(formData)
      .then(() => {
        setDialogSeverity("success");
        setDialogTitle("Validation de votre adresse email : " + formData.email);
        setDialogAlertContent("Un email de confirmation de compte a été envoyé à l'adresse " + formData.email);
        setDialogContent("Cliquer sur le lien présent dans cet email pour finaliser la création du compte");
        setDialogOpen(true);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error)
        setDialogSeverity("error");
        setDialogTitle("Une erreur est survenue lors de l'enregistrement de " + formData.email);
        let errorContent = "";
        if (typeof error.response.data === "string") {
          errorContent = "Description de l'erreur : " + error.response.data;
        } else if (error.response.data.errors && Array.isArray(error.response.data.errors)) {
          errorContent = "Description de l'erreur : " + error.response.data.errors.join(", ");
        }
        setDialogAlertContent(errorContent);
        setDialogContent("Vous pouvez toujours nous contacter à l'adresse suivante: support@healthbees.fr ");
        setDialogOpen(true);
        setIsLoading(false);
      });
  };

  if (isLoading) {
    return <CircularProgress />;
  }

  return (
  
    <Box mx="auto" p={4} width="900px">
      <Paper variant="outlined">
        <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
        <Box
              component="img"
              sx={{
                maxWidth: 200,
                marginBottom: '40px',
                marginTop: '10px',

                display: { xs: 'none',sm: 'flex', md: 'flex', }
              }}
              src={entretienTransaparentLogoURL}
        />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2, marginTop:'40px' }}>
        <Typography variant="h3" align="center"  fontWeight='medium'>
                Formulaire D'inscription          
        </Typography>
        </Box>
      </Paper>
      <Paper variant="outlined">
        <Box p={2}>
          <Typography variant="h5" align="center">
            Informations sur le titulaire du compte
          </Typography>
          <Grid container spacing={2} mt={2}>
           {/* Add here the fields for the registration */}
                <Grid item xs={12} sm={6}>
                <EditableField
                    label="Prénom"
                    name="firstName"
                    value={formData?.firstName}
                    onChange={handleChangeInfo}
                    getErrorMsgValidatingFieldFct={(firstName) => UtilService.getErrorMsgValidatingName(firstName,"Prénom")}
                    isRequired
                />
                </Grid>
                <Grid item xs={12} sm={6}>
                <EditableField
                    label="Nom"
                    name="lastName"
                    value={formData?.lastName}
                    onChange={handleChangeInfo}
                    getErrorMsgValidatingFieldFct={(lastName) => UtilService.getErrorMsgValidatingName(lastName,"Nom")}
                    isRequired
                />
                </Grid>
                <Grid item xs={4}>
                <EditableField
                    label="Numéro RPPS du titulaire"
                    name="rpps"
                    value={formData?.rpps}
                    onChange={handleChangeInfo}
                    getErrorMsgValidatingFieldFct={getErrorMsgValidatingRPPS}
                    isRequired
                />
                </Grid>
                <Grid item xs={8}>
                <EditableField
                    label="Email de connexion au compte"
                    name="email"
                    value={formData?.email}
                    onChange={handleChangeInfo}
                    getErrorMsgValidatingFieldFct={UtilService.getErrorMsgValidatingEmail}
                    isRequired
                />
                </Grid>
                <Grid item xs={6}>
                <EditableField
                    label="Mot de passe"
                    name="password"
                    value={formData?.password}
                    type={ showPassword ? "text" : "password" }
                    getErrorMsgValidatingFieldFct={UtilService.getErrorMsgValidatingPassword}
                    onChange={handleChangeInfo}
                    isRequired
                    InputProps={{
                      endAdornment: 
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword(!showPassword)}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                  }}
                />
                </Grid>
                <Grid item xs={6}>
                <EditableField
                    label="Confirmation du mot de passe"
                    name="confirmPassword"
                    value={formData?.confirmPassword}
                    type={ showPassword ? "text" : "password" }
                    onChange={handleChangeInfo}
                    getErrorMsgValidatingFieldFct={(value) => {
                        if (formData?.password && value !== formData?.password) {
                            return 'Les mots de passe ne correspondent pas';
                        }
                        return '';
                        }}
                    isRequired
                    InputProps={{
                      endAdornment: 
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword(!showPassword)}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                  }}
                />
               </Grid>

          </Grid>
        </Box>
      </Paper>
      <Box mt={4} />
      <Paper variant="outlined">
        <Box p={2}>
          <Typography variant="h5" align="center">
            Informations de la pharmacie
          </Typography>
          <Grid container spacing={2} mt={2}>
           {/* Add here the fields for the pharmacy */}
            <Grid item xs={12}>
            <EditableField
                label="Nom de la pharmacie"
                name="pharmacyName"
                value={formData?.pharmacyName}
                onChange={handleChangeInfo}
                getErrorMsgValidatingFieldFct={UtilService.getErrorMsgValidatingPharmacyName}
                isRequired
            />
            </Grid>
            <Grid item xs={12}>
            <EditableField
                label="Adresse de la pharmacie"
                name="pharmacyAddress"
                value={formData?.pharmacyAddress}
                onChange={handleChangeInfo}
                getErrorMsgValidatingFieldFct={UtilService.getErrorMsgValidatingAddress}
                
            />
            </Grid>
            <Grid item xs={12}>
            <EditableField
                label="Numéro de téléphone"
                name="pharmacyPhoneNumber"
                value={formData?.pharmacyPhoneNumber}
                onChange={handleChangeInfo}
                getErrorMsgValidatingFieldFct={UtilService.getErrorMsgValidatingPhoneNumber}
                
            />
            </Grid>
            <Grid item xs={12}>
            <EditableField
                label="Email de contact de la pharmacie"
                name="pharmacyEmail"
                value={formData?.pharmacyEmail}
                onChange={handleChangeInfo}
                getErrorMsgValidatingFieldFct={(value) => UtilService.getErrorMsgValidatingEmail(value,true)}
                
            />
            </Grid>

          </Grid>
          <Button
            variant="outlined"
            color="primary"
            startIcon={<SaveIcon />}
            onClick={handleSaveClick}
            sx={{ mr: 1 }}
            disabled={!isFormValid}
          >
            S'inscrire
          </Button>
        </Box>
      </Paper>
      <Dialog
      open={isDialogOpen}
      onClose={handleCloseDialog}
    >
      <DialogTitle>{dialogTitle}</DialogTitle>
      <DialogContent>
        <Alert severity={dialogSeverity}>  
          <Typography>{dialogAlertContent}</Typography>
        </Alert>
        <Divider sx={{marginTop: "10px", marginBottom: "10px"}}/>
        <Typography>{dialogContent}</Typography>
        
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseDialog} color="primary">
          Ok
        </Button>
      </DialogActions>
</Dialog>

    </Box>
  );
 
}

export default SignUp;
