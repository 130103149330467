import React from 'react';
import PatientDetail from './patientDetail.component.js';
import PatientSidebar from './patientSidebar.component.js';
import { Box } from '@mui/material';


function PatientPage(props) {
  return (
    <Box sx={{ display: 'flex' }}>
      <PatientSidebar show />
      <PatientDetail sidebar={true} />
    </Box>
  );
}

export default PatientPage;