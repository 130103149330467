import React from 'react';
import { Navigate  } from 'react-router-dom';
import { connect } from 'react-redux';
import UtilService from '../../services/util.service.js';
import ReceiptIcon from '@mui/icons-material/Receipt';


import {
    Grid,
    Paper,
    Typography,
    Alert,
    AlertTitle,
    Box,
    Button,

  } from "@mui/material";

function BillingDetail(props) {


    const handleSave = () => {
        props.handleClose();
       props.handleBillAction(props.consultationGroup.id);
        
    };

    if (!props.user.accessToken) {
          return <Navigate replace to="/" />;
    } 
    else {
      return (
    <>
      <Paper variant="outline"  > 
       <Box p={1}> 
       <Grid container> 
            <Box p={1}/> 

            <Box p={1}/>
            <Grid item md={12} xs={12}><Typography align={'center'} p={1} paddingBottom={3} variant="h5"> Information de facturation</Typography></Grid>
            <Grid item md={6} xs={6}>
            <Typography variant="body1" align={'right'} pr={1} >
              Numéro de SS du patient : 
              </Typography>
            </Grid>
            <Grid item md={6} xs={6}>
            <Typography variant="body1" align={'left'} fontWeight={'bold'}>
              {props.patient.socialSecurityNumber &&(UtilService.formatFrenchSSN(props.patient.socialSecurityNumber)) }
            </Typography>
            </Grid>
            <Grid item md={6} xs={6}>
            <Typography variant="body1" align={'right'} pr={1} >
                Code de facturation : 
              </Typography>
            </Grid>
            <Grid item md={6} xs={6}>
            <Typography variant="body1" align={'left'} fontWeight={'bold'}>
              TAC
            </Typography>
            </Grid>
            <Grid item md={6} xs={6}>
            <Typography variant="body1" align={'right'} pr={1} >
                Date d'execution : 
              </Typography>
            </Grid>
            <Grid item md={6} xs={6}>
            <Typography variant="body1" align={'left'} fontWeight={'bold'}>
                {UtilService.todayDate_ddMMYYYY() } ( {UtilService.getFormattedTodayDate()} )
            </Typography>
            </Grid>
            <Grid item md={6} xs={6}>
            <Typography variant="body1" align={'right'} pr={1} >
                Zone prescripteur : 
              </Typography>
            </Grid>
            <Grid item md={6} xs={6}>
            <Typography variant="body1" align={'left'}>
            Votre numéro d’identification
            </Typography>
            </Grid>
            <Grid item md={6} xs={6}>
            <Typography variant="body1" align={'right'} pr={1} >
              Zone exécutant : 
              </Typography>
            </Grid>
            <Grid item md={6} xs={6}>
              <Typography variant="body1" align={'left'} >
              Votre numéro d’identification
              </Typography>
            </Grid>
            <Grid item md={6} xs={6}>
            <Typography variant="body1" align={'right'} pr={1} >
              Honoraires : 
              </Typography>
            </Grid>
            <Grid item md={6} xs={6}>
            <Typography variant="body1" align={'left'} fontWeight={'bold'}>
              0.01 €
            </Typography>
            </Grid>
            <Grid item md={12} xs={12}>
            <Box p={1}/>
            <Alert severity="info">  
                <AlertTitle> <Typography  variant="body1">Declaration du Code <strong>TAC</strong> (0.01 €): </Typography></AlertTitle>
                <Typography   variant="body1"> 
                Au démarrage de l’accompagnement, un code « adhésion » TAC  doit etre envoyé à la sécurité sociale (ce code est identique pour tous les thèmes mais doit être facturé à chaque adhésion d’un patient à un nouveau thème d'entretien ;
              </Typography>
            </Alert>
            </Grid>

        </Grid>
        </Box>
       </Paper>
              <Paper variant="outline" >  <Box p={1}> <Grid container>
              <Grid item md={12}>
                <Button
                
                sx={{ justifyContent: 'center'}}
                variant="contained"
                color="primary"
                startIcon={<ReceiptIcon/>}
                onClick={handleSave}
                >
                LE CODE TAC A ETE FACTURE SUR VOTRE LGO
                </Button>
              </Grid>
              </Grid></Box></Paper>
    </>
       );}

}
function mapStateToProps(state) {
    const user  = state.authReducer.user;
    return {
      user
    };
}

export default connect(mapStateToProps)(BillingDetail);