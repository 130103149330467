import React, { useState, useEffect } from 'react';
import { Navigate  } from 'react-router-dom';
import { connect } from 'react-redux';
import {
    Grid,
    Paper,
    Typography,
    TextField,
    Box,
    Button,
    FormHelperText,
    FormControl,
    Radio,
    RadioGroup,
    FormControlLabel,
  } from "@mui/material";


  import ConsultationService from '../../services/consultation.service.js'
  import Autocomplete,{ createFilterOptions } from '@mui/material/Autocomplete';


function TreatmentDetail(props) {

    const isChemoTreatmentDetail = props.treatmentDetailType && props.treatmentDetailType ==='CHEMO'
    const [medicamentName, setMedicamentName] = useState(props.treatment?.drug?.name ?? '');

    const [isMedicamentNameValid, setIsMedicamentNameValid] = useState(false);

    const [beginingDate, setBeginingDate] = useState(props.treatment?.beginingDate ?? new Date().toISOString().slice(0, 10)); // todays date by default
    const [isBeginingDateValid, setIsBeginingDateValid] = useState(false);

    const [endingDate, setEndingDate] = useState(props.treatment?.endingDate ?? '');
    const [isEndingDateValid, setIsEndingDateValid] = useState(false);

    const [morningDosage, setMorningDosage]   = useState(props.treatment?.dosage?.morningDosage ?? '');
    const [isMorningDosageValid, setIsMorningDosageValid] = useState(true);
    
    const [noonDosage, setNoonDosage]         = useState(props.treatment?.dosage?.noonDosage ?? '');
    const [isNoonDosageValid, setIsNoonDosageValid] = useState(true);

    const [eveningDosage, setEveningDosage]   = useState(props.treatment?.dosage?.eveningDosage ?? '');
    const [isEveningDosageValid, setIsEveningDosageValid] = useState(true);

    const [beforeSleepDosage, setBeforeSleepDosage]  = useState(props.treatment?.dosage?.beforeSleepDosage ?? '');
    const [isBeforeSleepDosageValid, setIsBeforeSleepDosageValid] = useState(true);
    const [autoCompleteMed, setAutoCompleteMed] = useState(props?.treatment?.drug ?? null);
    const [chemoTreatmentOptions, setChemoTreatmentOptions] = useState(null);
    const [autocompleteInputValue, setAutocompleteInputValue] = useState(null);
    const [autocompleteLoading, setAutocompleteLoading] = useState(false);


    const [comment, setComment]               = useState(props.treatment?.comment ?? '');
    const [origin, setOrigin]                 = useState(props.treatment?.origin ?? '');
    const [isFormValid, setIsFormValid] = useState(false);

    useEffect(() => {
      async function fetchChemoTreatments() {
         if(!isChemoTreatmentDetail|| !autocompleteInputValue) return;
         try {
            setAutocompleteLoading(true)
            const response = await ConsultationService.getChemoTreatments(autocompleteInputValue);
            setAutocompleteLoading(false)
            setChemoTreatmentOptions(response.data.content);
         } catch (error) {
          console.log('error await')
          console.log(error)

         }
      }
      fetchChemoTreatments();
   }, [isChemoTreatmentDetail,autocompleteInputValue]);


    useEffect(() => {
      function handleChange () {
        
        setIsMedicamentNameValid(isChemoTreatmentDetail ? isValidMedicamentName(autoCompleteMed?.name ?? '') : isValidMedicamentName(medicamentName));
        setIsMorningDosageValid(isValidDosage(morningDosage));
        setIsBeforeSleepDosageValid(isValidDosage(beforeSleepDosage));
        setIsEveningDosageValid(isValidDosage(eveningDosage));
        setIsNoonDosageValid(isValidDosage(noonDosage));
        
        checkTreatmentDatesValid(beginingDate,endingDate);
       
        
        setIsFormValid(
          isMedicamentNameValid && 
          isMorningDosageValid && 
          isBeforeSleepDosageValid && 
          isEveningDosageValid && 
          isNoonDosageValid &&
          isBeginingDateValid&&
          isEndingDateValid
        )
  
      }
        handleChange();
        // eslint-disable-next-line
      }, [autoCompleteMed, medicamentName,beginingDate,endingDate,morningDosage,noonDosage,eveningDosage,beforeSleepDosage,
        isMedicamentNameValid,isMorningDosageValid,isBeforeSleepDosageValid,isEveningDosageValid,isNoonDosageValid,isBeginingDateValid,isEndingDateValid]);


    function handleSave() {
       const drug  = isChemoTreatmentDetail ? autoCompleteMed : {name: medicamentName};
        const newTreatment = {
            drug ,
            dosage :{
                quantity:'',
                morningDosage,  
                noonDosage,
                eveningDosage,
                beforeSleepDosage,

            },
          beginingDate,
          endingDate,
          comment,
          origin
        };
        props.handleSave(newTreatment,props.index);
        props.closeDialog();
    }
    const handleMorningChange = (event) => {
        setMorningDosage(event.target.value);
    };
    const handleNoonChange = (event) => {
        setNoonDosage(event.target.value);
    };
    const handleEveningChange = (event) => {
        setEveningDosage(event.target.value);
    };
    const handleBeforeSleepChange = (event) => {
        setBeforeSleepDosage(event.target.value);
    };
    const handleMedicamentNameChange = (event) => {
      setMedicamentName(event.target.value);
    };
    const handleBeginingDateChange = (event) => {
        setBeginingDate(event.target.value);
      };
    const handleEndingDateChange = (event) => {
        setEndingDate(event.target.value);
    };
    const handleCommentChange = (event) => {
        setComment(event.target.value);
    };
    const handleOriginChange = (event) => {
        setOrigin(event.target.value);
    };
    
    const checkTreatmentDatesValid = (beginningDate, endingDate) => {
      if (!beginningDate){
        setIsBeginingDateValid(false);
        setIsEndingDateValid(true);
        return;
      }

      if(beginningDate && !endingDate){
        setIsBeginingDateValid(true);
        setIsEndingDateValid(true);
        return;
      }
      
        try {
          const beginningTimestamp = Date.parse(beginningDate);
          const endingTimestamp = Date.parse(endingDate);
      
          const endingDateisAfterBeginingDate = !isNaN(beginningTimestamp) && !isNaN(endingTimestamp) && beginningTimestamp < endingTimestamp;
          setIsBeginingDateValid(endingDateisAfterBeginingDate);
          setIsEndingDateValid(endingDateisAfterBeginingDate);
        } catch (error) {
          setIsBeginingDateValid(false);
          setIsEndingDateValid(false);
        }
      };
    
    const isValidMedicamentName = (name) => {
        if (!name) return false;
        return name.length >= 3;
    };

    const isValidDosage = (dosage) => {
        if(!dosage)return true;

        const dosageString = String(dosage);
        const dosageRegex = /^[0-9]+([.,]?[0-9]*)?$|[1-9]\/[2-9][0-9]?$|^0\.[0-9]+$/;
        return dosageString.match(dosageRegex) !== null && dosageString.match(dosageRegex)[0] === dosageString;
      
    };

    const filterOptions = createFilterOptions({
      limit: 100,
      stringify: (option) => option.name + ' ' + option.activeSubstance,
    });
    if (!props.user.accessToken) {
          return <Navigate replace to="/" />;
    } 
    else {
      return (
        <>
      <Paper variant="outline"  > 
       <Box p={1}> 
       <Grid container>
            <Grid item md={12} xs={12}><Typography variant="h5" sx={{ pb: 1 }}> Nom du médicament</Typography></Grid>

            <Grid item md={12} xs={12}>
            {!isChemoTreatmentDetail && (<>
              <TextField
                  label="Médicament"
                  required
                  value={medicamentName}
                  error={!isMedicamentNameValid}
                  color="success"
                  id="treatmentName"
                  name="treatmentName" 
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  onChange={handleMedicamentNameChange}
                  inputProps={{ maxLength: 250 }}
              />
              <FormHelperText id="treatmentName">250 caractères max</FormHelperText>
            </>)}
            {isChemoTreatmentDetail  && (<>
              <Autocomplete
              id="treatmentName"
              name="treatmentName"
              filterOptions={filterOptions}
              input={autocompleteInputValue ? autocompleteInputValue: autoCompleteMed?.name??null}
              value={autoCompleteMed}
              
              noOptionsText={(autocompleteInputValue && autocompleteInputValue.length>0)? 
              <span>Aucun médicament anticéreux trouvé avec '{autocompleteInputValue}', ajouter un autre traitement pour l'ajouter à cette entretien</span> :
              <span> veillez taper les premieres lettres du traitement, (substance active ou nom du médicament) </span> 
            }
              options={chemoTreatmentOptions || []}
              getOptionLabel={(option) =>  option?.name ?? ''}
              isOptionEqualToValue={(option, value) => value === undefined || option?.name?.toString() === (value?.name ?? value)?.toString()}
              onChange={(event, value) => {setAutoCompleteMed(value);
                                           setAutocompleteInputValue(null)}}
              loading={autocompleteLoading}
              onInputChange={(event, newInputValue) => {
                if(event?.type==='change') {
                  setAutocompleteInputValue(newInputValue);
                  setAutoCompleteMed(null)
                }
              }}
        
              sx={{ width: '100%'}}
              renderOption={(props, option, { selected }) => (
                <li {...props} >
                  <Grid  container  >
                  <Grid item xs={12} sm={6} lg={6} >
                        <Typography variant="text"  sx={{fontSize: '1.1em', marginRight: 2}}> {option.name} </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={6} >
                        <Typography variant="text" sx={{fontSize: '0.9em',marginX: 2}}  > substance active : </Typography>
                        <Typography variant="text"> {option.activeSubstance}</Typography>
                  
                        </Grid>
                  </Grid> 
                </li>
              )}
              renderInput={(params) => <>
                <TextField 
                    {...params} 
                    label="Traitement anticéreux par voix orale" 
                    error={!isMedicamentNameValid}
                    helperText={!isMedicamentNameValid ? "Veuillez selectionner le traitement anticéreux" : ""}
                    />
              
                </>
            } />
              </>)}
            </Grid>
       </Grid>
       </Box>
       </Paper> 
       <Paper variant="outline" >

       <Box pl={1}><Grid container>
         <Grid item md={12} xs={12}><Typography variant="h5"> Posologie</Typography></Grid>
         <Grid item md={1} >
         <FormControl  sx={{marginRight:"8px",maxWidth:"80px"}}>
                <TextField
                    label="matin"
                    value={morningDosage}
                    name="morning"
                    required={false}
                    error={!isMorningDosageValid}
                    color="success"
                    InputLabelProps={{ shrink: true }}
                    onChange={handleMorningChange}
                    fullWidth
                    size="small"
                    placeholder='Matin'
                    margin="normal"
                    variant="outlined"
                    />
            </FormControl>
         </Grid>
         <Grid item md={1} >
         <FormControl  sx={{marginRight:"8px",maxWidth:"80px"}}>
             <TextField
                    label="Midi"
                    value={noonDosage}
                    name="noon"
                    required={false}
                    color="success"
                    error={!isNoonDosageValid}
                    InputLabelProps={{ shrink: true }}
                    onChange={handleNoonChange}
                    fullWidth
                    size="small"
                    placeholder='Midi'
                    margin="normal"
                    variant="outlined"
                    />
            </FormControl>
         </Grid>
         <Grid item md={1} >
         <FormControl  sx={{marginRight:"8px",maxWidth:"80px"}}>
                <TextField
                    label="Soir"
                    value={eveningDosage}
                    name="evening"
                    error={!isEveningDosageValid}
                    required={false}
                    color="success"
                    InputLabelProps={{ shrink: true }}
                    onChange={handleEveningChange}
                    fullWidth
                    size="small"
                    placeholder='Soir'
                    margin="normal"
                    variant="outlined"
                    />
            </FormControl>
         </Grid>
         <Grid item md={2} >
         <FormControl  sx={{marginRight:"8px",maxWidth:"80px"}}>
                <TextField
                    label="Coucher"
                    value={beforeSleepDosage}
                    error={!isBeforeSleepDosageValid}
                    name="beforeSleep"
                    required={false}
                    color="success"
                    InputLabelProps={{ shrink: true }}
                    onChange={handleBeforeSleepChange}
                    fullWidth
                    size="small"
                    placeholder='Coucher'
                    margin="normal"
                    variant="outlined"
                    />
            </FormControl>
         </Grid>
       </Grid></Box>
        </Paper>
        
        <Paper variant="outline" >
        <Box p={1}> 
        <Grid container>
            <Grid item md={12} xs={12}><Typography variant="h5"> Durée de prise du traitement</Typography></Grid>
            <Grid item md={6}>
            <FormControl fullWidth  sx={{ paddingRight: "10px"}}>       
            <TextField
                    label="Date de Début"
                    type="date"
                    value={beginingDate}
                    name="beginingDate"
                    error={!isBeginingDateValid}
                    color="success"
                    required={true}
                    InputLabelProps={{ shrink: true }}
                    onChange={handleBeginingDateChange}
                    fullWidth
                    margin="normal"
                    variant="outlined"
            />
            </FormControl>
            <FormHelperText id="beginingDate">Date de début</FormHelperText>

            </Grid>
            <Grid item md={6}>
                <FormControl fullWidth sx={{ paddingLeft: "10px"}}>       
                <TextField
                        label="Date de Fin"
                        placeholder='test'
                        type="date"
                        id="endingDate"
                        value={endingDate}
                        error={!isEndingDateValid}
                        color="success"
                        name="endingDate"
                        InputLabelProps={{ shrink: true }}
                        onChange={handleEndingDateChange}
                        fullWidth
                        margin="normal"
                        variant="outlined"
                />
                <FormHelperText id="endingDate">Date de Fin</FormHelperText>
                </FormControl>
            </Grid>
        </Grid>
        </Box>
       </Paper> 

       <Paper variant="outline" >
        <Box p={1}> 
        <Grid container>
            <Grid item md={12} xs={12}><Typography variant="h5"> Commentaire(s)</Typography></Grid>

            <Grid item md={12}>
            <TextField
                id="comment"
                multiline
                fullWidth
                value={comment}
                onChange={handleCommentChange}
                minRows={2}
                maxRows={10}
                placeholder="Vos commentaires sur le traitement"
                variant="outlined"
                inputProps={{ maxLength: 250 }}
                />
                <FormHelperText id="comment">250 caractères max</FormHelperText>

            </Grid>
        </Grid>
        </Box>
       </Paper> 

       <Paper variant="outline" >
        <Box p={1}> 
        <Grid container>
            <Grid item md={12} xs={12}><Typography variant="h5"> Origine</Typography></Grid>

            <Grid item md={12}>
            <FormControl>
                <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={origin}
                    onChange={handleOriginChange}
                >
                    <FormControlLabel value="Prescripteur"  checked={origin === 'Prescripteur'} control={<Radio />} label="Prescripteur" />
                    <FormControlLabel value="Auto Médication" checked={origin === 'Auto Médication'} control={<Radio />} label="Auto Médication" />
                    <FormControlLabel value="Non renseigné" checked={origin === 'Non renseigné'} control={<Radio />} label="Non renseigné" />
                </RadioGroup>
                </FormControl>
            </Grid>
        </Grid>
        </Box>
       </Paper> 
       
       
       <Paper variant="outline" >  <Box p={1}> <Grid container>
          <Grid item md={2}>
            <Button
            variant="contained"
            color="primary"
            disabled={!isFormValid}
            onClick={handleSave}
            >
            Sauvegarder
            </Button>
          </Grid>
          </Grid></Box></Paper>

       </>
       );}

}
function mapStateToProps(state) {
    const user  = state.authReducer.user;
    return {
      user
    };
}

export default connect(mapStateToProps)(TreatmentDetail);