import React from 'react';
import ReactDOM from 'react-dom/client';
import { StyledEngineProvider } from '@mui/joy/styles';
import { Provider } from 'react-redux';
import { BrowserRouter } from "react-router-dom";
import App from './App';
import configureStore from './store';
import './index.css';


const store = configureStore();


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  //<React.StrictMode>
    <StyledEngineProvider injectFirst>
      <Provider store={store}>
       <BrowserRouter>
        <App />
      </BrowserRouter>
      </Provider>
    </StyledEngineProvider>
 // </React.StrictMode>
);


