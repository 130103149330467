import React, { useState, useEffect } from 'react';
import { Navigate,useNavigate  } from 'react-router-dom';
import { connect } from 'react-redux';
import {actionDisplayMessage} from "../../../actions/auth.action"
import {
    Grid,
    List,
    ListItemText,
    ListItem,
    Divider,
    Paper,
    Typography,
    TextField,
    Box,
    Button,
    CircularProgress, 
    FormHelperText,
    Stack,

  } from "@mui/material";
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import DoneIcon from '@mui/icons-material/Done';



import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import UtilService from '../../../services/util.service.js';
import HorizontalSelect from '../../../components/field/horizontalSelect.component.js';
import ConsultationService from '../../../services/consultation.service.js'
import ChemoMedicatioDoc from './chemoMedicationDoc.component';



function ChemoDailyLifeSideEffects(props) {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [chemoInterview, setChemoInterview] = useState(null);

  const patientId = props.patient? props.patient.id:null;
  
  const [patient, setPatient] = useState(null);
  const [isFormValid/*, setIsFormValid*/] = useState(true);

  const [sideEffectsFreeText, setSideEffectsFreeText] = useState('');

  const [difficultyThatNeedAssistance, setDifficultyThatNeedAssistance] = useState({});
  
  const [treatmentTrust, setTreatmentTrust] = useState(null);
  const [knowTermsRelatedToYourTreatment, setKnowTermsRelatedToYourTreatment] = useState(null);
  const [howDoYouLiveTheTreatmentFreeText,setHowDoYouLiveTheTreatmentFreeText] = useState('');

  const [patientComment, setPatientComment] = useState('');
  const [conclusion, setConclusion] = useState('');

  const [formData, setFormData] = useState(null);

  const initiazeFormData = (formData) => { 
    setIsLoading(false);
    setFormData(formData);
    setPatient(formData.patientRef);
    setKnowTermsRelatedToYourTreatment(formData?.knowTermsRelatedToYourTreatment ?? knowTermsRelatedToYourTreatment)
    setHowDoYouLiveTheTreatmentFreeText(formData?.howDoYouLiveTheTreatmentFreeText ?? howDoYouLiveTheTreatmentFreeText)
    setSideEffectsFreeText(formData?.sideEffectsFreeText ?? sideEffectsFreeText)
    setDifficultyThatNeedAssistance(formData?.difficultyThatNeedAssistance ?? difficultyThatNeedAssistance)
    setTreatmentTrust(formData?.treatmentTrust ?? treatmentTrust)
    setPatientComment(formData?.patientComment ?? patientComment)
    setConclusion(formData?.conclusion ?? conclusion)
  }


  useEffect(() => {
    setIsLoading(false);
    setChemoInterview(props.consultation);
    initiazeFormData(props.consultation);
     // eslint-disable-next-line
 }, [props.consultation]);

 const handleValidateClick= () => {
  setIsLoading(true);
  const successMessage = "L'entretien a été validé avec succès";

  ConsultationService.validateChemoDailyLifeSideEffectsConsultation(formData)
      .then(() => {
      setIsLoading(false);
      props.dispatch(actionDisplayMessage(successMessage,"success"));
      navigate(`/patient/${patientId}/chemo/`);
      })
      .catch((error) => {
      console.error(error);
      setIsLoading(false);
      props.dispatch(
        actionDisplayMessage(
          "Une erreur s'est produite lors de l'enregistrement des modifications",
          "error"));
      });
};
  
  const handleSaveClick = () => {
    setIsLoading(true);
    const successMessage = "Les modifications ont été enregistrées avec succès";

    ConsultationService.saveChemoDailyLifeSideEffectsConsultation(formData)
        .then(() => {
        setIsLoading(false);
        props.dispatch(actionDisplayMessage(successMessage,"success"));
        navigate(`/patient/${patientId}/chemo/`);
        })
        .catch((error) => {
        console.error(error);
        setIsLoading(false);
        props.dispatch(
          actionDisplayMessage(
            "Une erreur s'est produite lors de l'enregistrement des modifications",
            "error"));
        });
  };

  const handleDiscardClick = () => {
    setFormData(chemoInterview);
    initiazeFormData(chemoInterview)
  };

  useEffect(() => {
    const handlePatientCommentAndConclusionTreatmentAndSideEffectChange= () => {
    
      setFormData(prevFormData => ({
        ...prevFormData,
        treatmentTrust: treatmentTrust,
        howDoYouLiveTheTreatmentFreeText:howDoYouLiveTheTreatmentFreeText,
        knowTermsRelatedToYourTreatment:knowTermsRelatedToYourTreatment,
        patientComment: patientComment,
        sideEffectsFreeText:sideEffectsFreeText,
        conclusion:conclusion
      }));
    }
    handlePatientCommentAndConclusionTreatmentAndSideEffectChange();
  },[patientComment,conclusion,treatmentTrust,sideEffectsFreeText,
    howDoYouLiveTheTreatmentFreeText,knowTermsRelatedToYourTreatment]);

  useEffect(() => {
    const handleDifficultyThatNeedAssistanceChange= () => {
    
      setFormData(prevFormData => ({
        ...prevFormData,
        difficultyThatNeedAssistance: difficultyThatNeedAssistance
      }));
    }
    handleDifficultyThatNeedAssistanceChange();
  },[difficultyThatNeedAssistance]);

  useEffect(() => {
    if(props.debug) console.log(formData);
  },[formData,props.debug]);
  
  if (!props.user.accessToken) {
      return <Navigate replace to="/" />;
    } 
  if( isLoading) {
      return <CircularProgress />;
    }
  if(!patient || !formData){
   return  <CircularProgress />;
  }
  return (
    <Grid container justifyContent="center"  p={2} >
      <Grid item sm={8}  >
      <Paper variant="outlined">
        <Stack direction="row" mt={1} justifyContent={"right"}>
          <Button sx={{maxWidth:'300px'}} variant="outlined" startIcon={<CancelIcon />} onClick={handleDiscardClick}>
                Annuler les modifications
          </Button>
        </Stack>
        <Stack direction="row" mb={3} justifyContent={"center"}>
          <Typography variant="h3" >
                Entretien « Vie quotidienne et gestion des effets indésirables »
          </Typography>
        </Stack>
      </Paper>
      <Paper variant="outlined">
      <Box p={2} display="block">
        <Typography variant="h5" align="center">
          Informations personnelles
        </Typography>
        <Grid container spacing={2} mt={2}>
          <Grid item md={4}>
            <Typography variant="text" color="grey">{ patient.firstName} {patient && patient.lastName} </Typography>
          </Grid>
          <Grid item md={4}>
            <Typography variant="text" color="grey">Né le { UtilService.handleDateFormat(patient.birthday)} </Typography>
          </Grid>
          <Grid item md={4}>
          <Typography variant="text" color="grey">Numéro de SS:  {patient.socialSecurityNumber} </Typography>
          </Grid>
        </Grid>

      </Box>
    </Paper>
    <Box p={'2px'}/>
    <Paper variant="outlined">
      <Box p={2} display="block">
        <Typography pb={3} variant="h5" align="center">
        Effets indésirables       
        </Typography>
        <Grid container spacing={1}>
          <Grid item md={12}>
            <TextField
                id="sideEffectsFreeText"
                multiline
                fullWidth
                value={sideEffectsFreeText}
                onChange={(event) => setSideEffectsFreeText(event.target.value)}
                minRows={2}
                maxRows={10}
                placeholder="Le patient ressent-il des effets indésirables liés à son traitement?"
                variant="outlined"
                inputProps={{ maxLength: 300 }}
                />
                <FormHelperText id="comment">300 caractères max</FormHelperText>
          </Grid>
          
        </Grid>
      </Box>
    </Paper>
    <Box p={'2px'}/>

    <Paper variant="outlined">
      <Box p={2} display="block">
        <Typography pb={3} variant="h5" align="center">
        Identification des difficultés motrices/cognitives et sensorielles qui nécessitent une assistance         
        </Typography>
        <Grid container spacing={1}>
          <Grid item sm={7} md={7} lg={7}>
                  <Box sx={{ height: '100%', display: 'flex', flexDirection:'column' , justifyContent:'center'}}> 
                  <Typography variant="text">
                    Le patient ressent-il le besoin d'avoir une aide exterieure (psychologique, aide au quotidien) ?              
                  </Typography></Box>
          </Grid>
          <Grid pl={2} item sm={5} md={5}  lg={5} > 
                      <HorizontalSelect 
                        changeValue={(newValue) => {setDifficultyThatNeedAssistance({...difficultyThatNeedAssistance, doesPatientNeedForExternalHelp:newValue })}}
                        value={difficultyThatNeedAssistance?.doesPatientNeedForExternalHelp}/>
          </Grid>
          <Grid item md={12}>
            <TextField
                id="needForExternalHelpFreeText"
                multiline
                fullWidth
                value={difficultyThatNeedAssistance?.needForExternalHelpFreeText}
                onChange={(event) => 
                  setDifficultyThatNeedAssistance({...difficultyThatNeedAssistance, needForExternalHelpFreeText:event.target.value })}
                minRows={2}
                maxRows={10}
                placeholder="Si oui, veuillez preciser"
                variant="outlined"
                inputProps={{ maxLength: 300 }}
                />
                <FormHelperText id="comment">300 caractères max</FormHelperText>
          </Grid>
          
        </Grid>
      </Box>
    </Paper>
    <Box p={'2px'}/>


<Box p={'2px'}/>
<Paper  variant="outlined">
  <Box  p={2} display="block">
      <Typography pb={3} variant="h5" align="center">
          Evaluation de la connaissance dans le traitement et de la motivation à prendre le traitement
      </Typography>
      <Grid container spacing={1}>
      <Grid item sm={5} md={5} lg={5}>
                  <Box sx={{ height: '100%', display: 'flex', flexDirection:'column' , justifyContent:'center'}}> 
                  <Typography variant="text">
                  Le patient connaît-il  les modalités spécifiques à mettre en place  liées à son traitement  ?              
                  </Typography></Box>
          </Grid>
          <Grid pl={2} item sm={7} md={7}  lg={7} > 
                      <HorizontalSelect 
                        changeValue={(newValue) => {setKnowTermsRelatedToYourTreatment(newValue)}}
                        value={knowTermsRelatedToYourTreatment}/>
          </Grid>
          <Grid mt={3}ml={1}> 
          <Typography variant="text">
                 Comment le patient vit-il son traitement  ?              
           </Typography>
           </Grid>
          <Grid item md={12}>
            <TextField
                id="howDoYouLiveTheTreatmentFreeText"
                multiline
                fullWidth
                value={howDoYouLiveTheTreatmentFreeText}
                onChange={(event) => setHowDoYouLiveTheTreatmentFreeText(event.target.value)}
                minRows={2}
                maxRows={10}
                placeholder="Comment le patient vit-il son traitement  ?"
                variant="outlined"
                inputProps={{ maxLength: 300 }}
                />
                <FormHelperText id="comment">300 caractères max</FormHelperText>
          </Grid>
          <Grid item sm={12} md={12} lg={12}>
              <Box mt={1}  sx={{ height: '100%', display: 'flex', flexDirection:'column' , justifyContent:'center'}}> 
              <Typography mb={2} variant="text">
              Sur une échelle de 1 à 10, à quel point vous sentez-vous à l'aise avec la prise de votre traitement ?
              </Typography></Box>
          </Grid>
          <Grid item sm={2} md={2} > 
          <Box sx={{ height: '100%', display: 'flex', flexDirection:'column' , justifyContent:'center'}}> <Typography variant="text">
                1 = Très peu confiant
              </Typography></Box>
          </Grid>
          <Grid item sm={8} md={8} lg={8}>
            <HorizontalSelect 
                choices={[ '1', '2', '3', '4', '5', '6', '7', '8', '9', '10']}
                values ={[  1,   2,   3,   4,   5,   6,   7,    8,   9,  10 ]}
                changeValue={setTreatmentTrust}
                value={treatmentTrust}
                borderRadius={'30px'}/>
          </Grid>
          <Grid item sm={2} md={2} > 
          <Box sx={{ height: '100%', display: 'flex', flexDirection:'column' , justifyContent:'center'}}> <Typography variant="text">
                10 = Extrêmement confiant
            </Typography></Box>
          </Grid>
        </Grid>
  </Box>
</Paper>
<Box p={'2px'}/>
<Paper  variant="outlined">
  <Box  p={2} display="block">
      <Typography pb={3} variant="h5" align="center">
          Informations complémentaires demandées par le patient
      </Typography>
      <Grid container spacing={1}>
      <Grid item md={12}>
            <TextField
                id="patientComment"
                multiline
                fullWidth
                value={patientComment}
                onChange={(event) => setPatientComment(event.target.value)}
                minRows={2}
                maxRows={10}
                placeholder="Informations complémentaires demandées par le patient"
                variant="outlined"
                inputProps={{ maxLength: 250 }}
                />
                <FormHelperText id="comment">250 caractères max</FormHelperText>
            </Grid>
      </Grid>
  </Box>
</Paper>
<Box p={'2px'}/>

<Box p={'2px'}/>
<Paper  variant="outlined">
  <Box  p={2} display="block">
      <Typography pb={3} variant="h5" align="center">
    Vos conclusions     
    </Typography>
      <Grid container spacing={1}>
      <Grid item md={12}>
            <TextField
                id="conclusion"
                multiline
                fullWidth
                value={conclusion}
                onChange={(event) => setConclusion(event.target.value)}
                minRows={2}
                maxRows={10}
                placeholder="Vos conclusions sur l'entretien"
                variant="outlined"
                inputProps={{ maxLength: 250 }}
                />
                <FormHelperText id="comment">250 caractères max</FormHelperText>
            </Grid>
      </Grid>
  </Box>
</Paper>
<Box p={'2px'}/>
    {chemoInterview.status === 'DRAFT'?( <>
      <Button
        variant="contained"
        color="primary"
        startIcon={<DoneIcon />}
        onClick={handleValidateClick}
        sx={{ mr: 1 }}
        disabled={!isFormValid}
      >
        Valider l'entretien
      </Button>
      <Button
          variant="outlined"
          color="primary"
          startIcon={<SaveIcon />}
          onClick={handleSaveClick}
          sx={{ mr: 1 }}
          disabled={!isFormValid}
        >
          Enregistrer pour plus tard
        </Button></>
        ):(
          <Button
          variant="contained"
          color="primary"
          startIcon={<SaveIcon />}
          onClick={handleSaveClick}
          sx={{ mr: 1 }}
          disabled={!isFormValid}
        >
          Enregistrer
          </Button>
         )}
      <Box mt={4} />
    </Grid>
    
    <Grid item  sm={4}  paddingLeft={2} paddingRight={2} >  
       <Box  paddingBottom={0.5}><Paper variant="outlined">
            <Stack direction="row" align="center" justifyContent={'center'}  >
              <Typography variant='h3' align="center">Documentation</Typography>
            </Stack>
          </Paper></Box>
          <Paper variant="outlined" >
             <Stack direction="row"  alignItems="center" justifyContent={'center'} gap={1} >
              <NewReleasesIcon />
              <Typography variant='h5' align="center">Objectifs de l’entretien :</Typography>
             </Stack>

              <List>

              <ListItem alignItems="flex-start" >
                <ListItemText
                  primary="Évoquer avec le patient l’impact de ses traitements sur sa vie quotidienne (changement d’habitudes, impact sur son quotidien, problèmes rencontrés…)."   
                />            
              </ListItem>
              <Divider variant="inset" component="li" />
              <ListItem alignItems="flex-start">
                <ListItemText
                  primary="Aider le patient dans la gestion des éventuels effets indésirables rencontrés par voie orale"
                />
              </ListItem>
            </List>
          </Paper>
          <Paper variant="outlined" >
            <Stack direction="row"  alignItems="center" justifyContent={'center'} p={1} >
              <Typography variant='h4' align="center">Rappels à faire au patients</Typography>
             </Stack>
             <Stack  align="center" sx={{padding: 2}}  >

             <Typography variant='text' textAlign={"justify"}display={"block"} mb={1}>Il est important de rappeler au patient que toute variation importante de poids doit être signalée car elle peut nécessiter d’adapter la dose de chimiothérapie.</Typography>
             <Typography variant='text' textAlign={"justify"}display={"block"} mb={1}>Il convient d’évoquer avec le patient les problèmes qu’il peut rencontrer au quotidien :</Typography>

             <Typography variant='text' textAlign={"justify"} display={"block"} mb={0.5}>-Isolement vis-à-vis du monde médical lié à la prise de traitement ambulatoire par rapport à un traitement anticancéreux administré à l’hôpital.</Typography>

             <Typography variant='text' textAlign={"justify"}display={"block"} mb={0.5}>-Soutien psychologique,</Typography>

             <Typography variant='text' textAlign={"justify"}display={"block"} mb={1}>-Conseils en cas de voyage (contre-indication de certains vaccins…).</Typography>
             <Divider m={1}/>             
             <Typography variant='text' textAlign={"justify"}display={"block"} mb={1}mt={1}>Le patient peut être également mis en relation avec les services d’un réseau de santé proche de son domicile pour assurer la continuité des soins entre l’hôpital et la ville, si nécessaire.</Typography>
             <Divider m={1}/>
             <Typography variant='text' textAlign={"justify"}display={"block"} mb={1}>Les règles générales liées aux traitements anticancéreux oraux doivent également être rappelées :</Typography>

             <Typography variant='text' textAlign={"justify"}display={"block"} mb={0.5}>-Boire 2 litres d’eau par jour au moins,</Typography>

             <Typography variant='text' textAlign={"justify"}display={"block"} mb={0.5}>-Ne pas s’exposer au soleil,</Typography>

             <Typography variant='text' textAlign={"justify"}display={"block"} mb={0.5}>-Limiter sa consommation d’alcool,</Typography>

             <Typography variant='text' textAlign={"justify"}display={"block"} mb={0.5}>-Ne jamais prendre de médicaments / produits d’automédication sans consulter son médecin ou demander l’avis de son pharmacien.</Typography>

             <Typography variant='text' textAlign={"justify"}display={"block"} mb={1}>-Faire attention aux interactions avec l’alimentation.</Typography>
             <Divider m={1}/>
             <Typography variant='text' textAlign={"justify"}display={"block"} mb={1}>Ces règles doivent cependant être adaptées à chaque patient en lien avec ses comorbidités (concernant le volume hydrique quotidien notamment en cas de cancer du sein) et en lien avec son traitement anticancéreux et les EI associés.</Typography>
          </Stack>
          </Paper>
          <ChemoMedicatioDoc consultationGroupId={props.consultation.consultationGroupId} />

        
      </Grid>
    </Grid>
  );  
}

function mapStateToProps(state) {
    const user  = state.authReducer.user;
    return {
      user
    };
}
export default connect(mapStateToProps)(ChemoDailyLifeSideEffects);
