import {CovidTestingKit} from "../../types/types";
import React from "react";
import CovidTestSelector from "./CovidTestSelector";
import {Avatar, Box, Button, Card, CardContent, Stack, SvgIcon, Typography} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

interface NewMedicalTestProps {
    testName: (test: CovidTestingKit) => void;
    buttonLabel : string;
    logoUrl : string;
    handleNewTest : Function
}

const NewMedicalTest: React.FC<NewMedicalTestProps> = ({ testName,buttonLabel,logoUrl, handleNewTest,sx }) => {

    return(
        <Card  sx={sx}>
            <CardContent>
                <Stack
                    alignItems="flex-start"
                    direction="column"
                    justifyContent="space-between"
                    spacing={1}
                >
                    <Button onClick={handleNewTest}
                            variant="contained" color="primary" startIcon={<AddCircleOutlineIcon />}>
                        {buttonLabel}
                    </Button>
                    <Stack  width={'100%'} direction="row"  justifyContent="center" >
                    <Box
                        component="img"
                        sx={{
                            height: 64,
                        }}
                        alt="Your logo."
                        src={logoUrl}
                    />
                </Stack>

                </Stack>
            </CardContent>
        </Card>
    )


}

export default NewMedicalTest;