import * as React from 'react';
import { Box, Paper, Grid, Typography, Button } from '@mui/material';
import { Link } from "react-router-dom";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Chip } from '@mui/material';
import { ConsultationStatus } from "../../types/types.ts";
import UtilService from '../../services/util.service.js';

import ConsultationService from '../../services/consultation.service.js';
import PictureAsPdfSharpIcon from "@mui/icons-material/PictureAsPdfSharp";
import PreviewIcon from "@mui/icons-material/Preview";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";

interface ConsultationItemProps {
    consultation: any;
    onDelete: (id: string) => void;
    urlIdentifier: string;
    patientId: string;
}

const ConsultationItem: React.FC<ConsultationItemProps> = ({ consultation, onDelete, urlIdentifier, patientId }) => {
    const getStatusChip = (status: ConsultationStatus) => {
        switch (status) {
            case ConsultationStatus.DRAFT:
                return <Chip variant="outlined" color="warning" label={"Brouillon"} />;
            case ConsultationStatus.VALIDATED:
                return <Chip variant="outlined" color="info" label={"Validé"} />;
            case ConsultationStatus.BILLED:
                return <Chip variant="outlined" color="success" label={"Facturé"} />;
            default:
                return null;
        }
    };
    return (
        <Box p={1}>
            <Paper variant="outlined">
                <Grid container spacing={2} p={1}>
                    <Grid item md={7}>
                        <Grid container direction="column">
                            <Grid item><Typography variant='h5'>{ConsultationService.getConsultationNameFromConsultationType(consultation.type)}</Typography></Grid>
                            <Grid item><Typography color="grey" variant='h6'>
                                Créé le {UtilService.handleDateFormat(consultation.creationDate)} | Modifié le {UtilService.handleDateFormat(consultation.lastModifiedDate)}
                            </Typography></Grid>
                            <Grid item>{getStatusChip(consultation.status)}</Grid>
                        </Grid>
                    </Grid>
                    <Grid item md={2}>
                        <Button component={Link} to={`/patient/${patientId}/${urlIdentifier}/${consultation.id}`}
                                variant="contained" color="primary" startIcon={<EditIcon />}>
                            Modifier
                        </Button>
                    </Grid>
                    <Grid item md={2}>
                        {consultation.status !== 'BILLED' && (
                            <Button
                                variant="outlined"
                                color="primary"
                                startIcon={<DeleteIcon />}
                                onClick={() => onDelete(consultation.id)}
                            >
                                Supprimer
                            </Button>
                        )}
                    </Grid>
                </Grid>
                <Grid sx={{display:"none"}}item   m={1} md={12} >
                    <Grid container >
                        <Grid item md={4}>
                            <Typography p={2} variant='h6'> < PictureAsPdfSharpIcon/>Entretien réalisé le {UtilService.handleDateFormat(consultation.creationDate)}  </Typography>
                        </Grid>
                        <Grid item md={3}>
                        </Grid>
                        <Grid item md={2} m={1}>
                            <Button variant="outlined" color="primary" startIcon={<PreviewIcon />}> Apperçu </Button>

                        </Grid>
                        <Grid  item md={2} m={1}>
                            <Button  variant="outlined" color="primary" startIcon={<CloudDownloadIcon />}>Télécharger</Button>
                        </Grid>
                    </Grid>

                </Grid>
            </Paper>
        </Box>
    );
};

export default ConsultationItem;
