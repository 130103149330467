
//dirty hack  to have the full available, a better way should be implemented if the pharmacist really need it

import {CovidTestingKit} from "../../types/types";

export const medicalCovidTestList : CovidTestingKit[] = [
    { testName: "Kit de test rapide antigénique SARS-CoV-2", manufacturer: "Triplex International Bioscience" },
    { testName: "Rapid SARS-CoV-2 Antigen Test Card 07AG6020B", manufacturer: "MP BIOMEDICAL" },
    { testName: "2019-nCoV Antigen Test Kit (Colloidal Gold Immunochromatographic Assay)", manufacturer: "Guangzhou Tebsun Bio-Tech Development Co., LTD" },
    { testName: "2019-nCoV Antigen Test Kit (colloidal gold method) TEST ANTIGENIQUE DU 2019-nCoV", manufacturer: "Guangdong Hecin Scientific" },
    { testName: "2019-nCov Antigen Test (lateral flow method) W196", manufacturer: "Guangzhou Wondfo Biotech Co" },
    { testName: "AFIAS Covid -19 Ag", manufacturer: "Boditech Med" },
    { testName: "AMP SARS COV 2 Ag", manufacturer: "AMEDA Labordiagnostik" },
    { testName: "Antigène Coronavirus (SARS-CoV-2)", manufacturer: "Tody Laboratories" },
    { testName: "Aria COVID-19 Ag Rapid Test Cassette (Prélèvement nasopharyngé, prélèvement nasal) référence R0182 C", manufacturer: "CTK BIOTECH" },
    { testName: "BIOCREDIT COVID-19 Ag", manufacturer: "RapiGEN" },
    { testName: "BIOSYNEX® COVID-19 Ag + BSS", manufacturer: "BIOSYNEX Swiss" },
    { testName: "BIOSYNEX® COVID-19 Ag BSX", manufacturer: "BIOSYNEX Swiss" },
    { testName: "Biosynex Covid-19 Ag BSS", manufacturer: "Biosynex Swiss" },
    { testName: "CLINITEST® Rapid COVID-19 Antigen Test", manufacturer: "Healgen Scientific" },
    { testName: "CLUNGENE COVID-19 Antigen Rapide Test", manufacturer: "Hangzhou Clongene Biotech" },
    { testName: "COVID 19 Antigen Rapid Test (Nasopharyngeal Swab ICOV-502", manufacturer: "CITEST DIAGNOSTICS INC" },
    { testName: "COVID-19 Ag Color", manufacturer: "CERTEST BIOTEC" },
    { testName: "COVID-19 Antigen Rapid Test", manufacturer: "HANGZHOU ALLTEST BIOTECH" },
    { testName: "COVID-19 Antigen Rapid Test (Nasopharyngeal Swab)", manufacturer: "ACRO BIOTECH" },
    { testName: "COVID-19 Antigen rapid test", manufacturer: "Hangzhou Clongene Biotech" },
    { testName: "COVID-19 Antigen rapid test kit", manufacturer: "SAFECARE Biotech" },
    { testName: "COVID-19 Test Kit (Gold colloidal method)", manufacturer: "Hangzhou Singclean Medical Products" },
    { testName: "COVID-19 antigen rapid test", manufacturer: "Prima Lab" },
    { testName: "COVID-19-CHECK-1 Antigen", manufacturer: "VEDALAB" },
    { testName: "COVID-VIRO® Test Rapide Antigène COVID-19", manufacturer: "AAZ" },
    { testName: "COVIgen® Test antigénique de detection rapide du SARS-CoV-2 (COVID-19)", manufacturer: "Hangzhou Clongene Biotech" },
    { testName: "Cassette de test antigénique rapide COVID-19 (prélèvement salivaire/nasopharyngé)", manufacturer: "SureScreen Diagnostics" },
    { testName: "CerTest SARS-CoV-2 ONE STEP SARS-CoV-2 Ag CARD TEST", manufacturer: "CERTEST BIOTEC" },
    { testName: "Coronavirus Ag rapide test cassette (swab)", manufacturer: "ZHEJIANG ORIENT GENE BIOTECH" },
    { testName: "Covid -19 antigen rapid test", manufacturer: "Spartacus Biomed" },
    { testName: "Covid Antigen Test Kit", manufacturer: "Guangdong Wesail Biotech" },
    { testName: "EBS SARS-CoV-2 Ag Rapid Test", manufacturer: "EUROBIO Scientific" },
    { testName: "Epithod® SARS-CoV-2 qAg Test Kit", manufacturer: "DxGen" },
    { testName: "Flowflex Test rapide d'antigène du SARS-CoV-2", manufacturer: "ACON Biotech" },
    { testName: "Fluorecare® SARS-CoV-2 Antigen Test Kit (Colloidal Gold Chromatographic Immunoassay)", manufacturer: "Shenzhen Microprofit Biotech" },
    { testName: "GLINE-2019-nCoV Ag", manufacturer: "SHENZHEN YHLO BIOTECH CO" },
    { testName: "GenSureTM COVID-19 Antigen Rapid Test Kit", manufacturer: "GenSure Biotech Inc" },
    { testName: "HIGHTOP SARS-COV-2 ANTIGEN RAPID TEST", manufacturer: "QINGDAO HIGHTOP BIOTECH CO.,LTD" },
    { testName: "ID Rapid COVID-19 Antigen", manufacturer: "Innovative Diagnostics" },
    { testName: "INNOVA SARS-CoV-2 Antigen Rapid Qualitative Test", manufacturer: "Xiamen Biotime biotechnology" },
    { testName: "KCB COVID-19 Antigen Rapid Test Ref IVDACOV19-1025A", manufacturer: "Kappa City Biotech" },
    { testName: "KaiBiLiTM COVID-19 Antigen et KaiBiLiTM COVID-19 Antigen Neo", manufacturer: "Hangzhou Genesis Biodetection et Biocontrol" },
    { testName: "Kit De Détection D'antigène COVID-19 référence COVID-19-NG08", manufacturer: "New Gene (Hangzhou) Bioengineering" },
    { testName: "Kit de Test Rapide d’Antigènes (Méthode de l’or colloïdal) du SARS-CoV-2", manufacturer: "Zybio" },
    { testName: "Kit de détection de l'antigène du syndrome respiratoire aigu sévère du coronavirus 2 (SARS-CoV-2)", manufacturer: "Nanjing VAZYME medical technology" },
    { testName: "Ksmart® SARS-COV2 Antigen", manufacturer: "AVALUN" },
    { testName: "LumiraDx SARS-CoV-2 Antigen (Ag) Test", manufacturer: "LumiraDx UK Ltd" },
    { testName: "MEDSAN SARS COV 2 ANTIGEN RAPID TEST", manufacturer: "MEDsan GmbH" },
    { testName: "NADAL® COVID-19 Ag Test", manufacturer: "Nal von minden GmbH" },
    { testName: "NG Test SARS CoV 2 Ag", manufacturer: "NG BIOTECH" },
    { testName: "NINONASAL", manufacturer: "NG BIOTECH" },
    { testName: "Novel Coronavirus (COVID-19) Antigen Test Kit", manufacturer: "MEDAKIT" },
    { testName: "Novel Coronavirus (SARS CoV 2) Antigen Test Kit (colloïdal Gold)", manufacturer: "BN Santé" },
    { testName: "Novel Coronavirus Antigen Test Kit", manufacturer: "Hangzhou Laihe Biotech -LYHER" },
    { testName: "Onsite COVID-19 Ag Rapid Test Cassette référence R0182 C", manufacturer: "CTK BIOTECH" },
    { testName: "PCL COVID19 Rapid FIA", manufacturer: "PCL" },
    { testName: "PCL Covid -19 Ag Gold Saliva (avec pvt nasopharyngé)", manufacturer: "PCL" },
    { testName: "Panbio™ COVID-19 Ag Rapid Test Device", manufacturer: "Abbott Rapid Diagnostics Jena" },
    { testName: "QuickProfile™ COVID-19 Antigen Test", manufacturer: "LumiQuick Diagnostics" },
    { testName: "RAPIDTECH Test Rapide Antigène SARS-CoV-2", manufacturer: "Weifang Kanghua Biotech" },
    { testName: "RAPIDTECH Test Rapide Antigénique SARS-CoV-2", manufacturer: "Qingdao Hightop Biotech" },
    { testName: "Rapid Response COVID-19 Antigen Test Cassette", manufacturer: "BTNX" },
    { testName: "SARS-COV-2 Nucleocapsid (N) Antigen Rapid Cassette de test (écouvillon)", manufacturer: "LiClear Biotech" },
    { testName: "SARS-CoV-2 Ag IFA Watmind (immunofluorescence) avec analyseur", manufacturer: "Shenzhen Watmind Medical" },
    { testName: "SARS-CoV-2 Antigen Rapid Test Cassette", manufacturer: "Hangzhou Sejoy Electronics& Instruments Co.,Ltd." },
    { testName: "SARS-CoV-2 Antigen Rapid Test Kit", manufacturer: "Wuhan UNScience Biotechnology Co Ltd" },
    { testName: "SARS-CoV-2 Antigen Test Kit", manufacturer: "Shenzhen Ultra-Diagnostics Biotec" },
    { testName: "SARS-CoV-2 Antigen Test Kit (Colloidal Gold)", manufacturer: "Genrui Biotech Inc" },
    { testName: "SARS-CoV-2 Rapid Antigen Test", manufacturer: "SD BIOSENSOR" },
    { testName: "SOFIA SARS Ag FIA QUIDEL", manufacturer: "Quidel Corporation" },
    { testName: "STANDARD Q COVID-19 Ag test", manufacturer: "SD BIOSENSOR" },
    { testName: "Standard F COVID-19 Ag FIA", manufacturer: "SD BIOSENSOR" },
    { testName: "TEST antigénique rapide SARS-CoV-2", manufacturer: "Xiamen BOSON Biotech" },
    { testName: "TODA CORONADIAG Ag", manufacturer: "Toda Pharma" },
    { testName: "Test Antigénique SARS-CoV-2 (méthode flux latéral) ref W196", manufacturer: "Guanzhou WONDFO Biotech" },
    { testName: "Test Rapide COVID-19 Antigénique nasopharyngé Tigsun-1416", manufacturer: "Beijing Tigsun Diagnostics" },
    { testName: "Test antigénique rapide COVID-19 sur cassette cassette (avec écouvillon)", manufacturer: "ZHEJIANG ORIENT GENE BIOTECH" },
    { testName: "Test de détection rapide antigénique", manufacturer: "Healgen Scientific" },
    { testName: "Test en une étape pour Antigène SARS-CoV-2 (Or colloïdal) (prélèvement nasopharyngé ref CG20612", manufacturer: "Getein Biotech" },
    { testName: "Test rapide antigène COVID-19 (Écouvillon nasopharyngé) BERIGHT", manufacturer: "HANGZHOU ALLTEST BIOTECH" },
    { testName: "Test rapide d'antigène du SARS-CoV-2 (préfilled)", manufacturer: "ACON Biotech" },
    { testName: "Test rapide d’antigène COVID-19", manufacturer: "HANGZHOU ALLTEST BIOTECH" },
    { testName: "Test rapide d’antigènes de la COVID-19", manufacturer: "BIOMAXIMA" },
    { testName: "Tigsun COVID-19 ANTIGEN RAPID TEST Ref :TG-1416", manufacturer: "Beijing Tigsun Diagnostics" },
    { testName: "UNICELL-2019-nCoV Ag", manufacturer: "SHENZHEN YHLO BIOTECH CO" },
    { testName: "Vitassay SARS-CoV-2", manufacturer: "VITASSAY Healthcare SLU" },
    { testName: "Wondfo COVID-19 Test", manufacturer: "Guanzhou WONDFO Biotech" },
    { testName: "humasis COVID-19 Ag test Acova-7025", manufacturer: "HUMASIS" },
    { testName: "ichromaTM Covid 19 Ag", manufacturer: "Boditech Med" },
    { testName: "kit de test de diagnostic Ag SARS-CoV-2 (Or colloidal)", manufacturer: "Shenzhen Watmind Medical" },
    { testName: "kit de test rapide de détection antigénique du nouveau coronavirus", manufacturer: "Beijing Jinwofu Bioengineering" },
    { testName: "test rapide pour la détection d'antigènes du coronavirus SRAS-CoV-2", manufacturer: "Biospeedia" }
];
