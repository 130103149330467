import React, { useState, useEffect } from 'react';
import {Alert,AlertTitle, Stack,Paper,CircularProgress,Box, Typography,Button, Divider } from '@mui/material';
import { connect } from 'react-redux';
import { Navigate,useParams,useNavigate } from "react-router-dom";
import ReceiptIcon from '@mui/icons-material/Receipt';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import PatientService from '../../services/patient.service.js'
import {actionDisplayMessage} from "../../actions/auth.action.js"
import AddIcon from '@mui/icons-material/Add';
import ConsultationService from '../../services/consultation.service.js';
import utilService from '../../services/util.service.js';
import BillingHelpDialog from "../dialog/BillingHelpDialog.tsx";
import TACHelpDialog from "../dialog/TACHelpDialog.tsx";
import NoChemoConsultations from "../../scenes/consultation/chemo/NoChemoConsultations.tsx";
import NoPregnancyConsultations from "../../scenes/consultation/pregnancy/NoPregnancyConsultations.tsx";
import ConsultationItem from "./ConsultationItem.tsx";
import {ConsultationGroupType, ConsultationType} from "../../types/types.ts";


function GenericConsultationList(props) {
  const { patientId } = useParams();
  const [consultationGroups, setConsultationGroups] = useState([]);
  const [patient, setPatient] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [requireToLoadData, setRequireToLoadData] = useState(true);

  const [isBillingHelpDialogOpen, setIsBillingHelpDialogOpen] = useState(false);
  const [isTACHelpDialogOpen, setIsTACHelpDialogOpen] = useState(false);

  const navigate = useNavigate();
  const [displayCreateNewConsultationButton, setDisplayCreateNewConsultationButton] = useState(true);

  function lookForAValidConsultation(consultationGroups) {
    for (let consultationGroup of consultationGroups) {
      if (consultationGroup.consultations && consultationGroup.consultations.length > 0) {
        setDisplayCreateNewConsultationButton(false);
        return;
      }
    }
     setDisplayCreateNewConsultationButton(true);

  }

  const startNewConsultation = (consultationGroup,consultationType)=>{
    let startNewConsultationService =null
    if(consultationGroup.type === ConsultationGroupType.CANCER_TREATMENT){
      switch(consultationType){
      case ConsultationType.CHEMO_DAILY_LIFE_AND_SIDE_EFFECTS:
        startNewConsultationService = ConsultationService.addNewDailyLifeAndSideEffectsConsultationToGroup;
        break;
      case ConsultationType.CHEMO_OBSERVANCE:
        startNewConsultationService = ConsultationService.addNewObservanceConsultationToGroup;
        break;
      case ConsultationType.CHEMO_FIRST:
        startNewConsultationService = ConsultationService.addNewChemoFisrtConsultationToGroup;
        break;
      default:
        props.dispatch(actionDisplayMessage("Type d'entretien inconnu","error"));
        return;
      }
    }
    else{
      console.error("unsuported consultation group type "+ consultationGroup.type);
      props.dispatch(
          actionDisplayMessage("groupe d'entretien inconnu","error"));
      return;
    }
    setIsLoading(true);
    startNewConsultationService(consultationGroup.id)
      .then((data) => {
          setIsLoading(false);
          props.dispatch(actionDisplayMessage("Nouvel Entretien créé","success"));
          navigate(`/patient/${patientId}/${props.urlIdentifier}/${data.data.id}`);
        })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
        props.dispatch(
          actionDisplayMessage(
            "Une erreur s'est produite lors de la création de l'entretien",
            "error"));
        });
  }

  useEffect(() => {
    if(!requireToLoadData) return;
    props.getConsultationsByPatientId(patientId)
    .then(
    (data) => 
      {
        setIsLoading(false);
        if( data.data.content && data.data.content.length > 0){
          setConsultationGroups(data.data.content)
          lookForAValidConsultation(data.data.content)
          setRequireToLoadData(false); 
        }else{
          setConsultationGroups([])
          setDisplayCreateNewConsultationButton(true);
        }
      }
    )
    .catch(error => {
      setRequireToLoadData(false); 
      console.log(error)
    });

    PatientService.getPatient(patientId)
      .then(
       (data) => {
                  setIsLoading(false);
                  setPatient(data.data);
                 }
       )
       .catch(error => {
         console.log(error)
       })
       setRequireToLoadData(false); 
    // eslint-disable-next-line
  },[requireToLoadData])

  const handleDeleteConsultation = (consultationId) => {
    setIsLoading(true);
    props.deleteInterviewsById(consultationId)
    .then(() => {
        props.dispatch(actionDisplayMessage("Entretien supprimé","success"));
        setRequireToLoadData(true); 
      })
    .catch((error) => {
      console.error(error);
      setIsLoading(false);
      props.dispatch(
        actionDisplayMessage(
          "Une erreur s'est produite lors de la suppresion de l'entretien",
          "error"));
      });

  };

  const handlNewConsultation = () => {
    setIsLoading(true);
    props.startConsultationProcessWithPatientId(patientId)
      .then((data) => {
          setIsLoading(false);
          props.dispatch(actionDisplayMessage("Nouvel Entretien créé","success"));
          navigate(`/patient/${patientId}/${props.urlIdentifier}/${data.data.id}`);
        })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
        props.dispatch(
          actionDisplayMessage(
            "Une erreur s'est produite lors de la création de l'entretien",
            "error"));
        });

  }


  const handleBillAction = (consultationGroupId) => {
    props.billConsultationGroupById(consultationGroupId)
      .then((data) => {
          props.dispatch(actionDisplayMessage("Entretien facturé avec succès","success"));
          setRequireToLoadData(true); 
        })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
        props.dispatch(
          actionDisplayMessage(
            "Une erreur s'est produite lors de la facture de l'entretien",
            "error"));
        });
  }

  const handleBillTACAction = (consultationGroupId) => {
    props.billTACAction(consultationGroupId)
      .then((data) => {
          props.dispatch(actionDisplayMessage("Code TAC facturé avec succès","success"));
          setRequireToLoadData(true); 
        })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
        props.dispatch(
          actionDisplayMessage(
            "Une erreur s'est produite lors de la facturation du code TAC ",
            "error"));
        });
  }
if (!props.user.accessToken) {
    return <Navigate replace to="/" />;
} else if( isLoading) {
  return <CircularProgress />;
}
else {
  return (        
    <Box mx="auto" p={2} maxWidth="900px" width={'100%'} height={"100%"}
    sx={{ marginLeft: props.sidebar ? '250px':'0px' }}>
        {patient && displayCreateNewConsultationButton && (
            props.urlIdentifier === 'chemo' ?
                <NoChemoConsultations patient={patient} onStartNewConsultation={handlNewConsultation} /> :
                props.urlIdentifier === 'pregnancy' ?
                <NoPregnancyConsultations patient={patient} onStartNewConsultation={handlNewConsultation} /> :
                null
        )}

  {consultationGroups.map((consultationGroup,indexGroup) => (
    <Paper key={consultationGroups.id+''+indexGroup} variant="elevation" sx={{marginBottom: "20px"}}> 

     {consultationGroup.consultations.map((consultation, indexConsultation) => (
               <Box key={consultation.id+''+indexConsultation} p={1}>
                 <ConsultationItem
                        consultation={consultation}
                        onDelete={handleDeleteConsultation}
                        urlIdentifier={props.urlIdentifier}
                        patientId={patientId}
                    />
               </Box>
          ))}
           {!consultationGroup.billed && 
            consultationGroup.newConsultationTypes && 
            consultationGroup.newConsultationTypes.map((consultationType, indexConsultationType) => ( 
                <Box key={consultationGroup.id +indexConsultationType + consultationType} p={1}>
                    <Stack direction="row" justifyContent={"center"} spacing={2}>
                        <Button
                            onClick={() => startNewConsultation(consultationGroup, consultationType)}
                            variant="outlined" color="primary" startIcon={<AddIcon />}>
                            <Typography variant='text'>
                               {"Démarer L'entretien thematique :"} <strong>{ConsultationService.getConsultationNameFromConsultationType(consultationType)}</strong>
                            </Typography>
                        </Button>  
                    </Stack>
                </Box>))}

           {consultationGroup.shouldTAC && !consultationGroup.TACDate  ?
            <Box id={consultationGroup.id+"shouldTAC"} p={1}>
              <Divider >Code TAC</Divider> 
            <Alert severity="info">  
                <AlertTitle> <Typography  variant="h5">Veuillez facturer le code <strong>TAC</strong> (0,01 €) pour  {patient.firstName} {patient.lastName} </Typography></AlertTitle>
                <Typography   variant="body1"> 
                  Vous avez demarré un accompagnement pharmaceutique, afin d'optimiser les remboursements, veuillez déclarer au plus vite sur votre <strong>LGO</strong> le code <strong>TAC</strong>.
              </Typography>
              <Stack direction="row"  paddingTop={3} justifyContent={"center"} spacing={2}>
                <Button
                    onClick={() =>setIsTACHelpDialogOpen(true)}
                    variant="outlined" color="neutral" startIcon={<HelpCenterIcon />}>Aide à la Facturation du TAC</Button>
                <Button
                    onClick={() =>handleBillTACAction(consultationGroup.id)}
                    variant="contained" color="primary" startIcon={<ReceiptIcon />}>Le Code TAC a été facturé sur votre LGO</Button>
            </Stack>
            </Alert>
            
            </Box>
          : null}
                    
            
           {consultationGroup.billable && !consultationGroup.billed  ?

            <Box id={consultationGroup.id+"billable"} p={1}>
              <Divider >Facturation</Divider>
              <Alert severity="success">  
                <AlertTitle> <Typography  variant="h5">
                  Veuillez facturer l'accompagnement (code <strong>{consultationGroup.billingCode}</strong>)  sur votre LGO pour vous faire rembourser <strong>{consultationGroup.price && (parseFloat(consultationGroup.price)).toFixed(2) } euros net</strong>.
                </Typography></AlertTitle>
              
              {consultationGroup.billingCode !=='EFE' ?
              <Typography   variant="body1"> 
                <strong>Vous avez terminé et validé tous les entretiens necessaires à la facturation de votre acte d'acompagnement.  </strong>          
              </Typography>
              :null}
                <Stack direction="row" paddingTop={3}  justifyContent={"center"} spacing={2}>
                <Button
                    onClick={() =>setIsBillingHelpDialogOpen(true)}
                    variant="outlined" color="neutral" startIcon={<HelpCenterIcon />}>Aide à la Facturation</Button>
                  <Button
                    onClick={() =>handleBillAction(consultationGroup.id)}
                    variant="contained" color="primary" startIcon={<ReceiptIcon />}>L'entretien a été facturé sur le LGO</Button>
                  
                </Stack>
              </Alert>
            </Box>
          : null}

        {consultationGroup.billed  ? 
        <>
        <Divider>Detail Facturation</Divider>
         <Box id={consultationGroup.id+"billedInfo"} p={1}>  
         <Alert  severity="success">  
           <AlertTitle> <Typography  variant="h6">
             Vous avez facturé cet acte (code <strong>{consultationGroup.billingCode}</strong>) le 
             <strong> {utilService.handleDateFormat(consultationGroup.billingDate)}</strong> et 
             il vous a rapporté <strong>{consultationGroup.price && (parseFloat(consultationGroup.price)).toFixed(2) } euros net</strong>.
           </Typography></AlertTitle>
         </Alert>
       </Box>
       </>
        :null}

        <BillingHelpDialog
            open={isBillingHelpDialogOpen}
            onClose={() => setIsBillingHelpDialogOpen(false)}
            consultationGroup={consultationGroup}
            patient={patient}
            handleBillAction={handleBillAction}
        />

        <TACHelpDialog
            open={isTACHelpDialogOpen}
            onClose={() => setIsTACHelpDialogOpen(false)}
            consultationGroup={consultationGroup} // Set this based on user interaction
            patient={patient} // Set this similarly
            handleBillAction={handleBillTACAction}
        />

          </Paper>

          ))}
      
    </Box>
    )
}
};

function mapStateToProps(state) {
  const user  = state.authReducer.user;
  return {
    user
  };
}


export default connect(mapStateToProps)(GenericConsultationList);