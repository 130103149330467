import React from 'react';
import { Box } from '@mui/material';
import ConsultationService,{CHEMO_URL_IDENTIFIER} from '../../services/consultation.service.js'


import GenericConsultationList from "../generic/genericConsultationList.component.js"


function ChemoConsultationList(props) {


  return (
    <Box  width={'100%'} height={"100%"}>
      <GenericConsultationList 
        {...props}
        getConsultationsByPatientId={ConsultationService.getAllChemoConsultationGroupsFromPatientId}
        deleteInterviewsById={ConsultationService.deleteChemoConsultationById}
        startConsultationProcessWithPatientId={ConsultationService.startChemoConsultationForPatient}
        billConsultationGroupById={ConsultationService.billConsultationGroupById}
        billTACAction={ConsultationService.billTACForChemoConsultationGroupById}
        urlIdentifier={CHEMO_URL_IDENTIFIER}
      />
    </Box>
  );

}

export default ChemoConsultationList;