import React, { useState, useEffect } from 'react';
import {
    Grid,
    Paper,
    Typography,
    TextField,
    Box,
    Button,
    FormHelperText,
    FormControlLabel,
    FormGroup,
    Checkbox
  } from "@mui/material";
  import EmailIcon from '@mui/icons-material/Email';
  import SaveIcon from '@mui/icons-material/Save';

  import {UtilService}  from '../../services/util.service';


function EmailConfirmation(props) {

    const [emailAddress, setEmailAddress] = useState(props?.emailAddress ?? '');
    const [emailContent, setEmailContent] = useState(props?.emailContent ?? '');
    const [pharmacyEmailInCC, setPharmacyEmailInCC] = useState(props.hasPharmacyEmailInCC ?? false);

    const displayCheckboxForPharmacyEmailInCC = props.pharmacyEmail && UtilService.isValidEmail(props.pharmacyEmail);
    const [isFormValid, setIsFormValid] = useState(false);

    useEffect(() => {
      if(!displayCheckboxForPharmacyEmailInCC){ setPharmacyEmailInCC(false)}
      },[displayCheckboxForPharmacyEmailInCC]);

    useEffect(() => {
      function handleChange () {
        const isEmailDataValid =
               UtilService.isValidEmail(emailAddress) &&
              isValidEmailContent(emailContent);
        setIsFormValid(isEmailDataValid);
        if(isEmailDataValid){
          props.setValidEmailData({emailAddress,emailContent,pharmacyEmailInCC});
        }      
      }
      handleChange();
      }, [emailAddress,emailContent,pharmacyEmailInCC,props]);


    function isValidEmailContent(emailContent) {
      return emailContent.length < 20000 && emailContent.length !== 0;
    }

    function handleSend() {
        props.closeDialog();
        props.handleSendEmail();
    }

    function handleSaveAsDraft() {
      props.closeDialog();
      props.handleSaveAsDraft();
  }
    

    const handlePharmacyEmailInCC = (event) => {
      setPharmacyEmailInCC(event.target.checked);
    };
    const handleEmailAddressChange = (event) => {
        setEmailAddress(event.target.value);
    };
    const handleEmailContentChange = (event) => {
      setEmailContent(event.target.value);
  };
  return (
        <>
      <Paper variant="outline"  > 
       <Box p={1}> 
       <Grid container>
            <Grid item md={12} xs={12}><Typography variant="h5"> Adresse email de la Patiente</Typography></Grid>

            <Grid item md={12} xs={12}>
            <TextField
                label="Adresse Email"
                required
                value={emailAddress}
                error={!UtilService.isValidEmail(emailAddress)}
                color="success"
                id="emailAddress"
                name="emailAddress" 
                fullWidth
                margin="normal"
                variant="outlined"
                onChange={handleEmailAddressChange}
                inputProps={{ maxLength: 250 }}
            />            
            </Grid>
            {displayCheckboxForPharmacyEmailInCC &&
            <FormGroup>
                <FormControlLabel 
                  control={<Checkbox 
                            checked={pharmacyEmailInCC} 
                            onChange={handlePharmacyEmailInCC}/>} 
                  label={"Rajouter l'adresse email de la pharmacie ("+props.pharmacyEmail +") en CC du mail"}
                />
            </FormGroup>
            }

       </Grid>
       </Box>
       </Paper> 
       <Paper variant="outline" >
       <Grid container>
          <Grid item md={12} xs={12}><Typography variant="h5"> Contenu du mail</Typography></Grid>
          <Grid item md={12} xs={12}>
            <TextField
                id="emailContent"
                multiline
                fullWidth
                error={!isValidEmailContent(emailContent)}
                value={emailContent}
                onChange={handleEmailContentChange}
                minRows={10}
                maxRows={30}
                placeholder="Le mail envoyé à la patiente"
                variant="outlined"
                inputProps={{ maxLength: 2000}}
                />
                <FormHelperText id="comment">2000 caractères max</FormHelperText>
          </Grid>
        </Grid>
       </Paper>
       
       
       <Paper variant="outline" >  <Box p={1}> <Grid container>
          <Grid item  md={6} >
            <Button
            sx={{ mr: 1 ,mb:1}}

            variant="contained"
            color="primary"
            disabled={!isFormValid}
            onClick={handleSend}
            startIcon={<EmailIcon />}
            > Envoyer pour valider l'entretien      </Button>

            <Button
            sx={{mb:1}}
            variant="outlined"
            color="primary"
            disabled={!isFormValid}
            startIcon={<SaveIcon />}
            onClick={handleSaveAsDraft}
            
            >
            Enregistrer pour plus tard
            </Button>
          </Grid>
          </Grid></Box></Paper>

       </>
       );
}



export default EmailConfirmation;