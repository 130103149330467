import React, { useState, useEffect } from 'react';
import {  useNavigate,useParams } from 'react-router-dom';
import { Box, Button, CircularProgress, Paper, Typography,Alert } from "@mui/material";

import UserService from "../../services/user.service.js"



function SubscriptionSuccess() {
  const { userId } = useParams();
  const [displaySuccesMessage, setDisplaySuccesMessage] = useState(false);
  const [displayFailuresMessage, setDisplayFailureMessage] = useState(false);

  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();
  const entretienTransaparentLogoURL  = process.env.PUBLIC_URL + "/icons/entretien_logo.png";
  
  if(!userId) displayFailuresMessage("lien incorrect");

  const delay = ms => new Promise(res => setTimeout(res, ms));

  useEffect( () => {
    async function fetchUserData() {
      if(!userId) return;
      for(let i = 0; i < 5 ; ++i){
        await delay(1000);
        UserService.getUser(userId)
          .then((response) => {
            if(response.data.subscriptionStatus === "VALID"){
              setDisplaySuccesMessage("Votre achat est confirmé ");
              setIsLoading(false)
              return;
            }
          })
          .catch((error) => {
            setDisplayFailureMessage("Erreur dans la recupération des informations de votre compte, veuillez contacter le support");
            setIsLoading(false)
            return;
          });
      }
    }
    fetchUserData();
  }, [userId]);

 
  if (isLoading) {
    return <CircularProgress />;
  }

  return (
    <Box mx="auto" p={4} width="900px">
      <Paper variant="outlined">
        <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
        <Box
              component="img"
              sx={{
                maxWidth: 200,
                marginBottom: '40px',
                marginTop: '10px',

                display: { xs: 'none',sm: 'flex', md: 'flex', }
              }}
              src={entretienTransaparentLogoURL}
        />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2, marginTop:'40px' }}>
    {displaySuccesMessage && (
        <Alert severity="success">  
          <Typography variant="h5" align="center"  fontWeight='medium'>
                  {displaySuccesMessage}       
          </Typography>
        
        <Button
            variant="outlined"
            color="primary"
            onClick={(event) => { navigate(`/patient`);}}
            sx={{ mr: 1 }}
          >
            Cliquez ici pour creer de nouveaux patients !
          </Button>
          </Alert>
        )}
    {displayFailuresMessage && (
          <Alert severity="error">  
          <Typography variant="h5" align="center"  fontWeight='medium'>
              {displayFailuresMessage }   
          </Typography>
        </Alert> 
        )}             
        </Box>
      </Paper>
      
  
    </Box>
  );
}

export default SubscriptionSuccess;
