import React, { useState, useEffect } from 'react';
import { useLocation ,useNavigate } from 'react-router-dom';
import { Box, Button, CircularProgress, Paper, Typography,Alert } from "@mui/material";
import RegisterService from '../../services/register.service.js';
import {actionDisplayMessage} from "../../actions/auth.action.js"



function VerifyEmail() {
  const location = useLocation();
  const [tokenId, setTokenId] = useState(null);
  const [displaySuccesMessage, setDisplaySuccesMessage] = useState(false);
  const [displayFailuresMessage, setDisplayFailureMessage] = useState(false);
  const [email, setEmail] = useState("");

  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();
  const entretienTransaparentLogoURL  = process.env.PUBLIC_URL + "/icons/entretien_logo.png";


  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const token = params.get('verificationToken');
    if (token) {
      setTokenId(token)
    }
    else{
      setDisplayFailureMessage(true);
      setIsLoading(false);
    }
  }, [location]);

 

  useEffect(() => {
    if(!tokenId || displayFailuresMessage ) return;
    RegisterService.verify(tokenId)
    .then((response) => {
      setIsLoading(false);
      setDisplaySuccesMessage(true);
      setEmail(response.data.email);
    })
    .catch((error) => {
      console.error(error)
      setDisplayFailureMessage(true);
      actionDisplayMessage(
        "Une erreur s'est produite lors de la validation de l'email\n"+error,
        "error");
      setIsLoading(false);
    // eslint-disable-next-line
    })  }, [tokenId]);



  

  if (isLoading) {
    return <CircularProgress />;
  }

  return (
    <Box mx="auto" p={4} width="900px">
      <Paper variant="outlined">
        <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
        <Box
              component="img"
              sx={{
                maxWidth: 200,
                marginBottom: '40px',
                marginTop: '10px',

                display: { xs: 'none',sm: 'flex', md: 'flex', }
              }}
              src={entretienTransaparentLogoURL}
        />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2, marginTop:'40px' }}>
    {displaySuccesMessage && (
        <Alert severity="success">  
          <Typography variant="h5" align="center"  fontWeight='medium'>
                  Votre email a été validé        
          </Typography>
        
        <Button
            variant="outlined"
            color="primary"
            onClick={() => { navigate(`/?email=${encodeURIComponent(email)}`);}}
            sx={{ mr: 1 }}>
            Cliquez ici pour vous connecter !
          </Button>
          </Alert>
        )}
    {displayFailuresMessage && (
          <Alert severity="error">  
          <Typography variant="h5" align="center"  fontWeight='medium'>
                 Lien d'activation invalide ou expiré        
          </Typography>
        </Alert> 
        )}             
        </Box>
      </Paper>
      
  
    </Box>
  );
}

export default VerifyEmail;
