import React from 'react';
import { connect } from 'react-redux';

import { Routes, Route,useLocation } from "react-router-dom";
import Login from "./scenes/login/login.component.js"
import DashBoard from "./scenes/dashboard/dashboard.component.js"
import PatientSearch from "./scenes/patient/patientSearch.component.js"
import {actionClearDisplayMessage} from "./actions/auth.action"


import { CssBaseline, ThemeProvider,Box,Snackbar,Alert } from "@mui/material";
import { useTheme } from "./theme";

import MyAppBar from "./scenes/global/appbar.component"
import PatientDetail from './scenes/patient/patientDetail.component.js';

import ChemoPage from './scenes/consultation/chemo/chemoPage.component.js';
import { PREGNANCY_URL_IDENTIFIER, CHEMO_URL_IDENTIFIER } from './services/consultation.service.js';
import { MEDICAL_TEST_URL_IDENTIFIER } from './services/MedicalTestService.ts';

import PregnancyPage from './scenes/consultation/pregnancy/pregnancyPage.component.js';

import MyAccount from './scenes/account/myAccount.component.js'
import SignUp from './scenes/account/signUp.component.js'
import VerifyEmail from './scenes/account/verifyEmail.component.js'
import SubscriptionSuccess from './scenes/account/subscriptionSuccess.component.js'

import PatientPage from './scenes/patient/patientPage.component.js';
import UtilService from './services/util.service.js';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import EnvironmentService from './services/environment.service.js'
import ConsultationDetail from './scenes/consultation/consultationDetail.component.js';
import MedicalTestPage from "./scenes/medicalTest/MedicalTestPage.tsx";
import MedicalTestDetail from "./scenes/medicalTest/MedicalTestDetail.tsx";
import MedicalTestSearch from "./scenes/medicalTest/search/MedicalTestSearch.tsx";

function App(props) {

  const [currentUser] = React.useState({email :null,
                                      roles : [],
                                      token: undefined,
                                      refreshToken: null});


  const handleSnackbarClose = () => {
    props.dispatch(actionClearDisplayMessage());
  };
  const isNavbarDisplay = UtilService.isNavbarDisplay(useLocation());
  return (

    <ThemeProvider theme={useTheme()}>
    <CssBaseline />
    {  isNavbarDisplay && <MyAppBar/>}

    <Box  component="main" className="App" width={"100%"} height={"100%"} sx={{
                    display: 'flex', 
                    height: '100%', 
                      backgroundColor: '#dbdce0',
                      paddingTop: isNavbarDisplay? '64px':'inherit'
           
        }} >
         
       

        <Routes>
          <Route exact path="/" element={
                <GoogleReCaptchaProvider reCaptchaKey={EnvironmentService.getReCaptchav3SiteKey()}>
                  <Login/> 
                </GoogleReCaptchaProvider>
              }/>
                  
          <Route exact path="/dashboard" element={<DashBoard currentUser={currentUser}/>} />
          <Route path="/account" element={ <MyAccount /> } />
          
          <Route path="/signup" element={ 
                <GoogleReCaptchaProvider reCaptchaKey={EnvironmentService.getReCaptchav3SiteKey()}>
                  <SignUp />
                </GoogleReCaptchaProvider> } />
           <Route path="/success/:userId" element={<SubscriptionSuccess /> }  />
          <Route path="/verify" element={ <VerifyEmail /> } />


          <Route exact path="/medical_test" element={<MedicalTestSearch/> } />
          <Route exact path="/patient" element={<PatientSearch/> } />
          <Route path="/patient/:patientId" element={<PatientPage/>} />
          <Route path="/patient/new" element={ <PatientDetail mode="create" /> } />
          
          <Route path="/patient/:patientId/pregnancy" element={<PregnancyPage />} /> 
          <Route path="/patient/:patientId/pregnancy/:consultationId" element={<ConsultationDetail urlIdentifyer={PREGNANCY_URL_IDENTIFIER}/>}/>

          <Route path="/patient/:patientId/chemo" element={<ChemoPage />} />
          <Route path="/patient/:patientId/chemo/:consultationId" element={<ConsultationDetail urlIdentifyer={CHEMO_URL_IDENTIFIER}/>} />

          <Route path={`/patient/:patientId/${MEDICAL_TEST_URL_IDENTIFIER}`} element={<MedicalTestPage />} />
          <Route path={`/patient/:patientId/${MEDICAL_TEST_URL_IDENTIFIER}/:testId`} element={<MedicalTestDetail />} />

        </Routes>
    </Box> 
      
      <Snackbar
        open={props.messageText && props.messageText.length > 0}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        >
        <Alert onClose={handleSnackbarClose} severity={props.severity} sx={{ width: '100%' }}>
            {props.messageText}
        </Alert>
     </Snackbar>
     </ThemeProvider>

  );
}

function mapStateToProps(state) {
  const user  = state.authReducer.user;
  const messageText = state.authReducer.messageText
  const severity= state.authReducer.severity
  return {
    user,
    messageText,
    severity
  };
}
export default connect(mapStateToProps)(App);
