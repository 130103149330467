import React, { useState, useEffect } from 'react';
import { useParams,Navigate,useNavigate  } from 'react-router-dom';
import { connect } from 'react-redux';
import {
    Grid,
    Paper,
    Typography,
    Box,
    Button,
    CircularProgress,
  } from "@mui/material";
import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Cancel';
import {actionDisplayMessage} from "../../actions/auth.action"
import EditableField from '../../components/field/editableField.component.js';
import PatientService from '../../services/patient.service.js'
import utilService,{validFrenchSsnRegEx,spacingCharRegEx} from '../../services/util.service.js';


function PatientDetail(props) {
  const { patientId } = useParams();
  const [patient, setPatient] = useState(null);
  const isCreateMode = props.mode === "create";
  const [isFormValid, setIsFormValid] = useState(false);


  const [isEditMode, setIsEditMode] = useState(isCreateMode);
  const [formData, setFormData] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();


  const handleEditClick = () => {
    setIsEditMode(true);
  };
  
  const handleSaveClick = () => {
    
    const saveAction = isCreateMode ? PatientService.createPatient : PatientService.savePatient;
    const successMessage = isCreateMode ? 
    "Patient créé avec Succès":
    "Les modifications ont été enregistrées avec succès"
    setIsLoading(true);
    const cleanedFormData = {
      ...formData,
      socialSecurityNumber: formData.socialSecurityNumber.replace(/\s+/g, ''),
    };

    saveAction(cleanedFormData)
        .then((response) => {
        setIsLoading(false);
        setIsEditMode(false);
        props.dispatch(actionDisplayMessage(successMessage,"success"));
        if(isCreateMode){
          navigate(`/patient/${response.data.id}/`);
        }
        })
        .catch((error) => {
        console.error(error);
        setIsLoading(false);
        props.dispatch(actionDisplayMessage("Une erreur s'est produite lors de l'enregistrement des modification","error"));

        });
  };

  const handleDiscardClick = () => {
    setFormData(patient);
    setIsEditMode(false);
  };


  const handleChange = (event) => {
    let { name, value } = event.target;

    if (name === 'socialSecurityNumber') {
        value = utilService.formatFrenchSSN(value);
    }
    const updatedFormData = { ...formData, [name]: value }; 

    setFormData(updatedFormData); 
    
    const errorsMgs = utilService.getErrorMsgValidatingName(updatedFormData.firstName)
    + utilService.getErrorMsgValidatingName(updatedFormData.lastName)
    + utilService.getErrorMsgValidatingPhoneNumber(updatedFormData.homePhoneNumber)
    + utilService.getErrorMsgValidatingPhoneNumber(updatedFormData.phoneNumber)
    + utilService.getErrorMsgValidatingEmail(updatedFormData.email)
    + utilService.getErrorMsgValidatingAddress(updatedFormData.address)
    + getErrorMsgValidatingFrenchSSN(updatedFormData.socialSecurityNumber)
    + getErrorMsgValidatingAfiliateRegime(updatedFormData.afiliateRegime);


    const formValid = errorsMgs.length === 0;
    setIsFormValid(formValid);

  };
  

  const getErrorMsgValidatingAfiliateRegime = (afiliateRegime) => {
    if (!afiliateRegime) return '';
    const regex = /^[a-zA-Z\u00C0-\u017F\s]{1,49}$/;
    if(regex.test(afiliateRegime)) return '';
    
    if(afiliateRegime.length > 48) return "Champ trop grand, 49 caractères maximum";

  }
  

  function getErrorMsgValidatingFrenchSSN(ssn) {
    if(!ssn) return "Veuillez entrer un NIR de 15 chiffres";
    if(utilService.isValidSSN(ssn)){
      const cleanedSSN = ssn.replace(spacingCharRegEx, ''); // Remove spaces from the input string
      const securityKey  =  +cleanedSSN.replace(validFrenchSsnRegEx,"$7");
      let NIR;

      //https://xml.insee.fr/schema/nir.html#CleNIR-description:~:text=4.1.-,Description,-La%20cl%C3%A9%20de
      if(cleanedSSN.includes("2A")){
        NIR = +cleanedSSN.replace(validFrenchSsnRegEx,"$1$2$3$4$5$6")
                          .replace("2A","20");
        NIR -= 1000000;
      }else if(cleanedSSN.includes("2B")){
        NIR = +cleanedSSN.replace(validFrenchSsnRegEx,"$1$2$3$4$5$6")
                        .replace("2B","20");
        NIR -= 2000000;
      }
      else{
        NIR = +cleanedSSN.replace(validFrenchSsnRegEx,"$1$2$3$4$5$6");
      }

      const realSecurityKey = 97 -(NIR%97);
      if(securityKey === realSecurityKey) return '';
      const realSecurityKeyStr = realSecurityKey <=9 ?"0"+realSecurityKey: ""+realSecurityKey;
      const securityKeyStr     = securityKey     <=9 ?"0"+securityKey    : ""+securityKey;
      return "la clef de sécurité devrait etre "+realSecurityKeyStr+" et non "+securityKeyStr;
    }

    return "NIR non conforme"
}


  useEffect(() => {
    if (isCreateMode) {
        setIsLoading(false);

        setPatient({});
        setFormData({});
      } else {

    PatientService.getPatient(patientId)
    .then(
     (data) => {setIsLoading(false);
                setPatient(data.data);
                setFormData(data.data);}
     )
     .catch(error => {
       console.log(error)
     });
    }

  }, [patientId,isCreateMode]);

  
  if (!props.user.accessToken) {
      return <Navigate replace to="/" />;
    } 
  if( isLoading) {
      return <CircularProgress />;
    }
    return (
      <Box mx="auto" p={4} maxWidth="900px" sx={{ marginLeft: props.sidebar ? '250px' : '0px' }}>
        <Paper variant="outlined">
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
            {isEditMode ? (
              <>
                <Button
                  variant="outlined"
                  color="primary"
                  startIcon={<SaveIcon />}
                  onClick={handleSaveClick}
                  sx={{ mr: 1 }}
                  disabled={!isFormValid}
                >
                  Enregistrer
                </Button>
                {!isCreateMode && (
                  <Button variant="outlined" startIcon={<CancelIcon />} onClick={handleDiscardClick}>
                    Annuler
                  </Button>
                )}
              </>
            ) : (
              !isCreateMode && (
                <Button variant="outlined" startIcon={<EditIcon />} onClick={handleEditClick}>
                  Modifier
                </Button>
              )
            )}
          </Box>
        </Paper>
        <Paper variant="outlined">
          <Box p={2}>
            <Typography variant="h5" align="center">
              Informations personnelles
            </Typography>
            <Grid container spacing={2} mt={2}>
              <Grid item xs={6}>
                <EditableField
                  label="Prénom"
                  value={formData.firstName || ''}
                  name="firstName"
                  isEditable={isEditMode}
                  getErrorMsgValidatingFieldFct={utilService.getErrorMsgValidatingName}
                  onChange={handleChange}
                  isRequired
                />
              </Grid>
              <Grid item xs={6}>
                <EditableField
                  label="Nom"
                  value={formData.lastName || ''}
                  name="lastName"
                  isEditable={isEditMode}
                  getErrorMsgValidatingFieldFct={utilService.getErrorMsgValidatingName}
                  onChange={handleChange}
                  isRequired
                />
              </Grid>
              <Grid item xs={6}>
                <EditableField
                  label="Numéro de sécurité sociale"
                  value={formData.socialSecurityNumber || ''}
                  name="socialSecurityNumber"
                  isEditable={isEditMode}
                  getErrorMsgValidatingFieldFct={getErrorMsgValidatingFrenchSSN}
                  onChange={handleChange}
                  isRequired
                />
              </Grid>
              <Grid item xs={6}>
                <EditableField
                  label="Régime d'affiliation"
                  value={formData.afiliateRegime || ''}
                  name="afiliateRegime"
                  isEditable={isEditMode}
                  getErrorMsgValidatingFieldFct={getErrorMsgValidatingAfiliateRegime}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={6}>
                <EditableField
                  label="Date de naissance"
                  value={formData.birthday || null}
                  name="birthday"
                  isEditable={isEditMode}
                  type="date"
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
          </Box>
        </Paper>
        <Box mt={4} />
        <Paper variant="outlined">
          <Box p={2}>
            <Typography variant="h5" align="center">
              Informations de contact
            </Typography>
            <Grid container spacing={2} mt={2}>
              <Grid item xs={12}>
                <EditableField
                  label="Adresse"
                  value={formData.address || ''}
                  name="address"
                  isEditable={isEditMode}
                  getErrorMsgValidatingFieldFct={utilService.getErrorMsgValidatingAddress}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={6}>
                <EditableField
                  label="Téléphone portable"
                  value={formData.mobilePhoneNumber || ''}
                  name="mobilePhoneNumber"
                  isEditable={isEditMode}
                  getErrorMsgValidatingFieldFct={utilService.getErrorMsgValidatingPhoneNumber}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={6}>
                <EditableField
                  label="Téléphone fixe"
                  value={formData.homePhoneNumber || ''}
                  name="homePhoneNumber"
                  isEditable={isEditMode}
                  getErrorMsgValidatingFieldFct={utilService.getErrorMsgValidatingPhoneNumber}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <EditableField
                  label="Email"
                  value={formData.email || ''}
                  name="email"
                  isEditable={isEditMode}
                  getErrorMsgValidatingFieldFct={utilService.getErrorMsgValidatingEmail}
                  onChange={handleChange}
                  isRequired
                />
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Box>
    );
}


function mapStateToProps(state) {
    const user  = state.authReducer.user;
    return {
      user
    };
}
export default connect(mapStateToProps)(PatientDetail);
