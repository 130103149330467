
import { ENVIRONMENT,PROD_ENVIRONMENT,TEST_ENVIRONMENT } from './axiosInstance.js';

export class EnvironmentService {

    getReCaptchav3SiteKey ()  {
      if(ENVIRONMENT === PROD_ENVIRONMENT || ENVIRONMENT === TEST_ENVIRONMENT  ) 
        return '6LcJJdcmAAAAANsEAcu0LVkRhnh8MMiMt0NWa0XE';
    // LOCAL ENV
    return'6LenJtcmAAAAAKKiYvjKQuJVBKsC9PW8Yeg9UDYw';    
    };

    getReCaptchav2SiteKey ()  {
        if(ENVIRONMENT === PROD_ENVIRONMENT || ENVIRONMENT === TEST_ENVIRONMENT  )
            return '6Lc5T7kpAAAAAOsy3EbrUMgmqRbI3KbPLgCD6BIa';
        // LOCAL ENV
        return'6Ld5ULkpAAAAAApW2rkF-FaWLymSGVOZINXZZLOL';
    };
}

const utilEnv = new EnvironmentService();

export default utilEnv;