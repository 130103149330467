import React, { useState, useEffect } from 'react';
import { Navigate,useNavigate  } from 'react-router-dom';
import { connect } from 'react-redux';
import {actionDisplayMessage} from "../../../actions/auth.action"
import {
    Grid,
    List,
    ListItemText,
    ListItem,
    Divider,
    Paper,
    Typography,
    TextField,
    Box,
    Button,
    CircularProgress, 
    FormHelperText,
    Stack
  } from "@mui/material";
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import DoneIcon from '@mui/icons-material/Done';

import UtilService from '../../../services/util.service.js';
import HorizontalSelect from '../../../components/field/horizontalSelect.component.js';
import ConsultationService from '../../../services/consultation.service.js'



function ChemoObservance(props) {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [chemoInterview, setChemoInterview] = useState(null);

  const patientId = props.patient? props.patient.id:null;


  const [patient, setPatient] = useState(null);
  const [isFormValid/*, setIsFormValid*/] = useState(true);


  const [girerdQuestions, setGirerdQuestions] = useState({});
  const [awareOfMissedDoseRisks, setAwareOfMissedDoseRisks] = useState(null);
  const [knowsWhatToDoIfMissedTreatment, setKnowsWhatToDoIfMissedTreatment] = useState(null);
  const [actionsIfMissedDoseFreeText, setActionsIfMissedDoseFreeText] = useState('');

  const [treatmentTrust, setTreatmentTrust] = useState(null);
  const [knowHowImportantBeingObservantIs, setKnowHowImportantBeingObservantIs] = useState(null);
  const [patientComment, setPatientComment] = useState('');
  const [conclusion, setConclusion] = useState('');

  const [formData, setFormData] = useState(null);

  const initiazeFormData = (formData) => { 
    setIsLoading(false);
    setFormData(formData);
    setPatient(formData.patientRef);
    setGirerdQuestions(formData?.girerdQuestions ?? girerdQuestions)
    setAwareOfMissedDoseRisks(formData?.awareOfMissedDoseRisks ?? awareOfMissedDoseRisks)
    setKnowsWhatToDoIfMissedTreatment(formData?.knowsWhatToDoIfMissedTreatment ?? knowsWhatToDoIfMissedTreatment)
    setActionsIfMissedDoseFreeText(formData?.actionsIfMissedDoseFreeText ?? actionsIfMissedDoseFreeText)
    setTreatmentTrust(formData?.treatmentTrust ?? treatmentTrust)
    setKnowHowImportantBeingObservantIs(formData?.knowHowImportantBeingObservantIs ?? knowHowImportantBeingObservantIs)
    setPatientComment(formData?.patientComment ?? patientComment)
    setConclusion(formData?.conclusion ?? conclusion)
  }


  useEffect(() => {
    setIsLoading(false);
    setChemoInterview(props.consultation);
    initiazeFormData(props.consultation);
     // eslint-disable-next-line
 }, [props.consultation]);


 const handleValidateClick = () => {
  setIsLoading(true);
  const successMessage = "Les modifications ont été enregistrées avec succès";

  ConsultationService.validateChemoObservanceConsultation(formData)
      .then(() => {
      setIsLoading(false);
      props.dispatch(actionDisplayMessage(successMessage,"success"));
      navigate(`/patient/${patientId}/chemo/`);
      })
      .catch((error) => {
      console.error(error);
      setIsLoading(false);
      props.dispatch(
        actionDisplayMessage(
          "Une erreur s'est produite lors de l'enregistrement des modifications",
          "error"));
      });
};
  const handleSaveClick = () => {
    setIsLoading(true);
    const successMessage = "Les modifications ont été enregistrées avec succès";

    ConsultationService.saveChemoObservanceConsultation(formData)
        .then(() => {
        setIsLoading(false);
        props.dispatch(actionDisplayMessage(successMessage,"success"));
        navigate(`/patient/${patientId}/chemo/`);
        })
        .catch((error) => {
        console.error(error);
        setIsLoading(false);
        props.dispatch(
          actionDisplayMessage(
            "Une erreur s'est produite lors de l'enregistrement des modifications",
            "error"));
        });
  };

  const handleDiscardClick = () => {
    setFormData(chemoInterview);
    initiazeFormData(chemoInterview)
  };

  useEffect(() => {
    const handlePatientCommentAndConclusionTreatmentAndSideEffectChange= () => {
    
      setFormData(prevFormData => ({
        ...prevFormData,
        treatmentTrust: treatmentTrust,
        patientComment: patientComment,
        conclusion:conclusion,
        knowHowImportantBeingObservantIs:knowHowImportantBeingObservantIs,
        actionsIfMissedDoseFreeText:actionsIfMissedDoseFreeText,
        knowsWhatToDoIfMissedTreatment:knowsWhatToDoIfMissedTreatment,
        awareOfMissedDoseRisks:awareOfMissedDoseRisks
      }));
      
    }
    handlePatientCommentAndConclusionTreatmentAndSideEffectChange();
  },[patientComment,conclusion,treatmentTrust,knowHowImportantBeingObservantIs,
    actionsIfMissedDoseFreeText,knowsWhatToDoIfMissedTreatment,awareOfMissedDoseRisks]);

  useEffect(() => {
    const handleGirerdQuestionsChange= () => {
    
      setFormData(prevFormData => ({
        ...prevFormData,
        girerdQuestions: girerdQuestions
      }));
    }
    handleGirerdQuestionsChange();
  },[girerdQuestions]);

  useEffect(() => {
    if(props.debug) console.log(formData);
  },[formData,props.debug]);

  

  
  

  
  if (!props.user.accessToken) {
      return <Navigate replace to="/" />;
    } 
  if( isLoading) {
      return <CircularProgress />;
    }
  if(!patient || !formData){
   return  <CircularProgress />;
  }
  return (
    <Grid container justifyContent="center"  p={2} >
      <Grid item sm={8}  >
      <Paper variant="outlined">
        <Stack direction="row" mt={1} justifyContent={"right"}>
          <Button sx={{maxWidth:'300px'}} variant="outlined" startIcon={<CancelIcon />} onClick={handleDiscardClick}>
                Annuler les modifications
          </Button>
        </Stack>
        <Stack direction="row" mb={3} justifyContent={"center"}>
          <Typography variant="h3" >
                Entretien « Observance »
          </Typography>
        </Stack>
      </Paper>
      <Paper variant="outlined">
      <Box p={2} display="block">
        <Typography variant="h5" align="center">
          Informations personnelles
        </Typography>
        <Grid container spacing={2} mt={2}>
          <Grid item md={4}>
            <Typography variant="text" color="grey">{ patient.firstName} {patient && patient.lastName} </Typography>
          </Grid>
          <Grid item md={4}>
            <Typography variant="text" color="grey">Né le { UtilService.handleDateFormat(patient.birthday)} </Typography>
          </Grid>
          <Grid item md={4}>
          <Typography variant="text" color="grey">Numéro de SS:  {patient.socialSecurityNumber} </Typography>
          </Grid>
        </Grid>

      </Box>
    </Paper>
    <Box p={'2px'}/>
    <Paper variant="outlined">
      <Box p={2} display="block">
        <Typography pb={3} variant="h5" align="center">
        Questionnaire de Girerd       
        </Typography>
        <Grid container spacing={1}>
        <Grid item sm={6} md={6} lg={6}>
            <Box sx={{ height: '100%', display: 'flex', flexDirection:'column' , justifyContent:'center'}}> 
            <Typography variant="text">
              Ce matin avez-vous oublié de prendre votre médicament ?             
            </Typography></Box>
          </Grid>
          <Grid pl={2} item sm={6} md={6}  lg={6} > 
            <HorizontalSelect 
              changeValue={(newValue) => {setGirerdQuestions({...girerdQuestions, forgotMedicationMorning:newValue })}}
              value={girerdQuestions?.forgotMedicationMorning}/>

          </Grid>
          <Grid item sm={6} md={6} lg={6}>
            <Box sx={{ height: '100%', display: 'flex', flexDirection:'column' , justifyContent:'center'}}> 
            <Typography variant="text">
            Depuis la dernière consultation avez-vous été en panne de médicament ?             
            </Typography></Box>
          </Grid>
          <Grid pl={2} item sm={6} md={6}  lg={6} > 
            <HorizontalSelect 
              changeValue={(newValue) => {setGirerdQuestions({...girerdQuestions, ranOutOfMedication:newValue })}}
              value={girerdQuestions?.ranOutOfMedication}/>
          </Grid>
          <Grid item sm={6} md={6} lg={6}>
            <Box sx={{ height: '100%', display: 'flex', flexDirection:'column' , justifyContent:'center'}}> 
            <Typography variant="text">
            Vous est-il arrivé de prendre votre traitement avec retard par rapport à l’heure habituelle ?            
            </Typography></Box>
          </Grid>
          <Grid pl={2} item sm={6} md={6}  lg={6} > 
            <HorizontalSelect 
              changeValue={(newValue) => {setGirerdQuestions({...girerdQuestions, tookMedicationLate:newValue })}}
              value={girerdQuestions?.tookMedicationLate}/>
          </Grid>
          <Grid item sm={6} md={6} lg={6}>
            <Box sx={{ height: '100%', display: 'flex', flexDirection:'column' , justifyContent:'center'}}> 
            <Typography variant="text">
            Vous est-il arrivé de ne pas prendre votre traitement parce que, certains jours, votre mémoire vous fait défaut ?            
            </Typography></Box>
          </Grid>
          <Grid pl={2} item sm={6} md={6}  lg={6} > 
            <HorizontalSelect 
              changeValue={(newValue) => {setGirerdQuestions({...girerdQuestions, missedMedicationDueToMemory:newValue })}}
              value={girerdQuestions?.missedMedicationDueToMemory}/>
          </Grid>
          <Grid item sm={6} md={6} lg={6}>
            <Box sx={{ height: '100%', display: 'flex', flexDirection:'column' , justifyContent:'center'}}> 
            <Typography variant="text">
            Vous est-il arrivé de ne pas prendre votre traitement parce que, certains jours, vous avez l’impression que votre traitement vous fait plus de mal que de bien ?            
            </Typography></Box>
          </Grid>
          <Grid pl={2} item sm={6} md={6}  lg={6} > 
            <HorizontalSelect 
              changeValue={(newValue) => {setGirerdQuestions({...girerdQuestions, feltMedicationHarmful:newValue })}}
              value={girerdQuestions?.feltMedicationHarmful}/>
          </Grid>
          <Grid item sm={6} md={6} lg={6}>
            <Box sx={{ height: '100%', display: 'flex', flexDirection:'column' , justifyContent:'center'}}> 
            <Typography variant="text">
            Depuis la dernière consultation avez-vous été en panne de médicament ?             
            </Typography></Box>
          </Grid>
          <Grid pl={2} item sm={6} md={6} lg={6} > 
            <HorizontalSelect 
              changeValue={(newValue) => {setGirerdQuestions({...girerdQuestions, tooManyPills:newValue })}}
              value={girerdQuestions?.tooManyPills}/>
          </Grid>
        </Grid>
      </Box>
    </Paper>
    <Box p={'2px'}/>

    <Paper variant="outlined">
      <Box p={2} display="block">
        <Typography pb={3} variant="h5" align="center">
        Risque en cas d'oubli       
        </Typography>
        <Grid container spacing={1}>
        <Grid item sm={6} md={6} lg={6}>
            <Box sx={{ height: '100%', display: 'flex', flexDirection:'column' , justifyContent:'center'}}> 
            <Typography variant="text">
              La patient connaît-il les risques en cas d'oubli du traitement ?              
            </Typography></Box>
          </Grid>
          <Grid pl={2} item sm={6} md={6}  lg={6} > 
            <HorizontalSelect 
              choices={['Acquis', 'Partiellement acquis', 'Non acquis']}
              values ={['ACQUIS', 'PARTIELEMENT', 'NON ACQUIS']}
              changeValue={(newValue) => {setAwareOfMissedDoseRisks(newValue)}}
              value={awareOfMissedDoseRisks}/>
          </Grid>
          <Grid item sm={6} md={6} lg={6}>
            <Box sx={{ height: '100%', display: 'flex', flexDirection:'column' , justifyContent:'center'}}> 
            <Typography variant="text">
              Le patient sait-il quoi faire en cas d'oubli du traitement ?              
            </Typography></Box>
          </Grid>
          <Grid pl={2} item sm={6} md={6}  lg={6} > 
            <HorizontalSelect 
              changeValue={(newValue) => {setKnowsWhatToDoIfMissedTreatment(newValue)}}
              value={knowsWhatToDoIfMissedTreatment}/>
          </Grid>
          <Grid item md={12}>
            <TextField
                id="actionsIfMissedDoseFreeText"
                multiline
                fullWidth
                value={actionsIfMissedDoseFreeText}
                onChange={(event) => setActionsIfMissedDoseFreeText(event.target.value)}
                minRows={2}
                maxRows={10}
                placeholder="Si oui, veuillez preciser"
                variant="outlined"
                inputProps={{ maxLength: 300 }}
                />
                <FormHelperText id="comment">300 caractères max</FormHelperText>
          </Grid>
        </Grid>
      </Box>
    </Paper>

<Box p={'2px'}/>
<Paper  variant="outlined">
  <Box  p={2} display="block">
      <Typography pb={3} variant="h5" align="center">
          Evaluation de la confiance et de la motivation à prendre le traitement
      </Typography>
      <Grid container spacing={1}>
      <Grid item sm={6} md={6} lg={6}>
        <Box sx={{ height: '100%', display: 'flex', flexDirection:'column' , justifyContent:'center'}}> 
          <Typography variant="text">
          Le patient sait-il qu’il est important d’être observant ?             
          </Typography>
        </Box>
        </Grid>
      <Grid pl={2} item sm={6} md={6}  lg={6} > 
        <HorizontalSelect 
          choices={['Acquis', 'Partiellement acquis', 'Non acquis']}
          values ={['ACQUIS', 'PARTIELEMENT', 'NON ACQUIS']}
          changeValue={(newValue) => {setKnowHowImportantBeingObservantIs(newValue)}}
          value={knowHowImportantBeingObservantIs}/>
      </Grid>
      <Grid item sm={12} md={12} lg={12}>
        <Box mt={1}  sx={{ height: '100%', display: 'flex', flexDirection:'column' , justifyContent:'center'}}> 
        <Typography mb={2} variant="text">
        Sur une échelle de 1 à 10, à quel point vous sentez-vous à l'aise avec la prise de votre traitement ?
        </Typography></Box>
      </Grid>
      <Grid item sm={2} md={2} > 
        <Box sx={{ height: '100%', display: 'flex', flexDirection:'column' , justifyContent:'center'}}> <Typography variant="text">
              1 = Très inconfortable
            </Typography></Box>
        </Grid>
        <Grid item sm={8} md={8} lg={8}>
          <HorizontalSelect 
              choices={[ '1', '2', '3', '4', '5', '6', '7', '8', '9', '10']}
              values ={[  1,   2,   3,   4,   5,   6,   7,    8,   9,  10 ]}
              changeValue={setTreatmentTrust}
              value={treatmentTrust}
              borderRadius={'30px'}/>
        </Grid>
        <Grid item sm={2} md={2} > 
        <Box sx={{ height: '100%', display: 'flex', flexDirection:'column' , justifyContent:'center'}}> <Typography variant="text">
              10 = Tout à fait confortable
          </Typography></Box>
        </Grid>
      </Grid>
  </Box>
</Paper>
<Box p={'2px'}/>
<Paper  variant="outlined">
  <Box  p={2} display="block">
      <Typography pb={3} variant="h5" align="center">
          Informations complémentaires demandées par le patient
      </Typography>
      <Grid container spacing={1}>
      <Grid item md={12}>
            <TextField
                id="patientComment"
                multiline
                fullWidth
                value={patientComment}
                onChange={(event) => setPatientComment(event.target.value)}
                minRows={2}
                maxRows={10}
                placeholder="Informations complémentaires demandées par le patient"
                variant="outlined"
                inputProps={{ maxLength: 250 }}
                />
                <FormHelperText id="comment">250 caractères max</FormHelperText>
            </Grid>
      </Grid>
  </Box>
</Paper>
<Box p={'2px'}/>

<Box p={'2px'}/>
<Paper  variant="outlined">
  <Box  p={2} display="block">
      <Typography pb={3} variant="h5" align="center">
    Vos conclusions     
    </Typography>
      <Grid container spacing={1}>
      <Grid item md={12}>
            <TextField
                id="conclusion"
                multiline
                fullWidth
                value={conclusion}
                onChange={(event) => setConclusion(event.target.value)}
                minRows={2}
                maxRows={10}
                placeholder="Vos conclusions sur l'entretien"
                variant="outlined"
                inputProps={{ maxLength: 250 }}
                />
                <FormHelperText id="comment">250 caractères max</FormHelperText>
            </Grid>
      </Grid>
  </Box>
</Paper>
<Box p={'2px'}/>
    {chemoInterview.status === 'DRAFT'?( <>
      <Button
        variant="contained"
        color="primary"
        startIcon={<DoneIcon />}
        onClick={handleValidateClick}
        sx={{ mr: 1 }}
        disabled={!isFormValid}
      >
        Valider l'entretien
      </Button>
      <Button
          variant="outlined"
          color="primary"
          startIcon={<SaveIcon />}
          onClick={handleSaveClick}
          sx={{ mr: 1 }}
          disabled={!isFormValid}
        >
          Enregistrer pour plus tard
        </Button></>
        ):(
          <Button
          variant="contained"
          color="primary"
          startIcon={<SaveIcon />}
          onClick={handleSaveClick}
          sx={{ mr: 1 }}
          disabled={!isFormValid}
        >
          Enregistrer
          </Button>
         )}
      <Box mt={4} />
    </Grid>
    
    <Grid item  sm={4}  paddingLeft={2} paddingRight={2} >  
       <Box  paddingBottom={0.5}><Paper variant="outlined">
            <Stack direction="row" align="center" justifyContent={'center'}  >
              <Typography variant='h3' align="center">Documentation</Typography>
            </Stack>
          </Paper></Box>
          <Paper variant="outlined" >
             <Stack direction="row"  alignItems="center" justifyContent={'center'} gap={1} >
              <NewReleasesIcon />
              <Typography variant='h5' align="center">Objectifs de l’entretien :</Typography>
             </Stack>

              <List>

              <ListItem alignItems="flex-start" >
                <ListItemText
                  primary="Évaluer l’adhésion et l’observance du traitement."   
                />            
              </ListItem>
              <Divider variant="inset" component="li" />
              <ListItem alignItems="flex-start">
                <ListItemText
                  primary="Sensibiliser le patient à l’importance d’avoir une bonne observance au traitement
                  anticancéreux par voie orale."
                />
              </ListItem>
            </List>
          </Paper>
          <Paper variant="outlined" >
            <Stack direction="row"  alignItems="center" justifyContent={'center'} p={1} >
              <Typography variant='h4' align="center">Rappels à faire au patients</Typography>
             </Stack>
             <Stack  align="center" sx={{padding: 2}}  >

             <Typography variant='text' textAlign={"justify"}display={"block"} mb={1}>L’observance du patient à ce type de traitement est particulièrement importante. </Typography>
             <Typography variant='text' textAlign={"justify"}display={"block"} mb={1}>Pour apprécier cette observance, le questionnaire de GIRERD constitue un support adapté. Il est reproduit dans la fiche
de suivi mise à votre disposition.</Typography>
<Divider m={1}/>             

             <Typography variant='text' textAlign={"justify"} display={"block"} mb={1}>
              Le questionnaire de GIRERD est habituellement utilisé pour apprécier et mesurer l’observance médicamenteuse d’un
              patient. Il est composé de 6 questions simples, auxquelles le patient doit répondre par oui ou par non.</Typography>

             <Typography variant='text' textAlign={"justify"}display={"block"} mb={1}>Chaque réponse négative vaut un point. L’observance est appréciée
comme suit :</Typography>

             <Typography variant='text' textAlign={"justify"}display={"block"} mb={0.5}>– Bonne observance : score = 6</Typography>
             <Typography variant='text' textAlign={"justify"}display={"block"} mb={1} mt={0.5}>– Faible observance : score = 4 ou 5</Typography>
             <Typography variant='text' textAlign={"justify"}display={"block"} mb={1}>– Non observance : score ≤ 3</Typography>
             <Divider m={1}/>

             <Typography variant='text' textAlign={"justify"}display={"block"} mb={1}>
              Par ailleurs, les éléments mis en évidence lors des entretiens précédents notamment la survenue d’effets indésirables,
              l’isolement et le ressenti du patient peuventvous  permettre  d’apprécier au mieux l’observance et d’apporter au
              patient les conseils adaptés.
              </Typography>
          </Stack>
          </Paper>
          
      </Grid>
    </Grid>
  );  
}

function mapStateToProps(state) {
    const user  = state.authReducer.user;
    return {
      user
    };
}
export default connect(mapStateToProps)(ChemoObservance);
