import React, {useEffect, useState} from "react";
import {Box, Button, CircularProgress, FormHelperText, Grid, Paper, Stack, TextField, Typography,Alert,Accordion,AccordionSummary,AccordionDetails} from '@mui/material';
import HorizontalSelect from '../../../components/field/horizontalSelect.component';
import {
    SoreThroatTestStatus,
    SoreThroatTest,
    SoreThroatTreatment,
    MedicalTestStatus,
    Patient
} from '../../../types/types.ts';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import MedicalTestResultSelect from "../../../components/field/MedicalTestResultSelect.tsx";
import {connect, useDispatch} from "react-redux";
import {Dispatch} from "redux";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import InfoIcon from '@mui/icons-material/Info';
import MedicalTestResultChip from "../../../components/field/MedicalTestResulChip.tsx";
import Chip from "@mui/material/Chip";
import EmailIcon from "@mui/icons-material/Email";
import UtilService from "../../../services/util.service.js"
import MedicalTestService, {MEDICAL_TEST_URL_IDENTIFIER} from "../../../services/MedicalTestService.ts";
import PharmacyService  from "../../../services/pharmacy.service";
import {getActionDisplayMessage} from "../../../actions/ReduxAction.ts";
import {useNavigate} from "react-router-dom";
import SaveIcon from "@mui/icons-material/Save";
import SendResultSoreThroatTest from "./SendResultSoreThroatTest.tsx";
interface SoreThroatTestProps {
    medicalTest: SoreThroatTest;
    patient : Patient;
}


const SoreThroatTestDetail: React.FC<SoreThroatTestProps> = ({ medicalTest, patient }) => {
    const [currentTest, setCurrentTest] = useState(medicalTest);
    const [macIsaacScore, setMacIsaacScore] = useState(null);
    const [soreThroatQuestionStatus, setSoreThroatQuestionStatus]
        = useState({status: medicalTest.soreThroatStatus, text:"" });
    const [isLoading, setIsLoading] = useState(false);
    const [showSoreThroatQuestions, setShowSoreThroatQuestions] = React.useState(true);
    const [forceShowSoreThroatQuestions, setForceShowSoreThroatQuestions] = React.useState(false);

    const resultHasBeenSent : boolean = currentTest.emailDate && currentTest.emailResult;
    const [isDialogOpen, setDialogOpen] = useState(false);

    const canSendReportToPatient : boolean = !currentTest.emailDate  && ((currentTest.status === MedicalTestStatus.POSITIVE &&  currentTest.allergicToAvailableAntibiotics !== null &&  currentTest.antibioticsPrescribed !== null ) || (currentTest.status === MedicalTestStatus.POSITIVE &&  currentTest.allergicToAvailableAntibiotics === true) ||  currentTest.status === MedicalTestStatus.NEGATIVE  );
    const emailReportHasBeeSent : boolean = currentTest.emailDate  && currentTest.emailResult;
    const displayPrescriptionErrorWhenPositive : boolean =  currentTest.status === MedicalTestStatus.POSITIVE && (currentTest.allergicToAvailableAntibiotics === null || (currentTest.allergicToAvailableAntibiotics === false && currentTest.antibioticsPrescribed === null));
    const displayBillingCode10 :boolean = (currentTest.status === MedicalTestStatus.POSITIVE && currentTest.allergicToAvailableAntibiotics === true) || currentTest.status === MedicalTestStatus.NEGATIVE || currentTest.withPrescription;
    const displayBillingCode15 :boolean = (currentTest.status === MedicalTestStatus.POSITIVE) && currentTest.antibioticsPrescribed && !currentTest.withPrescription && !displayBillingCode10;
    const displayTestConclusion :boolean = soreThroatQuestionStatus.status ===  SoreThroatTestStatus.CAN_TEST_PATIENT  && currentTest.status !== MedicalTestStatus.IN_PROGRESS && currentTest.status !== MedicalTestStatus.CANCELED;
    const displayCommentForDoctor :boolean = (currentTest.status === MedicalTestStatus.NEGATIVE || currentTest.status === MedicalTestStatus.POSITIVE);
    const displayAdditionalQuestion :boolean = (currentTest.status === MedicalTestStatus.NEGATIVE || currentTest.status === MedicalTestStatus.POSITIVE) && (soreThroatQuestionStatus.status ===  SoreThroatTestStatus.CAN_TEST_PATIENT);
    const attachmentWithMail :boolean = (currentTest.status === MedicalTestStatus.NEGATIVE && currentTest.negatifTestWithAnomaly === true) || currentTest.status === MedicalTestStatus.POSITIVE;
    const dispatch: Dispatch = useDispatch();
    const navigate = useNavigate();

    const [pharmacy, setPharmacy] = useState(patient.pharmacyRef);

    useEffect(() => {
            PharmacyService.getPharmacy(patient.pharmacyRef.id).then((response) => {
                setPharmacy(response.data);
            }).catch((error) => {
                dispatch(getActionDisplayMessage("Une erreur s'est produite lors de la recupération de vos données de Pharmacie, veuillez recharger la page ou contacter le support", 'error'));
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
        ,[patient]);

    const getQuestionsData = () => [
        {
            field: 'withPrescription',
            text: 'Avec une ordonnance de dispensation conditionnelle avec la mention «si TROD angine+» en regard de l’antibiotique* et datant au plus de 7 jours?',
            value: currentTest.withPrescription,
            transitions: {
                true: 'isLessThan3',
                false: 'hasFeverMoreThan39_5',
                null: 'NOT_ENOUGH_INFO'
            },
            visible: true,
            infoIcon: true
        },
        {
            field: 'isLessThan3',
            text: 'Le(a) patient(e) a moins de 3 ans',
            value: currentTest.isLessThan3,
            transitions: {
                true: 'CAN_TEST_PATIENT_WARNING_LESS_THAN3',
                false: 'CAN_TEST_PATIENT',
                null: 'NOT_ENOUGH_INFO'
            },
            visible: false,
            infoIcon: true
        },
        {
            field: 'hasFeverMoreThan39_5',
            text: 'Fièvre rapportée par le patient supérieure à 39,5° C?',
            value: currentTest.hasFeverMoreThan39_5,
            transitions: {
                true: 'SHOULD_CONSULT_EMERGENCY_FEVER_39',
                false: 'isMoreThan10',
                null: 'NOT_ENOUGH_INFO'
            },
            visible: false
        },
        {
            field: 'isMoreThan10',
            text: 'Le(a) patient(e) a plus de 10 ans (> 10) ?',
            value: currentTest.isMoreThan10,
            transitions: {
                true: 'symptomsDurationMoreThan8Days',
                false: 'NOT_ALLOWED_TO_TEST_AGE_10',
                null: 'NOT_ENOUGH_INFO'
            },
            visible: false,
            infoIcon: true
        },
        {
            field: 'symptomsDurationMoreThan8Days',
            text: 'Vos symptomes durent-ils depuis plus de 8 jours ?',
            value: currentTest.symptomsDurationMoreThan8Days,
            transitions: {
                true: 'SHOULD_CONSULT_DOCTOR_DURATION8D',
                false: 'recentTropicalTravelWithoutUpToDateVaccine',
                null: 'NOT_ENOUGH_INFO'
            },
            visible: false,
        },
        {
            field: 'recentTropicalTravelWithoutUpToDateVaccine',
            text: 'Êtes-vous de retour de voyage en pays tropical depuis 2 à 5 jours et vaccination anti-diphtérique non à jour ou non renseignée ?',
            value: currentTest.recentTropicalTravelWithoutUpToDateVaccine,
            transitions: {
                true: 'SHOULD_CONSULT_DOCTOR_TROPICAL',
                false: 'pregnancyRisk',
                null: 'NOT_ENOUGH_INFO'
            },
            visible: false,
        },
        {
            field: 'pregnancyRisk',
            text: "Êtes-vous enceinte ou des chances l'être?",
            value: currentTest.pregnancyRisk,
            transitions: {
                true: 'SHOULD_CONSULT_DOCTOR_PREGNANT',
                false: 'withImmunoRisk',
                null: 'NOT_ENOUGH_INFO'
            },
            visible: false,
        },
        {
            field: 'withImmunoRisk',
            text: "Avez-vous été diagnostiqué(e) avec une maladie qui affecte votre système immunitaire, comme le VIH, ou prenez-vous actuellement des médicaments qui pourraient affaiblir vos défenses immunitaires, tels que la corticothérapie à long terme, des immunosuppresseurs, de la chimiothérapie, ou des traitements pour l’hyperthyroïdie comme le carbimazole ou le méthimazole ?",
            value: currentTest.withImmunoRisk,
            transitions: {
                true: 'SHOULD_CONSULT_DOCTOR_IMMUNO',
                false: 'withRenalFailure',
                null: 'NOT_ENOUGH_INFO'
            },
            visible: false,
        },
        {
            field: 'withRenalFailure',
            text: "Insuffisance rénale sévère rapportée par le patient ou " +
                "débit de filtration glomérulaire (DFG) < 30 ml/" +
                "mn/1.73m2 selon CPK-EPI ou (DFG) < 40 ml/mn/1.73m2 " +
                "selon CPK-EPI si cefpodoxime ;",
            value: currentTest.withRenalFailure,
            transitions: {
                true: 'SHOULD_CONSULT_DOCTOR_RENAL',
                false: 'hasAntibioticTreatment',
                null: 'NOT_ENOUGH_INFO'
            },
            visible: false,
        },
        {
            field: 'hasAntibioticTreatment',
            text: "Suivez-vous actuellement un traitement antibiotique ?",
            value: currentTest.hasAntibioticTreatment,
            transitions: {
                true: 'SHOULD_CONSULT_DOCTOR_ANTIBIO',
                false: 'similarEpisodeInLast15Days',
                null: 'NOT_ENOUGH_INFO'
            },
            visible: false,
        },
        {
            field: 'similarEpisodeInLast15Days',
            text: "Avez-vous eu un épisode similaire au cours des 15 derniers jours ?",
            value: currentTest.similarEpisodeInLast15Days,
            transitions: {
                true: 'SHOULD_CONSULT_DOCTOR_15DAYS',
                false: 'thirdConsultationForOdynophagiaInSixMonthsWithoutDoctor',
                null: 'NOT_ENOUGH_INFO'
            },
            visible: false,
        },
        {
            field: 'thirdConsultationForOdynophagiaInSixMonthsWithoutDoctor',
            text: "Est-ce la troisième fois ou plus que vous consultez pour des douleurs à la déglutition (odynophagie) chez le pharmacien au cours des six derniers mois, sans avoir pris rendez-vous avec votre médecin traitant ?",
            value: currentTest.thirdConsultationForOdynophagiaInSixMonthsWithoutDoctor,
            transitions: {
                true: 'SHOULD_CONSULT_DOCTOR_3TIMES_6MONTHS',
                false: 'contraindicationToAntibiotics',
                null: 'NOT_ENOUGH_INFO'
            },
            visible: false,
        },
        {
            field: 'contraindicationToAntibiotics',
            text: "Avez-vous des antécédents d’allergie ou de contre-indication à l’utilisation de certains antibiotiques tels que l’amoxicilline, le céfuroxime, le cefpodoxime, l’azithromycine, la clarithromycine ou la josamycine ?",
            value: currentTest.contraindicationToAntibiotics,
            transitions: {
                true: 'SHOULD_CONSULT_DOCTOR_ANTIBIO_ALLERGIES',
                false: 'hasVenousCatheter',
                null: 'NOT_ENOUGH_INFO'
            },
            visible: false,
        },
        {
            field: 'hasVenousCatheter',
            text: "Portez-vous actuellement un cathéter veineux implanté ?",
            value: currentTest.hasVenousCatheter,
            transitions: {
                true: 'SHOULD_CONSULT_DOCTOR_CATHETER',
                false: 'hasRealFeverMoreThan39_5',
                null: 'NOT_ENOUGH_INFO'
            },
            visible: false,
        },

        {
            field: 'hasRealFeverMoreThan39_5',
            text:  'Température mesurée par le pharmacien supérieure à 39,5°C ?',
            value: currentTest.hasRealFeverMoreThan39_5,
            transitions: {
                true: 'SHOULD_CONSULT_EMERGENCY_FEVER_39',
                false: 'hasAlteredVitalSigns',
                null: 'NOT_ENOUGH_INFO'
            },
            visible: false,
        },
        {
            field: 'hasAlteredVitalSigns',
            text:  'Constatation d\'une altération franche des constantes :' +
                'Pression Artérielle Systolique < 90 mm Hg,' +
                'Fréquence Cardiaque > 110/min,Fréquence Respiratoire > 20/min,' +
                'Difficulté respiratoire, ' +
                'Trismus (limitation de l’ouverture buccale), ' +
                'Hypersialorrhée, ' +
                'Cervicalgie ou torticolis limitant lesmouvements de la tête',
            value: currentTest.hasAlteredVitalSigns,
            transitions: {
                true: 'SHOULD_CONSULT_EMERGENCY_PLHLEGOM',
                false: 'isMoreThan15',
                null: 'NOT_ENOUGH_INFO'
            },
            visible: false,
        },
        {
            field: 'isMoreThan15',
            text: 'Le(a) patient(e) a plus de 15 ans (≥ 15 ans)  ?',
            value: currentTest.isMoreThan15,
            transitions: {
                true: 'presenceOfCough',
                false: 'childWithRashUnder15YearsOld',
                null: 'NOT_ENOUGH_INFO'
            },
            visible: false,
            infoIcon: true
        },
        {
            field: 'childWithRashUnder15YearsOld',
            text: 'L\'enfant (de moins  de 15 ans) a t\'il a une éruption cutanée ?',
            value: currentTest.childWithRashUnder15YearsOld,
            transitions: {
                true: 'SHOULD_CONSULT_DOCTOR_RASH',
                false: 'childThatVomitsOrDiarrhea',
                null: 'NOT_ENOUGH_INFO'
            },
            visible: false,
        },
        {
            field: 'childThatVomitsOrDiarrhea',
            text: 'L\'enfant (de moins  de 15 ans) présente-t-il des troubles digestifs importants (vomissements, diarrhée) ?',
            value: currentTest.childThatVomitsOrDiarrhea,
            transitions: {
                true: 'SHOULD_CONSULT_EMERGENCY_CHILD_WITH_DIGESTIVES_TROUBLES',
                false: 'CAN_TEST_PATIENT_WITH_PARENTS',
                null: 'NOT_ENOUGH_INFO'
            },
            visible: false,
        },
        
        {
            field: 'presenceOfCough',
            text: 'Avez-vous une toux actuellement ?',
            value: currentTest.presenceOfCough,
            transitions: {
                true: 'hasTenderAnteriorCervicalLymphNodes',
                false: 'hasTenderAnteriorCervicalLymphNodes',
                null: 'NOT_ENOUGH_INFO'
            },
            visible: false,
            infoIcon: true
        },
        {
            field: 'hasTenderAnteriorCervicalLymphNodes',
            text: 'Avez-vous des ganglions cervicaux antérieurs qui sont sensibles au toucher ? (Adénopathies cervicales antérieures sensibles)',
            value: currentTest.hasTenderAnteriorCervicalLymphNodes,
            transitions: {
                true: 'temperatureAbove38',
                false: 'temperatureAbove38',
                null: 'NOT_ENOUGH_INFO'
            },
            visible: false,
            infoIcon: true
        },
        {
            field: 'temperatureAbove38',
            text: 'Avez-vous eu une température supérieure à 38°C depuis le début de cet épisode ?',
            value: currentTest.temperatureAbove38,
            transitions: {
                true: 'hasTonsillarSwellingOrExudate',
                false: 'hasTonsillarSwellingOrExudate',
                null: 'NOT_ENOUGH_INFO'
            },
            visible: false,
            infoIcon: true
        },
        {
            field: 'hasTonsillarSwellingOrExudate',
            text: 'Avez-vous remarqué un gonflement ou des dépôts (exsudats) sur vos amygdales ?',
            value: currentTest.hasTonsillarSwellingOrExudate,
            transitions: {
                true: 'isMoreThan50',
                false: 'isMoreThan50',
                null: 'NOT_ENOUGH_INFO'
            },
            visible: false,
            infoIcon: true
        },
        {
            field: 'isMoreThan50',
            text: "Avez-vous plus de 50 ans (> 50 ans)?",
            value: currentTest.isMoreThan50,
            transitions: {
                true: 'MAY_TEST_PATIENT_MAC_ISAAC_SCORE',
                false: 'MAY_TEST_PATIENT_MAC_ISAAC_SCORE',
                null: 'NOT_ENOUGH_INFO'
            },
            visible: false,
            infoIcon: true
        },

    ];
    const [questions, setQuestions] = useState(getQuestionsData());


    const states = {
        NOT_ALLOWED_TO_TEST_AGE_10 : { status: SoreThroatTestStatus.NOT_ALLOWED_TO_TEST, text: "Impossible de réaliser un trod angine sur un patient de moins  de 10ans sans ordonnance" },
        SHOULD_CONSULT_EMERGENCY_FEVER: { status: SoreThroatTestStatus.SHOULD_CONSULT_EMERGENCY, text: "Impossible de réaliser un trod angine sur une patiente atteinte de fièvre" },
        SHOULD_CONSULT_EMERGENCY_FEVER_39: { status: SoreThroatTestStatus.SHOULD_CONSULT_EMERGENCY, text: "Impossible de réaliser un trod angine sur un patient atteinte de fièvre supérieur à 39.5° C (signe évoquant un phlegmon de l’amygdale)" },
        SHOULD_CONSULT_EMERGENCY_PLHLEGOM: { status: SoreThroatTestStatus.SHOULD_CONSULT_EMERGENCY, text: "Impossible de réaliser un trod angine sur ce patient car il y a un risque de phlegmon de l’amygdale" },
        SHOULD_CONSULT_EMERGENCY_CHILD_WITH_DIGESTIVES_TROUBLES: { status: SoreThroatTestStatus.SHOULD_CONSULT_EMERGENCY, text: "Impossible de réaliser un test sur un enfant avec des troubles digestifs importants " },
        SHOULD_CONSULT_DOCTOR_RASH: { status: SoreThroatTestStatus.SHOULD_CONSULT_DOCTOR, text: "Impossible de réaliser un test sur un enfant de moint de 15 ans qui présente une eruption cutanée" },
        SHOULD_CONSULT_DOCTOR_DURATION8D: { status: SoreThroatTestStatus.SHOULD_CONSULT_DOCTOR, text: "Impossible de réaliser un test avec une durée d’évolution des symptômes > 8 jours" },
        SHOULD_CONSULT_DOCTOR_TROPICAL: { status: SoreThroatTestStatus.SHOULD_CONSULT_DOCTOR, text: "Impossible de réaliser un test a cause du risque de diphtérie" },
        SHOULD_CONSULT_DOCTOR_PREGNANT: { status: SoreThroatTestStatus.SHOULD_CONSULT_DOCTOR, text: "Impossible de réaliser un test sur une patiente enceinte" },
        SHOULD_CONSULT_DOCTOR_IMMUNO: { status: SoreThroatTestStatus.SHOULD_CONSULT_DOCTOR, text: "Impossible de réaliser un test sur une patient immunodépressif" },
        SHOULD_CONSULT_DOCTOR_RENAL: { status: SoreThroatTestStatus.SHOULD_CONSULT_DOCTOR, text: "Impossible de réaliser un test sur une patient atteint d'insuffisance rénale sévère" },
        SHOULD_CONSULT_DOCTOR_ANTIBIO: { status: SoreThroatTestStatus.SHOULD_CONSULT_DOCTOR, text: "Impossible de réaliser un test sur une patient qui suit une antibiothérapie" },
        SHOULD_CONSULT_DOCTOR_15DAYS: { status: SoreThroatTestStatus.SHOULD_CONSULT_DOCTOR, text: "Impossible de réaliser un test sur une patient qui a déjà eu un episode dans les 15 derniers jours" },
        SHOULD_CONSULT_DOCTOR_3TIMES_6MONTHS: { status: SoreThroatTestStatus.SHOULD_CONSULT_DOCTOR, text: "Impossible de réaliser un test sur une patient qui a déjà eu plus de trois episodes dans les 6 derniers mois" },
        SHOULD_CONSULT_DOCTOR_ANTIBIO_ALLERGIES: { status: SoreThroatTestStatus.SHOULD_CONSULT_DOCTOR, text: "Impossible de réaliser un test sur une patient qui est allergique à ces antibiotiques" },
        SHOULD_CONSULT_DOCTOR_CATHETER: { status: SoreThroatTestStatus.SHOULD_CONSULT_DOCTOR, text: "Impossible de réaliser un test sur une patient qui porte un cathéter veineux implanté" },
        CAN_TEST_PATIENT: { status: SoreThroatTestStatus.CAN_TEST_PATIENT, text: "" },
        CAN_TEST_PATIENT_WARNING_LESS_THAN3: { status: SoreThroatTestStatus.CAN_TEST_PATIENT, text: "Contacter le médecin, la réalisation du TROD à l’office normalement impossible pour un enfant de moins de 3 ans " },
        CAN_TEST_PATIENT_WITH_PARENTS: { status: SoreThroatTestStatus.CAN_TEST_PATIENT, text: "Vous pouvez réaliser le test avec l'accord de son représentant légal " },
        CAN_TEST_PATIENT_AFTER_MAC_ISAAC_SCORE: { status: SoreThroatTestStatus.CAN_TEST_PATIENT, text:null },
        CAN_NOT_TEST_PATIENT_AFTER_MAC_ISAAC_SCORE: { status: SoreThroatTestStatus.NOT_ALLOWED_TO_TEST, text:null},
        MAY_TEST_PATIENT_MAC_ISAAC_SCORE: { status: SoreThroatTestStatus.NOT_ALLOWED_TO_TEST, text: "Vous pouvez réaliser le test avec l'accord de son représentant légal " },
        NOT_ENOUGH_INFO: { status: SoreThroatTestStatus.NOT_ENOUGH_INFO, text: "Pas assez de réponse, pour connaitre l'élligibilité du patient" }
    };


    const determineQuestionsToShow = () => {
        let newQuestions = getQuestionsData();
        let currentState = newQuestions.find(q => q.field === 'withPrescription');
        while (currentState) {
            const response = currentTest[currentState.field];
            const nextStateKey = currentState.transitions[response];


            if (nextStateKey === null) {
                // Not enough information to proceed
                setQuestions(newQuestions);
                setSoreThroatQuestionStatus(states.NOT_ENOUGH_INFO);
                return;
            }

            const nextState = newQuestions.find(q => q.field === nextStateKey) || states[nextStateKey];

            if (!nextState) {
                // End of the state machine
                setShowSoreThroatQuestions(false);
                setQuestions(newQuestions);
                return;
            }

            if (nextState.visible !== undefined) {
                nextState.visible = true;
            } else {
                // Terminal state
                setQuestions(newQuestions);
                if(nextStateKey === "MAY_TEST_PATIENT_MAC_ISAAC_SCORE"){
                    const newMacIsaacScore  = computeMacIsaacScore();
                        setSoreThroatQuestionStatus(newMacIsaacScore <=  1?
                            states.CAN_NOT_TEST_PATIENT_AFTER_MAC_ISAAC_SCORE :
                            states.CAN_TEST_PATIENT_AFTER_MAC_ISAAC_SCORE);
                        setMacIsaacScore(newMacIsaacScore);
                        setShowSoreThroatQuestions(false);
                }
                else{
                    if(macIsaacScore !== null){
                        setMacIsaacScore(null);
                    }
                    setSoreThroatQuestionStatus(nextState);
                }
                if(nextStateKey === SoreThroatTestStatus.CAN_TEST_PATIENT ){
                    setShowSoreThroatQuestions(false);
                }

                return;
            }

            currentState = nextState;
        }
    };
const computeMacIsaacScore = () : number => {
    let score : number = 0;
    score = currentTest.presenceOfCough === false ? score+1:score;
    score = currentTest.hasTenderAnteriorCervicalLymphNodes === true ? score+1:score;
    score = currentTest.temperatureAbove38 === true ? score+1:score;
    score = currentTest.hasTonsillarSwellingOrExudate === true ? score+1:score;
    score = currentTest.isMoreThan50 === true ? score-1:score;

    return score

}

    useEffect(() => {
        determineQuestionsToShow();
        // eslint-disable-next-line
    }, [currentTest]);

    const handleFieldChange = (field, value) => {
        setCurrentTest(prevState => ({
            ...prevState,
            [field]: value
        }));
    };
    const handleSendReport = () => {
        setIsLoading(true);
        MedicalTestService.saveSoreThroatTest(currentTest).then((savedCurrentTest :SoreThroatTest) => {
            setIsLoading(false);
            setCurrentTest(savedCurrentTest);
            setDialogOpen(true);
        })
            .catch((error) => {
                console.error(error);
                setIsLoading(false);
                dispatch(
                    getActionDisplayMessage(
                        "Une erreur s'est produite lors de l'enregistrement des modifications",
                        "error"));
            });
    }

    const handleSaveAsDraft = () => {
      setIsLoading(true);
        MedicalTestService.saveSoreThroatTest(currentTest).then(() => {
            setIsLoading(false);
            dispatch(getActionDisplayMessage("Test Enregistré avec succès","success"));
            navigate(`/patient/${patient.id}/${MEDICAL_TEST_URL_IDENTIFIER}/`);
        })
            .catch((error) => {
                console.error(error);
                setIsLoading(false);
                dispatch(
                    getActionDisplayMessage(
                        "Une erreur s'est produite lors de l'enregistrement des modifications",
                        "error"));
            });
    }

    const renderIcon = (question) => {

        if (question.infoIcon) return <InfoIcon style={{ color: 'lightsteelblue' }} />;
        if (question.value === true)
            return  question.greenIconWhenValueIsTrue ?  <CheckCircleIcon style={{ color: 'green' }} /> : <CancelIcon style={{ color: 'red' }} />;
        if (question.value === false)
            return  question.greenIconWhenValueIsTrue ?  <CancelIcon style={{ color: 'red' }} /> : <CheckCircleIcon style={{ color: 'green' }} /> ;
        return '';
    };

    if (isLoading) return <CircularProgress />;

    return (
        <Grid container justifyContent="center" p={2}>
            <Grid item xs={12} sm={12} md={12} lg={11} xl={10}>
                <Paper variant="outlined">
                    <Stack direction="row" mb={3} justifyContent={"center"}>
                        <Typography variant="h3">
                            TROD angine du {new Date(currentTest.testDate).toLocaleDateString()} pour {patient.firstName} {patient.lastName}
                        </Typography>
                    </Stack>
                </Paper>
                <Paper variant="outlined">
                    <Box p={2} display="block">
                        <Typography variant="h4" align="center">
                            Information sur le patient
                        </Typography>
                        <Grid container spacing={2} mt={2}>
                            <Grid item md={4}>
                                <Typography variant="h5" color="grey">{patient.firstName} {patient.lastName}</Typography>
                            </Grid>
                            <Grid item md={4}>
                                <Typography variant="h5" color="grey">Né le {new Date(patient.birthday).toLocaleDateString()}</Typography>
                            </Grid>
                            <Grid item md={4}>
                                <Typography variant="h5" color="grey">Numéro de SS: {patient.socialSecurityNumber}</Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </Paper>
                <Paper variant="outlined">
                    <Accordion  expanded={showSoreThroatQuestions||forceShowSoreThroatQuestions} onChange={() => setForceShowSoreThroatQuestions(!forceShowSoreThroatQuestions)}>


                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                        >
                        <Typography variant="h4" align="center" width={'100%'}>
                            Questionnaire de TROD angine
                        </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                        <Grid container spacing={2} mt={2} pl={1}>
                            {questions.map((question, index) => (
                                question.visible && (
                                    <Grid container key={index} mb={1}>
                                        <Grid item xs={12} sm={6}>
                                            <Stack direction="row" spacing={1}>
                                                {renderIcon(question)}
                                                <Typography variant="h5" fontWeight={index === questions.length-1 || (questions[index + 1] && !questions[index + 1].visible) ? "bold" : "light"}>
                                                    {question.text}
                                                </Typography>
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <HorizontalSelect
                                                choices={['Oui', 'Non',]}
                                                values ={[true, false]}
                                                changeValue={(value) => handleFieldChange(question.field, value)}
                                                value={question.value}
                                            />
                                        </Grid>
                                    </Grid>
                                )
                            ))}
                        </Grid>
                        </AccordionDetails>

                    </Accordion>
                </Paper>
                <Paper variant="outlined">
                    <Box p={2} display="block">
                        <Typography variant="h4" align="center">
                            Orientation du test
                        </Typography>
                        <Grid container spacing={2} mt={2}>
                            {(soreThroatQuestionStatus.status) && (
                                (soreThroatQuestionStatus.status === SoreThroatTestStatus.CAN_TEST_PATIENT &&  macIsaacScore !== null&&  macIsaacScore >= 2 ?
                                    <Alert severity="info" style={{width:'100%',marginLeft:'16px'}} >
                                        <Typography px={2} variant="h5" align="center">
                                            Vous pouvez réaliser le TROD angine avec l'accord du patient.(score Mac Isaac: {macIsaacScore} ≥ 2 )  )
                                        </Typography>
                                    </Alert> : ''))}
                            {(soreThroatQuestionStatus.status) && (
                                (soreThroatQuestionStatus.status === SoreThroatTestStatus.CAN_TEST_PATIENT && macIsaacScore === null ?
                                    <Alert severity="info" style={{width:'100%',marginLeft:'16px'}} >
                                        <Typography px={2} variant="h5" align="center">
                                            Vous pouvez réaliser le TROD angine avec l'accord du patient. { soreThroatQuestionStatus.text ? ' : '+soreThroatQuestionStatus.text : ''}
                                        </Typography>
                                    </Alert> : ''))}

                            {(soreThroatQuestionStatus.status) && (
                                (soreThroatQuestionStatus.status === SoreThroatTestStatus.NOT_ALLOWED_TO_TEST  &&  macIsaacScore !== null && macIsaacScore <= 1 ?
                                    <Alert severity="info" style={{width:'100%',marginLeft:'16px'}} >
                                        <Typography px={2} variant="h5" align="center">
                                            Le TROD angine ne peut PAS être réalisé car le score Mac Isaac est de {macIsaacScore} (  pas de trod angine si score ≤ 1)  ) <br/>
                                            <strong>Traitement symptomatique local + paracétamol et si absence d’amélioration dans les 3 jours consultation médicale</strong>
                                        </Typography>
                                    </Alert> : ''))}
                            {(soreThroatQuestionStatus.status) && (
                                (soreThroatQuestionStatus.status === SoreThroatTestStatus.NOT_ALLOWED_TO_TEST && macIsaacScore === null ?
                                    <Alert severity="error" style={{width:'100%',marginLeft:'16px'}} >
                                        <Typography px={2} variant="h5" align="center">
                                            Le TROD angine ne peut PAS être réalisé { soreThroatQuestionStatus.text ? ' : '+soreThroatQuestionStatus.text : ''}
                                        </Typography>
                                    </Alert> : ''))}
                            {(soreThroatQuestionStatus.status) && (
                                (soreThroatQuestionStatus.status === SoreThroatTestStatus.NOT_ENOUGH_INFO ?
                                    <Alert severity="warning" style={{width:'100%',marginLeft:'16px'}} >
                                        <Typography px={2} variant="h5" align="center">
                                            Le TROD angine ne peut PAS encore être réalisé { soreThroatQuestionStatus.text ? ' : '+soreThroatQuestionStatus.text : ''}
                                        </Typography>
                                    </Alert> : ''))}

                            {(soreThroatQuestionStatus.status) && (
                                (soreThroatQuestionStatus.status === SoreThroatTestStatus.SHOULD_CONSULT_DOCTOR ?
                                    <Alert severity="error" style={{width:'100%',marginLeft:'16px'}} >
                                        <Typography px={2} variant="h5" align="center">
                                            Le trod angine ne peut PAS être réalisé, vous devriez orienter le patient vers son medecin traitant { soreThroatQuestionStatus.text ? ' : '+soreThroatQuestionStatus.text : ''}
                                        </Typography>
                                    </Alert> : ''))}

                            {(soreThroatQuestionStatus.status) && (
                                (soreThroatQuestionStatus.status === SoreThroatTestStatus.SHOULD_CONSULT_EMERGENCY ?
                                    <Alert severity="error" style={{width:'100%',marginLeft:'16px'}} >
                                        <Typography px={2} variant="h5" align="center">
                                            Le trod angine ne peut PAS être réalisé, vous devriez orienter le patient vers un service d'urgence { soreThroatQuestionStatus.text ? ' : '+soreThroatQuestionStatus.text : ''}
                                        </Typography>
                                    </Alert> : ''))}
                        </Grid>
                    </Box>
                </Paper>
                {soreThroatQuestionStatus.status ===  SoreThroatTestStatus.CAN_TEST_PATIENT  && (
                    <Paper variant="outlined" >
                        <Box p={2} display="block">
                            <Typography variant="h4" align="center" sx={{paddingBottom:4}}>
                                Résultat du Test
                            </Typography>
                            <Grid container  p={1}>
                                <Grid item md={12} xs={12}>
                                    <MedicalTestResultSelect showInconclusive={false} disabled={resultHasBeenSent} testResult={currentTest.status} changeValue={(newTestResult)=> handleFieldChange('status', newTestResult)}/>
                                </Grid>
                            </Grid>
                        </Box>
                    </Paper>
                )}
                {currentTest.status === MedicalTestStatus.POSITIVE && soreThroatQuestionStatus.status === SoreThroatTestStatus.CAN_TEST_PATIENT  && (
                    <Paper variant="outlined" >
                        <Box p={2} display="block">
                            <Typography variant="h4" align="center" sx={{paddingBottom:4}}>
                                Traitement antibiotique
                            </Typography>

                            <Grid container mb={1}>
                                <Grid item xs={12} sm={6}>
                                    <Stack direction="row" spacing={1}>
                                        <Typography variant="h5" >
                                          Avez vous déjà fait une réaction allergique à un antibiotique ?
                                        </Typography>
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <HorizontalSelect
                                        choices={['Oui', 'Non',]}
                                        values ={[true, false]}
                                        changeValue={(value) => handleFieldChange('allergicToAnyAntibiotics', value)}
                                        value={currentTest.allergicToAnyAntibiotics}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                <Stack direction="row" spacing={1}>
                                    <Typography variant="h5" >
                                        Allergie ou contre indication aux antibiotiques préconisés  ?
                                    </Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <HorizontalSelect
                                    choices={['Oui', 'Non',]}
                                    values ={[true, false]}
                                    changeValue={(value) => handleFieldChange('allergicToAvailableAntibiotics', value)}
                                    value={currentTest.allergicToAvailableAntibiotics}
                                />
                            </Grid>
                        {(currentTest.allergicToAvailableAntibiotics === false) &&  (<>
                            <Grid item xs={12} sm={12} >
                                <Stack direction="row" spacing={1} py={3}>
                                    <Typography variant="h4" >
                                        Antibiotique à prescrire :
                                    </Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <HorizontalSelect
                                    choices={['AMOXICILLINE 2 g par jour en 2 prises par jour, pendant 6 jours ',
                                              'AMOXICILLINE 50 mg/kg/j en 2 prises par jour par voie orale, pendant 6 jours ' ,
                                              'CÉFUROXIME AXÉTIL 500 mg par jour en 2 prises par jour par voie orale, pendant 4 jours ' ,
                                              'CÉFUROXIME PROXÉTIL 200 mg par jour en 2 prises par jour par voie orale pendant 5 jours ; ',
                                              'CÉFUROXIME PROXÉTIL 8 mg/kg/j en 2 prises par jour par voie orale pendant 5 jours',
                                              'AZITHROMYCINE 500 mg par jour en 1 prise orale par jour, pendant 3 jours',
                                              'AZITHROMYCINE 20 mg/kg/j en 1 prise orale par jour, pendant 3 jours',
                                              'CLARITHROMYCINE 500 mg par jour en 2 prises par voie orale par jour pendant 5 jours',
                                              'CLARITHROMYCINE 15 mg/kg/j en 2 prises par voie orale par jour, pendant 5 jours',
                                              'JOSAMYCINE 2 g par jour en 2 prises par voie orale par jour, pendant 5 jours',
                                              'JOSAMYCINE 50 mg/kg/j en 1 prise par voie orale par jour, pendant 5 jours',
                                    ]}
                                    values={[SoreThroatTreatment.AMOXICILLIN_2G_PER_DAY_6_DAYS,
                                        SoreThroatTreatment.AMOXICILLIN_50MG_KG_6_DAYS,
                                        SoreThroatTreatment.CEFUROXIME_AXETIL_500MG_4_DAYS,
                                        SoreThroatTreatment.CEFPODOXIME_PROXETIL_200MG_5_DAYS,
                                        SoreThroatTreatment.CEFPODOXIME_PROXETIL_8MG_KG_5_DAYS,
                                        SoreThroatTreatment.AZITHROMYCINE_500MG_3_DAYS,
                                        SoreThroatTreatment.AZITHROMYCINE_20MG_KG_3_DAYS,
                                        SoreThroatTreatment.CLARITHROMYCINE_500MG_5_DAYS,
                                        SoreThroatTreatment.CLARITHROMYCINE_15MG_KG_5_DAYS,
                                        SoreThroatTreatment.JOSAMYCINE_2G_PER_DAY_5_DAYS,
                                        SoreThroatTreatment.JOSAMYCINE_50MG_KG_5_DAYS,
                                    ]}
                                    changeValue={(value) => handleFieldChange('antibioticsPrescribed', value) }
                                    value={currentTest.antibioticsPrescribed}
                                    numberOfColumns={4}
                                />
                            </Grid>
                            {(currentTest.antibioticsPrescribed !== null) && ( <>
                                <Typography p={1} pt={3} variant="h5" align="center">
                                    Détail sur le traitement
                                </Typography>
                                <Grid container spacing={1}>
                                    <Grid item md={12} sm={12}>
                                        <TextField
                                            id="otherTreatment"
                                            multiline
                                            fullWidth
                                            value={currentTest.prescriptionComment || ""}
                                            onChange={(event) => handleFieldChange('prescriptionComment', event.target.value)}
                                            rows={4}
                                            placeholder="Vos remarques sur le traitement"
                                            variant="outlined"
                                            inputProps={{ maxLength: 250 }}
                                        />
                                        <FormHelperText id="comment">250 caractères max</FormHelperText>
                                    </Grid>
                                </Grid>
                            </> )}
                        </>)}
                            </Grid>
                        </Box>
                    </Paper>
                )}
                {displayAdditionalQuestion && (
                    <Paper variant="outlined" >
                        <Box p={2} display="block">
                            <Typography variant="h4" align="center" sx={{paddingBottom:4}}>
                                Question(s) complémentaire(s)
                            </Typography>
                            {displayCommentForDoctor && ( <>
                                <Typography p={1} pt={3} variant="h5" align="center">
                                    Vos remarques à transmettre au medecin
                                </Typography>
                                <Grid container spacing={1}>
                                    <Grid item md={12} sm={12}>
                                        <TextField
                                            id="conclusion"
                                            multiline
                                            fullWidth
                                            value={currentTest.commentForDoctor || ""}
                                            onChange={(event) => handleFieldChange('commentForDoctor', event.target.value)}
                                            minRows={2}
                                            maxRows={10}
                                            placeholder="Vos remarques"
                                            variant="outlined"
                                            inputProps={{ maxLength: 250 }}
                                        />
                                        <FormHelperText id="comment">250 caractères max</FormHelperText>
                                    </Grid>
                                </Grid>
                            </> )}
                        </Box>
                    </Paper>
                )}

                {displayTestConclusion && (
                    <Paper variant="outlined" >
                        <Box p={2} display="block">
                            <Typography variant="h4" align="center" sx={{paddingBottom:4}}>
                                Conclusion du Test
                            </Typography>

                            {(currentTest.status === MedicalTestStatus.POSITIVE || currentTest.status === MedicalTestStatus.NEGATIVE  ) &&(<>
                            <Typography variant="h5" align="center">
                                Le résultat du test est  <MedicalTestResultChip result={currentTest.status} />  .
                            </Typography>
                            <Typography variant="h5" align="center">
                                { currentTest.status === MedicalTestStatus.POSITIVE && currentTest.allergicToAvailableAntibiotics ? " Vous n'avez pas prescrit d'antibiotiques suites à une contre indications et vous avez redirigé le(a) patient(e) vers son medecin traitant":''}
                            </Typography>
                                </>
                             )}
                            {displayBillingCode10 &&
                                <Typography component={"div"} variant="body1" align="center" sx={{paddingBottom:4}}>
                                Le code de facturation est
                                    <Box component="span" mx={1}>
                                       <Chip  style={{backgroundColor: '#221821F6', color: '#ffffff', margin:'3px', fontWeight: 'bold' }} label={'TRD'}/> pour une rémunération de <Chip  style={{backgroundColor: '#529b5d', color: '#ffffff', margin: '3px', fontWeight: 'bold' }} label={'10 € TTC'}/>
                                    </Box>
                                    </Typography>
                            }
                            {displayBillingCode15 &&(
                                <Typography variant="body1" align="center" sx={{paddingBottom:4}}>
                                    Le code de facturation est
                                    <Box component="span" mx={1}>
                                       <Chip  style={{backgroundColor: '#221821F6', color: '#ffffff', margin:'3px', fontWeight: 'bold' }} label={'TRD'}/>
                                    </Box>
                                        pour une rémunération de
                                    <Box component="span" mx={1}>
                                       <Chip  style={{backgroundColor: '#529b5d', color: '#ffffff', margin: '3px', fontWeight: 'bold' }} label={'15 € TTC'}/>
                                    </Box>
                                </Typography>
                            )}
                            {displayPrescriptionErrorWhenPositive && (
                            <Alert severity="warning" style={{width:'100%',marginLeft:'16px'}} >
                                <Typography px={2} variant="h5" align="center">
                                    Veuillez remplir les informations sur le traitement antibiotique prescrit
                                </Typography>
                            </Alert>
                            )}
                            {canSendReportToPatient  &&   (
                                <Alert severity="success" style={{width:'100%'}} >
                                    <Typography px={2} variant="h5" >
                                        Envoyer le compte rendu à la patiente pour finaliser le test
                                    </Typography>
                                </Alert>
                            )}
                            {emailReportHasBeeSent &&  (<>
                                <Typography pb={3} variant="h5" align="center">
                                    Test terminé, email et compte rendu envoyé au patiente avec un resultat <Box component="span" mx={1}> <MedicalTestResultChip result={currentTest.emailResult} /> </Box>
                                    {( UtilService.handleDateFormat(currentTest.emailDate)!=='Non renseigné' ) ?
                                        ` le  ${UtilService.handleDateFormat(currentTest.emailDate)}` : ''}
                                    {(currentTest.emailDate && UtilService.get24HourTime(currentTest.emailDate) ) ?
                                        ` à  ${UtilService.get24HourTime(currentTest.emailDate)}` : ''}

                                </Typography>
                                <Grid item md={12}>
                                    <TextField
                                        label="Adresse Email"
                                        required
                                        value={currentTest.emailAddress || ""}
                                        id="emailAddress"
                                        name="emailAddress"
                                        fullWidth
                                        margin="normal"
                                        variant="outlined"
                                        disabled
                                    />
                                </Grid>
                                <Grid item md={12} xs={12}>
                                    <TextField
                                        id="emailContent"
                                        multiline
                                        fullWidth
                                        value={currentTest.emailContent || ""}
                                        disabled
                                        variant="outlined"
                                    />
                                </Grid>
                            </>)}

                        </Box>
                    </Paper>
                )}

                <Box p={'2px'} />
                <Box p={'2px'} />
                <Button
                    variant={canSendReportToPatient ?"outlined" :"contained" }
                    color="primary"
                    startIcon={<SaveIcon />}
                    onClick={handleSaveAsDraft}
                    sx={{ mr: 1 }}
                >
                    Enregistrer pour plus tard
                </Button>
                {canSendReportToPatient  &&

                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<EmailIcon />}
                    onClick={() =>handleSendReport() }
                    sx={{ mr: 1 }}
                >
                    Envoyer le compte rendu au patient
                </Button>
                    }
            </Grid>
           <SendResultSoreThroatTest
                isOpen={isDialogOpen}
                onClose={() => setDialogOpen(false)}
                currentTest={currentTest}
                soreThroatTestFieldChange ={handleFieldChange}
                defaultEmailAddress={currentTest.emailAddress}
                onSendingResult={() =>  navigate(`/patient/${patient.id}/${MEDICAL_TEST_URL_IDENTIFIER}/`)}
                attachmentWithMail={attachmentWithMail}
                emailContent ={currentTest.emailContent}
                pharmacyEmail={pharmacy.email}
            />
        </Grid>
    );
};

function mapStateToProps(state) {
    const { isLoggedIn, user } = state.authReducer;
    return {
        isLoggedIn,
        user
    };
}
export default connect(mapStateToProps)(SoreThroatTestDetail);