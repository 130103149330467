// Import the customized axios instance
import axios from "./axiosInstance.js";

class MedicationService {
  
  // Method to fetch a medication by its ID
  getMedication(medicationId) {
    return axios.get(`/medication/${medicationId}`);
  }

  // Method to fetch documentation for a medication by its ID
  getMedicationDocumentation(medicationId, page = 0, size = 10) {
    return axios.get(`/medication/${medicationId}/documentation`, {
      params: {
        page: page,
        size: size
      }
    });
  }

}

export default MedicationService = new MedicationService() ; 

