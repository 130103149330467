import React from 'react';
import { Dialog, DialogTitle, DialogContent, Button, Grid, Typography, Box } from '@mui/material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import BillingDetail from '../biling/billingDetail.component';
import { ConsultationGroup, Patient } from '../../types/types';

interface BillingHelpDialogProps {
    open: boolean;
    onClose: () => void;
    consultationGroup: ConsultationGroup;
    patient: Patient;
    handleBillAction: (id: string) => void;
}

const BillingHelpDialog: React.FC<BillingHelpDialogProps> = ({ open, onClose, consultationGroup, patient, handleBillAction }) => {
    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullWidth
            maxWidth="lg"
            disableRestoreFocus
        >
            <DialogTitle>
                <Grid container>
                    <Grid item xs={10}>
                        <Box display="flex" justifyContent="center">
                            <Typography variant="h4">Aide à la facturation</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={2}>
                        <Button
                            variant="outlined"
                            color="primary"
                            startIcon={<CloseRoundedIcon />}
                            onClick={onClose}
                        >
                            Quitter
                        </Button>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <BillingDetail
                    handleBillAction={() => handleBillAction(consultationGroup.id)}
                    handleClose={onClose}
                    consultationGroup={consultationGroup}
                    patient={patient}
                />
            </DialogContent>
        </Dialog>
    );
};

export default BillingHelpDialog;
