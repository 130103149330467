import * as React from 'react';
import { Box } from '@mui/material';
import PatientSidebar from '../patient/patientSidebar.component.js'; // Update the import path if necessary and ensure JS to TSX conversion if needed
import GenericTestList from "../../components/generic/GenericTestList.tsx";


const MedicalTestPage: React.FC = () => {
    return (
        <Box width={'100%'} height="100%">
            <PatientSidebar show />
            <GenericTestList sidebar={true} />
        </Box>
    );
};

export default MedicalTestPage;
