import React from 'react';
import PatientSidebar from '../../patient/patientSidebar.component.js';
import { Box } from '@mui/material';
import ChemoConsultationList from '../../../components/consultation/chemoConsultationList.component.js';


function ChemoPage(props) {
  return (
    <Box  width={'100%'} height={"100%"}>
      <PatientSidebar show />
      <ChemoConsultationList sidebar={true} />
    </Box>
  );
}

export default ChemoPage;