import * as React from 'react';
import { Paper, Grid, Typography, Button } from '@mui/material';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';

interface NoPregnancyConsultationsProps {
    patient: {
        firstName: string;
        lastName: string;
    };
    onStartNewConsultation: () => void;
}

const NoPregnancyConsultations: React.FC<NoPregnancyConsultationsProps> = ({ patient, onStartNewConsultation }) => {
    return (
        <Paper variant="outlined">
            <Grid container direction="row" justifyContent="center" alignItems="center">
                <Grid item xs={12}>
                    <Typography m={3} variant="h6">Aucun entretien pour {patient.firstName} {patient.lastName}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Button
                        sx={{ display: 'flex', justifyContent: 'center', marginBottom: "10px" }}
                        onClick={onStartNewConsultation}
                        variant="outlined"
                        color="primary"
                        startIcon={<CreateNewFolderIcon />}
                    >
                        Démarrer l'entretien pour femme enceinte
                    </Button>
                </Grid>
            </Grid>
        </Paper>
    );
};

export default NoPregnancyConsultations;
