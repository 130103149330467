import React, {useState, useEffect, useMemo} from 'react';
import {
    Paper,
    List,
    ListItem,
    Box,
    Typography, Checkbox, Stack
} from "@mui/material";
import {MedicalTestStatus} from "../../types/types.ts";

interface HorizontalSelectProps {
    values?: any[]; // Specify a more specific type if possible, like number[] or (string | number)[]
    testResult: any; // Again, this can be more specific depending on your use case
    borderRadius?: string;
    disabled : boolean;
    changeValue: (value: any) => void;
    showInconclusive :boolean
    debug?: boolean;
}

const MedicalTestResultSelect: React.FC<HorizontalSelectProps> = ({
                                                               testResult,
                                                               borderRadius = '4px',
                                                               changeValue,
                                                               disabled,
                                                               showInconclusive =false,
                                                               debug
                                                           }) => {

    const memoizedData = useMemo(() => {
        const values = [
            MedicalTestStatus.POSITIVE,
            MedicalTestStatus.NEGATIVE,
            MedicalTestStatus.CANCELED,
            MedicalTestStatus.IN_PROGRESS
        ];
        const choices = [
            "POSITIF",
            "NÉGATIF",
            "ANNULER",
            "EN COURS"
        ];
        const colorChoices = [
            '#428bca',
            '#428bca',
            '#B94A43',
            '#294056'
        ];

        if (showInconclusive) {
            values.push(MedicalTestStatus.INCONCLUSIVE);
            choices.push("NON INTERPRÉTABLE");
            colorChoices.push('#FF8C00');
        }

        return { values, choices, colorChoices };
    }, [showInconclusive]);

    const [selectedChoiceIndex, setSelectedChoiceIndex] = useState<number | undefined>(memoizedData.values.indexOf(testResult));

    useEffect(() => {
        const stringValues = memoizedData.values.map(value => value.toString());
        if(debug){
            console.log("values.map(value => value.toString()): ", stringValues);
            console.log("props.value: ", testResult);
            console.log("stringValues.includes(props.value.toString()): ", stringValues.includes(testResult + ""));
        }

        if (testResult !== undefined && stringValues.includes(testResult.toString())){
            const index = stringValues.indexOf(testResult.toString());
            setSelectedChoiceIndex(index);
        }
    }, [testResult, debug, memoizedData.values]);

    const handleChoiceClick = (index: number) => {
        setSelectedChoiceIndex(index);
        changeValue(memoizedData.values[index]);
    };

    return (
        <List component={Stack} direction="row" disablePadding>
            {memoizedData.choices.map((choice, index) => (
                <ListItem
                    key={index}
                    disablePadding
                    onClick={() => disabled ? null :handleChoiceClick(index)}
                    sx={{ cursor: disabled ? "default":"pointer" ,paddingX : 1}}

                >
                    <Paper
                        variant="outlined"
                        sx={{
                            height: "100%",
                            width: "100%",
                            minHeight: "40px",
                            borderRadius: borderRadius,
                            borderColor : memoizedData.colorChoices[index],
                            backgroundColor: index === selectedChoiceIndex ? memoizedData.colorChoices[index] : "white",
                        }}


                    >
                        <Box
                            sx={{
                                height: "100%",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <Stack
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                            >
                                {!disabled && (
                                        <Checkbox
                                            checked={index === selectedChoiceIndex}
                                            sx={{
                                                color: memoizedData.colorChoices[index] ,
                                                '&.Mui-checked': {color:  "white",},
                                             }}
                                        />)}

                                    <Typography
                                        variant="body1"
                                        color={index === selectedChoiceIndex ? "white" : memoizedData.colorChoices[index] }
                                        sx={{ fontWeight: 'bold',  }}
                                    >
                                        {choice}
                                    </Typography>
                            </Stack>
                        </Box>
                    </Paper>
                </ListItem>
            ))}
        </List>
    );
};

export default MedicalTestResultSelect;
