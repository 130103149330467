import React from 'react';
import PatientSidebar from '../../patient/patientSidebar.component.js';
import { Box } from '@mui/material';
import PregnancyConsultationList from '../../../components/consultation/pregnancyConsultationList.component.js';


function PregnancyPage(props) {
  return (
    <Box  width={'100%'} height={"100%"}>
      <PatientSidebar show />
      <PregnancyConsultationList sidebar={true} />
    </Box>
  );
}

export default PregnancyPage;