import { Types } from "./types";

import AuthService from "../services/auth.service";


export const actionDisplayMessage = (messageText, severity) => (dispatch) => {
  dispatch({
    type: Types.DISPLAY_MESSAGE,
    payload: { messageText: messageText,
              severity: severity }
  });
}

export const actionClearDisplayMessage = () => (dispatch) => {
  dispatch({
    type: Types.DISPLAY_MESSAGE,
    payload: { }
  });
}

export const actionLoginRecaptchaV2 = async (username, password, reCaptcha,dispatch) => {
    try {
        const data = await AuthService.loginWithReCaptchaV2(username, password, reCaptcha);
        dispatch({
            type: Types.LOGIN_SUCCESS,
            payload: { user: data },
        });
        return data;
    } catch (error) {
        console.error(error);
        if (error && error.response && error.response.data) {
            dispatch({
                type: Types.LOGIN_FAIL
            });
            return Promise.reject(error.response.data);
        } else {
            dispatch({
                type: Types.LOGIN_FAIL
            });
            return Promise.reject('Login failed');
        }
    }
}
export const actionLoginRecaptchaV3 = async (username, password, reCaptcha, dispatch) => {
    try {
        const data = await AuthService.loginWithReCaptchaV3(username, password, reCaptcha);
        dispatch({
            type: Types.LOGIN_SUCCESS,
            payload: { user: data },
        });
        return data;
    } catch (error) {
        console.error(error);
        if (error && error.response && error.response.data) {
            dispatch({
                type: Types.LOGIN_FAIL
            });
            return Promise.reject(error.response.data);
        } else {
            dispatch({
                type: Types.LOGIN_FAIL
            });
            return Promise.reject('Login failed');
        }
    }
};

export const actionLogout = () => (dispatch) => {
    AuthService.logout();
  
    dispatch({
      type: Types.LOGOUT,
    });
  };